<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <h3>Student’s Grievance Redressal Cell (SGRC)</h3>
                    </header>
                    <p style="text-align: justify;">Student’s Grievance Redressal Cell (SGRC) is formed as per the UGC notification dated. 11/04/2023S
                        N. 233 /notification received from Sant Gadge Baba Amravati University, Amravati (SGBAU)
                        dtd.13/04/2024 to deal with all types of Grievances, Complaints and Malpractices received from
                        students. The functions of the cell are purely safeguarding the rights of students, and also to
                        provide a platform for listening to complaints.</p>
                    <table class="table table-hover" style="margin: 1rem 0;">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Member Type</th>
                                <th>Member Name</th>
                                <th>Mobile</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Chairperson</td>
                                <td>Dr. Dipti B. Ruikar</td>
                                <td>8888975088</td>
                                <td>principal@prpotepatilpharma.ac.in</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Members - 1</td>
                                <td>Dr. Pankaj H. Chaudhary</td>
                                <td>8999344747</td>
                                <td>pankajchaudhary@prpotepatilpharma.ac.in</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Members - 2</td>
                                <td>Dr. Prasad R. Deshmukh</td>
                                <td>942264498</td>
                                <td>prasaddeshmukh@prpotepatilpharma.c.in</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Members - 3</td>
                                <td>Mr. Prashant J. Burange</td>
                                <td>9594907333</td>
                                <td>prashantburange@prpotepatilpharma.ac.in</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Special Invitee (Student)</td>
                                <td>Ku. Sejal S. Fuse</td>
                                <td>9028681196</td>
                                <td>fusesejal22@gmail.com</td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <p style="text-align: justify;">
                        (i) The term of the chairperson and members shall be for a period of two years. (ii) The term of the
                    special invitee shall be one year. (iii) The quorum for the meeting including the Chairperson, but
                    excluding the special invitee, shall be three. (iv) In considering the grievances before it, the
                    SGRC shall follow principles of natural justice. (v) The SGRC shall send its report with
                    recommendations, if any, to the competent authority of the institution concerned and a copy thereof
                    to the aggrieved student, preferably within a period of 15 working days from the date of receipt of
                    the complaint.

                    </p>
                    <!-- <table class="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Dr. Dipti B. Ruikar (Deshmukh), Principal PRPP Pharmacy College</td>
                                <td>Chairman</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Mr. Shreyas Patil</td>
                                <td>Vice Chairman</td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>Adv. Pravin M. Mohod, NGO Representative</td>
                                <td>Lawyer</td>
                            </tr>
                            <tr>
                                <th scope="row">4</th>
                                <td>Mr. Prasad R. Deshmukh, Male faculty Representative</td>
                                <td>Member</td>
                            </tr>

                            <tr>
                                <th scope="row">5</th>
                                <td>Ms. Gauri S. Baraskar, Women’s faculty Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">6</th>
                                <td>Mr. Sandip M. Narad, Gents Non Teaching Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">7</th>
                                <td>Ms. Minal P. Deshmukh, Women’s Non Teaching Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">8</th>
                                <td>B. Pharm Girls Students Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">9</th>
                                <td>D. Pharm Girls Students Representative</td>
                                <td>Member</td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </section><br><br><br>
        </main>
    </div>
</div>