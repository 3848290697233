<div class="content-block">
    <app-header-banner [data] = "banner"></app-header-banner>

    <app-home-about [data]="skills"></app-home-about>

    <app-home-vision-mission></app-home-vision-mission>
    <!-- <hr>     -->
    <app-about-po></app-about-po>
    <app-about-infra></app-about-infra>
    <!-- <app-about-details></app-about-details> -->
    <!-- <app-home-counter></app-home-counter> -->
</div>