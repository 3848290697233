<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div class="table-responsive ">
            <table class="text-center table table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Name of Activity</th>
                        <th scope="col">Year</th>
                        <th scope="col">Number of Students Participated</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>Cricket Championship held at Government College of Polytechnic, Amravati</td>
                        <td>2020</td>
                        <td>16</td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Athletics Competition held at Dr. Punjabrao Deshmukh Polytechnic College, Amravati</td>
                        <td>2020</td>
                        <td>02</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>Open Men’s Indian Federation Basketball Tournament held at Pachora</td>
                        <td>2020</td>
                        <td>01</td>
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>Intercollegiate University Basketball Tournament held at HVPM</td>
                        <td>2019</td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <th scope="row">5</th>
                        <td>Kabbadi Championship held at P. R. Pote Patil Institute of Polytechnic, Talegaon</td>
                        <td>2019</td>
                        <td>09</td>
                    </tr>
                    <tr>
                        <th scope="row">6</th>
                        <td>Chess Championship held at Government Polytechnic College, Amravati</td>
                        <td>2019</td>
                        <td>05</td>
                    </tr>
                    <tr>
                        <th scope="row">7</th>
                        <td>State Level Basketball Championship held at Brmhapuri</td>
                        <td>2019</td>
                        <td>01</td>
                    </tr>
                    <tr>
                        <th scope="row">8</th>
                        <td>Participation in National camp held at Nagothane, Raigad</td>
                        <td>2019</td>
                        <td>01</td>
                    </tr>
                    <tr>
                        <th scope="row">9</th>
                        <td>Senior State water polo championship by SAM (Swimming Association of Maharashtra)</td>
                        <td>2019</td>
                        <td>01</td>
                    </tr>
                    <tr>
                        <th scope="row">10</th>
                        <td>All India Inter University Water Polo Championship held at Lovely professional University, Jalandharb</td>
                        <td>2019</td>
                        <td>01</td>
                    </tr>
                    <tr>
                        <th scope="row">11</th>
                        <td>All India Inter University Water Polo Championship held at Bangalore</td>
                        <td>2018</td>
                        <td>01</td>
                    </tr>
                    <tr>
                        <th scope="row">12</th>
                        <td>Inter District Basketball Championship held at Nasik</td>
                        <td>2018</td>
                        <td>01</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>