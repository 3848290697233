import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-feedback',
  templateUrl: './student-feedback.component.html',
  styleUrls: ['./student-feedback.component.css']
})
export class StudentFeedbackComponent implements OnInit {
 
  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: " Feedback",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: " Feedback",
  }
}
