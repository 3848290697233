<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div class="faq-area section-padding pb-0">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="col-sm-3 col-md-3">
                            <div class="archive  ">
                                <div class="panel-group" id="accordion">
                                    <div class="panel panel-default">
                                        <div class="panel-heading" style="background:#067d8d;color:#fff">
                                            <h4 class="panel-title"><a style="cursor: pointer;" (click)="open(1)">Home
                                                </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>UnderTaking </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>Self Declaration </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>RTI </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>Extended Profile </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a style="cursor: pointer;"
                                                    (click)="open(2)">CRITERIA 1-7
                                                </a></h4>
                                        </div>
                                    </div>



                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>DVV Clarification </a>
                                            </h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>SSR </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>Best
                                                    Practices </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>Code of
                                                    Conduct </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>Institutional
                                                    Distinctiveness </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>Disabled Friendly
                                                    Campus </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>HR Policy </a>
                                            </h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>Annual Report
                                                </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a>Environmental
                                                    Promotional Activities Beyond the Campus </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a target="_blank">Capacity Building and Skill
                                                    Enhancement</a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a target="_blank">Feedback </a></h4>
                                        </div>
                                    </div>

                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title"><a target="_blank">Online Grievance Form </a></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 xl-mb-20">


                        
<p>Collapsible Set:</p>
<button type="button" class="collapsible">Open Section 1</button>
<div class="content">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
</div>
<button type="button" class="collapsible">Open Section 2</button>
<div class="content">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
</div>
<button type="button" class="collapsible">Open Section 3</button>
<div class="content">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
</div>



                        <div *ngIf="openType == 1">
                            <app-naac-home></app-naac-home>
                        </div>
                        <div *ngIf="openType == 2"> 
                            <h5 class="titleh5" style="text-align: center;text-transform: uppercase;">
                                Criteria 1 - 7
                            </h5>
                            <hr>
                            <div id="accordionExamplePage">
                                <div class="faq__area-item">
                                    <h6 class="icon page collapsed" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOnePage">
                                        Criterion 1: Curricular Aspects
                                    </h6>
                                    <div id="collapseOnePage" class="faq__area-item-body collapse"
                                        data-bs-parent="#accordionExamplePage">
                                        <table class="table table-bordered table-hover" style="width: 100%;">
                                            <tbody>
                                                <tr>
                                                    <td>1.1</td>
                                                    <td><strong>Curricular Planning and Implementation</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>1.1.1</td>
                                                    <td>
                                                        The Institution ensures effective curriculum planning and
                                                        delivery
                                                        and
                                                        conduct of continuous internal Assessment
                                                    </td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>1.2</td>
                                                    <td><strong>Academic Flexibility</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>1.2.1</td>
                                                    <td>Number of Add on /Certificate/Value added programs offered
                                                        during
                                                        the
                                                        last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>1.2.2</td>
                                                    <td>Percentage of students enrolled in Certificate/ Add-on/Value
                                                        added
                                                        programs as against the total number of students during the last
                                                        five
                                                        years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>1.3</td>
                                                    <td><strong>Curriculum Enrichment</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>1.3.1</td>
                                                    <td>Institution integrates crosscutting issues relevant to
                                                        Professional
                                                        Ethics, Gender, Human values, Environment and Sustainability
                                                        into
                                                        the
                                                        Curriculum</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>1.3.2</td>
                                                    <td>Percentage of students undertaking project work/field
                                                        work/internships
                                                    </td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>1.4</td>
                                                    <td><strong>Feedback System</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>1.4.1</td>
                                                    <td>Institution obtains feedback on the academic performance and
                                                        ambience of
                                                        the institution from various stakeholders, such as Students,
                                                        Teachers,
                                                        Employers, Alumni etc. and action taken report on the feedback
                                                        is
                                                        made
                                                        available on institutional website (Yes or No)</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="faq__area-item">
                                    <h6 class="icon page collapsed" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwoPage">
                                        Criterion 2: Teaching Learning and Evaluation
                                    </h6>
                                    <!-- faq__area-item-body collapse show -->
                                    <div id="collapseTwoPage" class="faq__area-item-body collapse "
                                        data-bs-parent="#accordionExamplePage">
                                        <table class="table table-bordered table-hover" style="width: 100%;">
                                            <tbody>
                                                <tr>
                                                    <td>2.1</td>
                                                    <td><strong>Student Enrolment and Profile</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2.1.1</td>
                                                    <td>Enrolment percentage</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2.1.2</td>
                                                    <td>Percentage of seats filled against seats reserved for various
                                                        categories
                                                        (SC, ST, OBC, Divyangjan, etc. as per applicable reservation
                                                        policy
                                                        during the last five years (Exclusive of supernumerary seats)
                                                    </td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2.2</td>
                                                    <td><strong>Student Teacher Ratio</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2.2.1</td>
                                                    <td>Student-Full time Teacher ratio</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2.3</td>
                                                    <td><strong>Teaching- Learning Process</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2.3.1</td>
                                                    <td>Student centric methods, such as experiential learning,
                                                        participative
                                                        learning and problem solving methodologies are used for
                                                        enhancing
                                                    </td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2.4</td>
                                                    <td><strong>Teacher Profile and Quality</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2.4.1</td>
                                                    <td>Percentage of full-time teachers against sanctioned posts during
                                                        the
                                                        last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2.4.2</td>
                                                    <td>Percentage of full time teachers with NET/SET/SLET/ Ph. D. /
                                                        D.M. /
                                                        M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt. during the last
                                                        five
                                                        years (consider only highest degree for count)</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2.5</td>
                                                    <td><strong> Evaluation Process and Reforms</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2.5.1</td>
                                                    <td>Mechanism of internal/ external assessment is transparent and
                                                        the
                                                        grievance redressal system is time- bound and efficient</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2.6</td>
                                                    <td><strong> Student Performance and Learning Outcome</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2.6.1</td>
                                                    <td>Programme Outcomes (POs) and Course Outcomes (COs) for all
                                                        Programmes
                                                        offered by the institution are stated and displayed on website
                                                        and
                                                        attainment of POs and COs are evaluated</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2.6.2</td>
                                                    <td>Pass percentage of students during last five years.</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2.7</td>
                                                    <td><strong> Student Satisfaction Survey</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2.7.1</td>
                                                    <td>Online student satisfaction survey regarding to teaching
                                                        learning
                                                        process. (Online survey to be conducted)</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="faq__area-item">
                                    <h6 class="icon page collapsed" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThreePage">
                                        Criterion 3: Research, Innovations, and Extension</h6>
                                    <div id="collapseThreePage" class="faq__area-item-body collapse"
                                        data-bs-parent="#accordionExamplePage">
                                        <table class="table table-bordered table-hover" style="width: 100%;">

                                            <tbody>
                                                <tr>
                                                    <td>3.1</td>
                                                    <td><strong>
                                                            Resource Mobilization for Research
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3.1.1</td>
                                                    <td>Grants received from Government and non-governmental agencies
                                                        for
                                                        research projects / endowments in the institution during the
                                                        last
                                                        five
                                                        years (INR in Lakhs)</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3.2</td>
                                                    <td><strong>
                                                            Innovation Ecosystem
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3.2.1</td>
                                                    <td>Institution has created an ecosystem for innovations and has
                                                        initiatives
                                                        for creation and transfer of knowledge (patents filed,
                                                        published,
                                                        incubation center facilities in the HEI to be considered)</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3.2.2</td>
                                                    <td>Number of workshops/seminars/conferences including on Research
                                                        Methodology, Intellectual Property Rights (IPR) and
                                                        entrepreneurship
                                                        conducted during the last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3.3</td>
                                                    <td><strong>
                                                            Research Publication and Awards
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3.3.1</td>
                                                    <td>Number of research papers published per teacher in the Journals
                                                        notified
                                                        on UGC care list during the last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3.3.2</td>
                                                    <td>Number of books and chapters in edited volumes/books published
                                                        and
                                                        papers published in national/ international conference
                                                        proceedings
                                                        per
                                                        teacher during last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3.4</td>
                                                    <td><strong>
                                                            Extension Activities
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3.4.1</td>
                                                    <td>Extension activities are carried out in the neighborhood
                                                        community,
                                                        sensitizing students to social issues, for their holistic
                                                        development,
                                                        and impact thereof during the last five years.</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3.4.2</td>
                                                    <td>Awards and recognitions received for extension activities from
                                                        government / government recognised bodies</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3.4.3</td>
                                                    <td>Number of extension and outreach programs conducted by the
                                                        institution
                                                        through NSS/NCC/Red cross/YRC etc., (including the programmes
                                                        such
                                                        as
                                                        Swachh Bharat, AIDS awareness, Gender issues etc. and/or those
                                                        organised
                                                        in collaboration with industry, community and NGOs) during the
                                                        last
                                                        five
                                                        years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3.5</td>
                                                    <td><strong>
                                                            Collaboration
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>3.5.1</td>
                                                    <td>The number of MoUs, collaborations/linkages for Faculty
                                                        exchange,
                                                        Student exchange, Internship, Field trip, On-the- job training,
                                                        research
                                                        and other academic activities during the last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="faq__area-item">
                                    <h6 class="icon page collapsed" data-bs-toggle="collapse"
                                        data-bs-target="#collapse4">
                                        Criterion 4: Infrastructure and Learning Resources</h6>
                                    <div id="collapse4" class="faq__area-item-body collapse"
                                        data-bs-parent="#accordionExamplePage">
                                        <table class="table table-bordered table-hover" style="width: 100%;">

                                            <tbody>
                                                <tr>
                                                    <td>4.1</td>
                                                    <td><strong>
                                                            Physical Facilities
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>4.1.1</td>
                                                    <td>Availability of adequate infrastructure and physical facilities
                                                        viz.,
                                                        classrooms, laboratories, ICT facilities, cultural activities,
                                                        gymnasium, yoga centre etc. in the institution.</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>4.1.2</td>
                                                    <td>Percentage of expenditure, excluding salary for infrastructure
                                                        augmentation during last five years (INR in Lakhs)</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>4.2</td>
                                                    <td><strong>
                                                            Library as a learning Resource
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>4.2.1</td>
                                                    <td>Library is automated using Integrated Library Management System
                                                        (ILMS),
                                                        subscription to e-resources, amount spent on purchase of books,
                                                        journals
                                                        and per day usage of library.</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>4.3</td>
                                                    <td><strong>
                                                            IT Infrastructure
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>4.3.1</td>
                                                    <td>Institution frequently updates its IT facilities and provides
                                                        sufficient
                                                        bandwidth for internet connection</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>4.3.2</td>
                                                    <td>Student – Computer ratio (Data for the latest completed academic
                                                        year)
                                                    </td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>4.4</td>
                                                    <td><strong>
                                                            Maintenance of Campus Infrastructure
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>4.4.1</td>
                                                    <td>Percentage of expenditure incurred on maintenance of
                                                        infrastructure
                                                    </td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="faq__area-item">
                                    <h6 class="icon page collapsed" data-bs-toggle="collapse"
                                        data-bs-target="#collapse5">
                                        Criterion 5: Student Support and Progression</h6>
                                    <div id="collapse5" class="faq__area-item-body collapse"
                                        data-bs-parent="#accordionExamplePage">
                                        <table class="table table-bordered table-hover" style="width: 100%;">

                                            <tbody>
                                                <tr>
                                                    <td>5.1</td>
                                                    <td><strong>
                                                            Student Support
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>5.1.1</td>
                                                    <td>Percentage of students benefited by scholarships and freeships
                                                        provided
                                                        by the Government and Non-Government agencies during last five
                                                        years
                                                    </td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5.1.2</td>
                                                    <td>Capacity building and skills enhancement initiatives taken by
                                                        the
                                                        institution include the following</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5.1.3</td>
                                                    <td>Percentage of students benefitted by guidance for competitive
                                                        examinations and career counseling offered by the Institution
                                                        during
                                                        the
                                                        last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5.1.4</td>
                                                    <td>The Institution has a transparent mechanism for timely redressal
                                                        of
                                                        student grievances including sexual harassment and ragging cases
                                                    </td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5.2</td>
                                                    <td><strong>
                                                            Student Progression
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>5.2.1</td>
                                                    <td>Percentage of placement of outgoing students and students
                                                        progressing to
                                                        higher education during the last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5.2.2</td>
                                                    <td>Percentage of students qualifying in state/national/
                                                        international
                                                        level
                                                        examinations during the last five years (eg: JAM/CLAT/GATE/
                                                        GMAT/
                                                        CAT/
                                                        GRE/ TOEFL/ Civil Services/State government examinations)</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5.3</td>
                                                    <td><strong>
                                                            Student Participation and Activities
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>5.3.1</td>
                                                    <td>Number of awards/medals for outstanding performance in sports/
                                                        cultural
                                                        activities at University / state/ national / international level
                                                        (award
                                                        for a team event should be counted as one) during the last five
                                                        years
                                                    </td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5.3.2</td>
                                                    <td>Average number of sports and cultural programs in which students
                                                        of
                                                        the
                                                        Institution participated during last five years (organised by
                                                        the
                                                        institution/other institutions)</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5.4</td>
                                                    <td><strong>
                                                            Alumni Engagement
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>5.4.1</td>
                                                    <td>There is a registered Alumni Association that contributes
                                                        significantly
                                                        to the development of the institution through financial and/or
                                                        other
                                                        support services</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="faq__area-item">
                                    <h6 class="icon page collapsed" data-bs-toggle="collapse"
                                        data-bs-target="#collapse6">
                                        Criterion 6: Governance, Leadership, and Management</h6>
                                    <div id="collapse6" class="faq__area-item-body collapse"
                                        data-bs-parent="#accordionExamplePage">
                                        <table class="table table-bordered table-hover" style="width: 100%;">

                                            <tbody>
                                                <tr>
                                                    <td>6.1</td>
                                                    <td><strong>
                                                            Institutional Vision and Leadership
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>6.1.1</td>
                                                    <td>The governance and leadership is in accordance with vision and
                                                        mission
                                                        of the institution and it is visible in various institutional
                                                        practices
                                                        such as decentralization and participation in the institutional
                                                        governance</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6.2</td>
                                                    <td><strong>
                                                            Strategy Development and Deployment
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>6.2.1</td>
                                                    <td>The functioning of the institutional bodies is effective and
                                                        efficient
                                                        as visible from policies, administrative setup, appointment and
                                                        service
                                                        rules, procedures, deployment of institutional Strategic/
                                                        perspective/development plan etc</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6.2.2</td>
                                                    <td>Implementation of e-governance in areas of operation</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6.3</td>
                                                    <td><strong>
                                                            Faculty Empowerment Strategies
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>6.3.1</td>
                                                    <td>The institution has effective welfare measures and Performance
                                                        Appraisal
                                                        System for teaching and non-teaching staff</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6.3.2</td>
                                                    <td>Percentage of teachers provided with financial support to attend
                                                        conferences/workshops and towards membership fee of professional
                                                        bodies
                                                        during the last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6.3.3</td>
                                                    <td>Percentage of teaching and non-teaching staff participating in
                                                        Faculty
                                                        development Programmes (FDP), professional development
                                                        /administrative
                                                        training programs during the last five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6.4</td>
                                                    <td><strong>
                                                            Financial Management and Resource Mobilization
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>6.4.1</td>
                                                    <td>Institution has strategies for mobilization and optimal
                                                        utilization
                                                        of
                                                        resources and funds from various sources (government/
                                                        nongovernment
                                                        organizations) and it conducts financial audits regularly
                                                        (internal
                                                        and
                                                        external)</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6.5</td>
                                                    <td><strong>
                                                            Internal Quality Assurance System
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>6.5.1</td>
                                                    <td>Internal Quality Assurance Cell (IQAC) has contributed
                                                        significantly
                                                        for
                                                        institutionalizing the quality assurance strategies and
                                                        processes.
                                                        It
                                                        reviews teaching learning process, structures &amp;
                                                        methodologies of
                                                        operations and learning outcomes at periodic intervals and
                                                        records
                                                        the
                                                        incremental improvement in various activities</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6.5.2</td>
                                                    <td>Quality assurance initiatives of the College</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="faq__area-item">
                                    <h6 class="icon page collapsed" data-bs-toggle="collapse"
                                        data-bs-target="#collapse7">
                                        Criterion 7: Institutional Values and Best Practices</h6>
                                    <div id="collapse7" class="faq__area-item-body collapse"
                                        data-bs-parent="#accordionExamplePage">
                                        <table class="table table-bordered table-hover" style="width: 100%;">

                                            <tbody>
                                                <tr>
                                                    <td>7.1</td>
                                                    <td><strong>
                                                            Institutional Values and Social Responsibilities
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>7.1.1</td>
                                                    <td>Measures initiated by the Institution for the promotion of
                                                        gender
                                                        equity
                                                        and Institutional initiatives to celebrate/organize national and
                                                        international commemorative days, events and festivals during
                                                        the
                                                        last
                                                        five years</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>7.1.2</td>
                                                    <td>The Institution has facilities and initiatives for Alternate
                                                        sources
                                                        of
                                                        energy and energy conservation measures, Management of the
                                                        various
                                                        types
                                                        of degradable and non-degradable waste, Water conservation,
                                                        Green
                                                        campus
                                                        initiatives and Disabled-friendly, barrier free environment</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>7.1.3</td>
                                                    <td>Quality audits on environment and energy regularly undertaken by
                                                        the
                                                        Institution</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>7.1.4</td>
                                                    <td>Describe the Institutional efforts/initiatives in providing an
                                                        inclusive
                                                        environment i.e., tolerance and harmony towards cultural,
                                                        regional,
                                                        linguistic, communal socioeconomic and Sensitization of students
                                                        and
                                                        employees to the constitutional obligations: values, rights,
                                                        duties
                                                        and
                                                        responsibilities of citizens.</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>7.2</td>
                                                    <td><strong>
                                                            Best Practices
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>7.2.1</td>
                                                    <td>Describe two best practices successfully implemented by the
                                                        Institution
                                                        as per NAAC format provided in the Manual.</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>7.3</td>
                                                    <td><strong>
                                                            Institutional Distinctiveness
                                                        </strong></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>7.3.1</td>
                                                    <td>Portray the performance of the Institution in one area
                                                        distinctive
                                                        to
                                                        its priority and thrust within 1000 words</td>
                                                    <td><a class="btn btn-success">View</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>