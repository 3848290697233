import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "approvals",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Approvals",
  }
}
