<div class="section-full content-inner wow fadeIn" data-wow-delay="0.4s">
	<div class="container">
		<div class="section-head m-b20">
			<h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Our Alumni
				Testimonials</h2>
			<div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
		</div>
		<div class="section-content m-b30 row">
			<div class="testimonial-six owl-loaded owl-theme owl-carousel owl-none dots-style-2">
				<div class="item">
					<div class="testimonial-8">
						<div class="testimonial-text">
							<p>Our college is the place for a student where I personally experience the period of growth
								, intellectual stimulation and social engagement by the help of the passionate and
								support teacher staff, there is the constant guidance and a lot of efforts which was
								always taken by them for the students development There is the training and placement
								cell for providing a platform to enhance students skills and an opportunity to showcase
								them. The infrastructure of our college is one of the finest in the Amravati region is
								what stand out the most</p>
						</div>
						<div class="testimonial-detail clearfix">
							<div class="testimonial-pic radius shadow"><img
									src="../../../assets/pdf/result/dpharm/2 Bhakti Chipda.jpg" width="100" height="100"
									alt=""></div>
							<h5 class="testimonial-name m-t0 m-b5">Ms. Bhakti Chipda</h5>
							<!-- <span class="testimonial-position">Private Civil Engg. Consultant</span> -->
						</div>
					</div>
				</div>
				<div class="item">
					<div class="testimonial-8">
						<div class="testimonial-text">
							<p>
								This institute has boosted up my confidence and my knowledge and also this institute is
								the first step of my life, moving for my professional carrier.
							</p>
						</div>
						<div class="testimonial-detail clearfix">
							<div class="testimonial-pic radius shadow"><img
									src="../../../assets/images/testimonials/pradtymn.jpg" width="100" height="100"
									alt=""></div>
							<h5 class="testimonial-name m-t0 m-b5">Mr. Pradyumna S. Keche</h5>
							<!--<span class="testimonial-position">Sr.Team Leader, Vivo India </span> -->
						</div>
					</div>
				</div>
				<div class="item">
					<div class="testimonial-8">
						<div class="testimonial-text">
							<p>P. R. Pote Patil College of Pharmacy, Amravati, stands out as an institute that fosters
								both personal and professional growth. The faculty is supportive and friendly,
								maintaining discipline while instilling moral values in students. Student-mentor
								relationship is seamless allowing students to interact with their mentors about studies
								and other issues comfortably.
								Academically, the staff teaches effectively and is willing to repeat explanations as
								needed. The practical labs are well-equipped, and practical sessions are conducted
								regularly. The faculty provides genuine guidance throughout and beyond the students'
								journey. The college campus is large and beautiful, creating a positive atmosphere for
								all.
							</p>
						</div>
						<div class="testimonial-detail clearfix">
							<div class="testimonial-pic radius shadow"><img
									src="../../../assets/images/testimonials/vaidehi.jpg" width="100" height="100"
									alt=""></div>
							<h5 class="testimonial-name m-t0 m-b5">Ms. Vaidehi Bonde</h5>
							<!-- <span class="testimonial-position">Sr.Unit Manager, Bajaj Finserv</span> -->
						</div>
					</div>
				</div>

				<div class="item">
					<div class="testimonial-8">
						<div class="testimonial-text">
							<p>I am proud to be an alumna of P. R Pote Patil College of Pharmacy, Amravati a beacon of
								excellence in pharmaceutical education. The college's commitment to nurturing holistic
								professionals is evident through initiatives like the sponsored foreign and domestic
								tours. These tours were pivotal in enhancing our understanding of global and local
								pharmaceutical landscapes, providing students firsthand exposure to industry practices,
								research and regulatory frameworks</p>
						</div>
						<div class="testimonial-detail clearfix">
							<div class="testimonial-pic radius shadow"><img
									src="../../../assets/pdf/result/dpharm/2%20Siddhant%20Warkari.jpeg" width="100" height="100" alt="">
							</div>
							<h5 class="testimonial-name m-t0 m-b5">Mr. Siddhant Warkari</h5>
							<!-- <span class="testimonial-position">Sr.Unit Manager, Bajaj Finserv</span> -->
						</div>
					</div>
				</div>
				<div class="item">
					<div class="testimonial-8">
						<div class="testimonial-text">
							<p>P. R. Pote Patil College of Pharmacy in Amravati prioritizes both personal and
								professional development. The faculty maintains a supportive and friendly environment
								while instilling moral values in students. The student-mentor relationship is seamless,
								allowing open communication about studies and other matters. Academically, the staff is
								effective, willing to repeat explanations as needed. Well-equipped practical labs and
								regular sessions enhance the learning experience. The faculty offers genuine guidance
								throughout students’ journeys, and the campus itself is large and beautiful, fostering a
								positive atmosphere.</p>
						</div>
						<div class="testimonial-detail clearfix">
							<div class="testimonial-pic radius shadow"><img
									src="../../../assets/pdf/result/dpharm/2.jpeg" width="100" height="100" alt="">
							</div>
							<h5 class="testimonial-name m-t0 m-b5">Ms. Vaibhavi Nerkar</h5>
							<!-- <span class="testimonial-position">Sr.Unit Manager, Bajaj Finserv</span> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>