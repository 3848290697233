<!-- <div class="section-full content-inner bg-gray wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s"> -->
	<div class="section-full content-inner bg-white">
	<div class="container">
		<div class="section-head m-b20">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Our Eminent Guests</h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>


		<div class="blog-carousel owl-none owl-carousel">
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/1.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Dr. A. P. J. Abdul Kalam</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/2.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Dr. Vijay Bhatkar</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/3.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Chetan Bhagat </h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/4.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. R. S. Gawai</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/5.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Devishing Shekhawat</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/6.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Dr. Dinesh Keskar</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/8.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Kiran Bedi</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/9.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. M. S. Bitta</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/10.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Dr. Raghunath Mashelkar</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/11.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Nitin Gadkari </h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/12.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Tessy Thomas </h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/13.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Sujit Benerjee</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/15.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Dr. Narendra Jadhav </h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/16.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Dr. Mohd. Yunus</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/18.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Vinod Tawade </h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/20.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Devendra Fadanvis</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/21.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Navjot Sing Siddhu</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/22.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. S. Guruprasad</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/23.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Sri Sri Ravishankar</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/24.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Anurima Sinnha</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/25.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Dr. Vikram Singh</h6>
						</div>
					</div>
				</div>
			</div>

			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/26.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Dr. Indrajeet Deshmukh</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/27.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Ashok Khade</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/28.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Swami Atmapriyananda</h6>
						</div>
					</div>
				</div>
			</div>

			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/29.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Dinesh Amalnerkar</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/30.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Dr. Ramshankar Katheriya</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/31.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Suresh Naik</h6>
						</div>
					</div>
				</div>
			</div>

			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/32.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Gaur Gopal Das</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/33.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Dr. Rajendra Singh</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/34.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Kumar Vishwas</h6>
						</div>
					</div>
				</div>
			</div>

			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/35.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. B. K. Shivani</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/36.jpg" alt="">
						<div class="user-info">
							<h6 class="title"> Hon. Sonu Sharma</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/37.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Gyanvatsal Swami</h6>
						</div>
					</div>
				</div>
			</div>

			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="assets/images/our-eminient/38.jpg" alt="">
						<div class="user-info">
							<h6 class="title">Hon. Laxmi Agarwal</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>