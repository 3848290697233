<div class="section-full bg-gray content-inner about-carousel-ser">
    <div class="container">

        <div class="section-head m-b20">
            <h2 class="title" style="color: #fff;font-family: Rockwell !important;font-weight: 500;">Programme Outcomes
            </h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div class="row" style="background-color: white; box-shadow: 0 0 10px #fe9d3b26; padding: 1.5rem;">
            <div class="col-md-12">
                <p>
                    <b>1. Pharmacy Knowledge</b><br>
                    Possess knowledge and comprehension of the core information associated with the profession of
                    pharmacy,
                    including biomedical sciences; pharmaceutical sciences; behavioural, social, and administrative
                    pharmacy
                    sciences; and manufacturing practices.
                </p>
                <p>
                    <b>2. Thinking Abilities</b><br>
                    Utilise the principles of scientific inquiry, thinking analytically, clearly and critically, while
                    solving problems and making decisions during daily practice. Find, analyse, evaluate and apply
                    information systematically and shall make defensible decisions.
                </p>
                <p>
                    <b>3. Planning Abilities</b><br>
                    Demonstrate effective planning abilities including time management, resource management, delegation
                    skills and organisational skills. Develop and implement plans and organise work to meet deadlines.
                </p>
                <p>
                    <b>4. Leadership skills</b><br>
                    Understand and consider the human reaction to change, motivation issues, leadership and team
                    building
                    when planning changes required for fulfilment of practice, professional and societal
                    responsibilities.
                    Assume participatory roles as responsible citizens or leadership roles, whenever appropriate, to
                    facilitate improvement in health and well-being.
                </p>
                <p>
                    <b>5. Professional Identity</b><br>
                    Understand, analyse and communicate the value of their professional roles in society (e.g. health
                    care
                    professionals, suppliers of pharmaceuticals, promoters of health, educators, business managers,
                    employers, employees) through consideration of historical, social, economic and political issues.
                </p>
                <p>
                    <b>6. The Pharmacist and society</b><br>
                    Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal, and
                    cultural issues and the consequent responsibilities relevant to the professional pharmacy practice.
                </p>
                <p>
                    <b>7. Environment and sustainability</b><br>
                    Understand the impact of the professional pharmacy solutions in societal and environmental contexts,
                    and
                    demonstrate the knowledge of, and need for sustainable development.
                </p>
                <p>
                    <b>8. Ethics</b><br>
                    Honour personal values and apply ethical principles in professional and social contexts. Demonstrate
                    behaviour that recognises cultural and personal variability in values, communication and lifestyles.
                    Use
                    ethical frameworks, apply ethical principles while making decisions, and take responsibility for the
                    outcomes associated with the decisions.
                </p>
                <p>
                    <b>9. Communication</b><br>
                    Communicate effectively with the pharmacy community and with society at large, such as, being able
                    to
                    comprehend and write effective reports, make effective presentations and documentation, and give and
                    receive clear instructions.
                </p>
                <p>
                    <b>10. Modern tool usage</b><br>
                    Learn, select, and apply appropriate methods and procedures, resources, and modern pharmacy-related
                    computing tools with an understanding of the limitations.
                </p>
                <p>
                    <b>11. Life-long learning</b><br>
                    Recognize the need for, and have the preparation and ability to engage in independent and life-long
                    learning in the broadest context of technological change. Self-assess and use feedback effectively
                    from
                    others to identify learning needs and to satisfy these needs on an ongoing basis.
                </p>
            </div>
        </div>
    </div>
</div>