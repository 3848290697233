import { Component, OnInit } from '@angular/core';
import data from './staff.json'

@Component({
  selector: 'app-dept-faculty',
  templateUrl: './dept-faculty.component.html',
  styleUrls: ['./dept-faculty.component.css']
})
export class DeptFacultyComponent implements OnInit {

  arrmStaff: mStaff[] = data
  banner: any = {
    pagetitle: "Teaching Staff",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Teaching Staff",
  }


  constructor() { }

  ngOnInit(): void {
  }

}


export class mStaff {
  id: string
  name: string
  designation: string
  photo: string
  pdf: string
}