import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core'

@Component({
  selector: 'app-home-about-mgt',
  templateUrl: './home-about-mgt.component.html',
  styleUrls: ['./home-about-mgt.component.css']
})
export class HomeAboutMgtComponent implements OnInit {

  data_mgt
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.data_mgt = this.data
    console.log(this.data)
  }

}
