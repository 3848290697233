import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-awards-presentation',
  templateUrl: './awards-presentation.component.html',
  styleUrls: ['./awards-presentation.component.css']
})
export class AwardsPresentationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Poster Presentation",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Poster Presentation",
  }
}
