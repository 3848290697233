<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <h3>STUDENT COUNCIL</h3>
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Designation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Dr. Mukund G. Tawar</td>
                                        <td>Chairperson</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Prof. Rahul Jodh</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Prof. Prasad R. Deshmukh</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">4</th>
                                        <td>Prof. Prashant J. Burange</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">5</th>
                                        <td>Prof. Sohani Solanke</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">6</th>
                                        <td>Prof. Harish Rathod</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">7</th>
                                        <td>Mr. Kalpak V. Gajbhiye</td>
                                        <td>General Secretary</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">8</th>
                                        <td>Miss. Krutika S. Sonar</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">9</th>
                                        <td>Mr. Saurabh A. Thakare</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">10</th>
                                        <td>Miss. Sakshi N. Shewatkar</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">11</th>
                                        <td>Miss. Poonam B. Chandevar</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">12</th>
                                        <td>Miss. Nikita S. Jain</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">13</th>
                                        <td>Mr. Suyog P. Bhure</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">14</th>
                                        <td>Mr. Yogiraj K. Ingole</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">15</th>
                                        <td>Mr. Jay D. Ade</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">16</th>
                                        <td>Miss. Shivani S. Parihar</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">17</th>
                                        <td>Miss. Mrunali S. Dhakare</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">18</th>
                                        <td>Mr. Navneet S. Sahu</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">19</th>
                                        <td>Mr. Vedant P. Radke</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">20</th>
                                        <td>Mr. Pradyumna S. Keche</td>
                                        <td>Member</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </header>
                </div>
            </section>
        </main>
    </div>
</div>