import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import dialogPolyfill from 'dialog-polyfill/dist/dialog-polyfill.esm.js';
import { PosterComponent } from './home/poster/poster.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'PRP ENGG';

   
  
  constructor(public dialog: MatDialog) {    
    // const dialogRef = this.dialog.open(PosterComponent, { panelClass: 'warning-dialog'});

    this.loadJsFile('assets/plugins/owl-carousel/owl.carousel.js')
    this.loadJsFile('assets/plugins/counter/counterup.min.js')
    this.loadJsFile('assets/plugins/counter/waypoints-min.js')
    this.loadJsFile('assets/js/dz.carousel.min.js')
  }

  public loadJsFile(url: any) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
