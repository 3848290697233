<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Sr. No.</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Designation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Dr. Dipti Bipinchandra Ruikar (Deshmukh)</td>
                                    <td>Principal &amp; Chairman</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Adv. Pravin M. Mohod</td>
                                    <td>Lawyer</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Mr. Prashant J. Burange</td>
                                    <td>Member</td>
                                </tr>

                                <tr>
                                    <th scope="row">4</th>
                                    <td>Mr. Prasad R. Deshmukh</td>
                                    <td>Member</td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>Ms. Kalyani A. Gudadhe</td>
                                    <td>Member</td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>Mr. Sandip M. Narad</td>
                                    <td>Member</td>
                                </tr>
                                <tr>
                                    <th scope="row">7</th>
                                    <td>Kalpak V. Gajbhiye</td>
                                    <td>Male Students Representative, Member</td>
                                </tr>
                                <tr>
                                    <th scope="row">8</th>
                                    <td>Ashwini C Munjewar</td>
                                    <td>Girls Students Representative, Member</td>
                                </tr>
                                <tr>
                                    <th scope="row">9</th>
                                    <td>Prayag Wankhade</td>
                                    <td>Male Students Representative, Member</td>
                                </tr>
                                <tr>
                                    <th scope="row">10</th>
                                    <td>Sayali Ingole</td>
                                    <td>Girls Students Representative, Member</td>
                                </tr>
                            </tbody>
                        </table>
                    </header>
                </div>
            </section>
        </main>
    </div>
</div>