<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div>
            <div class="section-header" style="margin-top: 1rem;">
                <h3>Salient Features</h3>
            </div>

            <div class="body-style-new">
                <h1></h1>
                <ul style="margin: 2rem;line-height: 2rem;">
                    <li>Highly Qualified and Experienced Staff.</li>
                    <li>Visiting Faculties and Guest Lectures from Reputed Institutions.</li>
                    <li>Free of cost foreign tours to first five toppers</li>
                    <li>Industrial and Study Tours to Metropolitan Cities and Industrial Hubs.</li>
                    <li>Spacious Class Rooms, Labs, Library with WIFI Campus.</li>
                    <li>Well established Industry Institute Interaction, Training &amp; Placement Cell. Provision for
                        Campus Placement in top companies.</li>
                    <li>Regular Activities for Personality &amp; Communication Skill Development, Group Discussion, Yoga
                        &amp; Meditation.</li>
                    <li>Personal Counseling of Student by Faculty and Regular Meeting with Parents.</li>
                    <li>Student's Active Participation in Social activities like SEWA Project, Blood Donation Camp, and
                        Visit to Orphanages Old Age Home, Cleanness Drive and Medical Camp.</li>
                    <li>Language lab to Develop communication skill of students.</li>
                    <li>Hostel Facilities to needy students.</li>
                    <li>Bus facility from college to city destinations.</li>
                    <li>Spiritual, Pleasant and Intellectually Stimulating Environment of Campus.</li>
                </ul>
            </div>
        </div>
    </div>
</div>