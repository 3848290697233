<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>

    <div class="section-full wow fadeIn" data-wow-delay="0.4s">
        <div class="container">
            <div class="top-info" style="display: flex; justify-content: center; align-items: center;margin-top: 4px;">
                <span class="price text-primary" style="font-weight: 500">ACADEMIC SESSION 2023-2024</span>
            </div>
            <hr>
            <div id="masonry" class="dlab-blog-grid-3 row">
                <div *ngFor="let obj of arrmEvents" class="post card-container col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="blog-post blog-grid blog-rounded blog-effect1"
                        style="box-shadow: 2px 1px 1px #eee !important" >
                        <div class="dlab-post-media dlab-img-effect" style="padding: 2rem !important">
                            <a (click)="openActivity(obj)" style="cursor: pointer"><img [src]="obj.banner" alt=""></a>
                        </div>
                        <div class="dlab-info p-a20">
                            <div class="dlab-post-meta">
                                <ul>
                                    <li class="post-author"> <i class="la la-clock-o"></i>{{obj.sub_title_date }}
                                    </li>
                                </ul>
                            </div>
                            <div class="dlab-post-title ">
                                <h5 class="post-title" style="font-size: 14px;">
                                    <a style="cursor: pointer" (click)="openActivity(obj)">
                                        {{obj.title}}
                                    </a>
                                </h5>
                            </div>
                            <div class="dlab-post-text">
                                <p>
                                    {{obj.short_content}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" style="margin-top: 10px">
        <div>
            <main id="main">
                <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                    <div class="container">
                        <header class="section-header">
                            <h3></h3>
                        </header>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div>
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li role="presentation" class="active"><a href="#Section6"
                                                    aria-controls="home" role="tab" data-toggle="tab"
                                                    aria-expanded="true" class="active show"
                                                    aria-selected="true">2022-23</a></li>
                                            <li role="presentation" class=""><a href="#Section5" aria-controls="home"
                                                    role="tab" data-toggle="tab" aria-expanded="false" class=""
                                                    aria-selected="false">2021-22</a></li>
                                            <li role="presentation" class=""><a href="#Section4" aria-controls="home"
                                                    role="tab" data-toggle="tab" aria-expanded="false" class=""
                                                    aria-selected="false">2020-21</a></li>
                                            <li role="presentation" class=""><a href="#Section1" aria-controls="home"
                                                    role="tab" data-toggle="tab" aria-expanded="false" class=""
                                                    aria-selected="false">2019-20</a></li>
                                            <li role="presentation" class=""><a href="#Section2"
                                                    aria-controls="messages" role="tab" data-toggle="tab"
                                                    aria-expanded="false" class="" aria-selected="false">2018-19</a>
                                            </li>
                                            <li role="presentation" class=""><a href="#Section3"
                                                    aria-controls="messages" role="tab" data-toggle="tab"
                                                    aria-expanded="false" class="" aria-selected="false">2017-18</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" style="margin-top: 2rem;">
                                            <div class="tab-pane active show" role="tabpanel" id="Section6">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>

                                                            <tr>
                                                                <td><a class="text-dark"><b>29-07-2023</b> : World
                                                                        Nature
                                                                        Conservation Day 2023</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-07-2023</b> : “One family
                                                                        One
                                                                        Tree” and “Vruksha Dindi” rally</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>10-07-2023</b> : Training
                                                                        and
                                                                        Placement Guidance programme</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>10-07-2023</b> :
                                                                        Felicitation
                                                                        Programmed of Meritorious Students</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>09-07-2023</b> : Van
                                                                        Mahotsav
                                                                        Saptah 2023</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>21-06-2023</b> : Workshop
                                                                        “Advance Instrumentation”</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>21-06-2023</b> :
                                                                        INTERNATIONAL
                                                                        YOGA DAY</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>31-05-2023</b> : YIN Summer
                                                                        Youth Summit 2023</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>29-04-2023</b> : Overview
                                                                        and
                                                                        Opportunity in Software Testing and Computer
                                                                        System
                                                                        Validation</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>08-04-2023</b> : Virtual
                                                                        Expert
                                                                        Talk on Clinical Data Management</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>28-03-2023</b> : Guest
                                                                        Lecture
                                                                        on Communication Skill and Resume Writing</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>25-03-2023</b> : Ganga
                                                                        Compost
                                                                        Depot</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>18-03-2023</b> : MEB Pharma
                                                                        (Industrial Visit)</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>08-03-2023</b> :
                                                                        International
                                                                        Women’s Day</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>06-03-2023</b> : Awareness
                                                                        Camp
                                                                        on Safe Disposal of Unused and Expired Medicine
                                                                    </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>03-03-2023</b> : PHARMACY
                                                                        STORE
                                                                        VISIT :- AROGYA BHARTI MEDICAL STORE
                                                                    </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>03-03-2023</b> : BOTANICAL
                                                                        GARDEN VISIT</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>02-03-2023</b> : Nation Wide
                                                                        Hemoglobin Testing Camp</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>28-02-2023</b> : National
                                                                        Science Day</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>27-02-2023</b> : INDUSTRIAL
                                                                        VISIT REPORT
                                                                        IKP KNOWLEDGE PARK, Hyderabad</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>27-02-2023</b> : Hospital
                                                                        Visit:- Zenith Heart And Multispeciality
                                                                        Hospital</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>13-02-2023</b> : Strotra
                                                                        Pathan</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>04-02-2023</b> : Urban
                                                                        Health
                                                                        Center Visit</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>21-01-2023</b> : 72nd Indian
                                                                        Pharmaceutical Congress</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>18-01-2023</b> : Techelons
                                                                        Valedictory Ceremony</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>17-01-2023</b> : Poster
                                                                        Presentation Competition</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>17-01-2023</b> : Techelons
                                                                        Inauguration Ceremony</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>16-01-2023</b> : Traditional
                                                                        Day
                                                                        Celebration</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>14-01-2023</b> : Shri
                                                                        Gajanan
                                                                        Maharaj Vijaygranth Bhavya Parayan Sohada</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>11-01-2023</b> : Parents
                                                                        Meet
                                                                        (A.Y 2022-2023)</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>10-01-2023</b> : Induction
                                                                        Program (D. Pharm, B. Pharm &amp; M. Pharm)</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>04-01-2023</b> : Practical
                                                                        Training Hands-on Session &amp; Workshop </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>06-12-2022</b> : Community
                                                                        Pharmacy Inauguration </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>03-12-2022</b> : Free Skin
                                                                        Check
                                                                        up Camp</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-11-2022</b> : Report on
                                                                        celebration of “Constitution Day (Samvidhan
                                                                        Divas)”</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-11-2022</b> : Blood
                                                                        Donation
                                                                        Camp</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-11-2022</b> : Rangoli
                                                                        competition</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-11-2022</b> : Model
                                                                        Making
                                                                        Competition</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-11-2022</b> : On Spot
                                                                        Drawing
                                                                        and Painting Competition Report</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-11-2022</b> : National
                                                                        Pharmacy Week 2022</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>25-11-2022</b> : Quiz
                                                                        Competition </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>19-11-2022</b> : Interactive
                                                                        session on GPAT, NIPER,CSIR, BHU, GATE ICT
                                                                        Entrance
                                                                        Examination</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>02-11-2022</b> : Alumni
                                                                        Interaction Report</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-09-2022</b> : Student
                                                                        Forum
                                                                        Inauguration</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-09-2022</b> :
                                                                        Felicitation of
                                                                        Pharmacists on the occasion of World Pharmacist
                                                                        Day
                                                                        2022</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="tab-pane show" role="tabpanel" id="Section5">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>

                                                            <tr>
                                                                <td><a class="text-dark"><b>30-06-2022</b> : Choice
                                                                        Based
                                                                        Credit System: Concept, Policy, Practice &amp;
                                                                        Procedure And New Education Policy</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>21-06-2022</b> : 8th
                                                                        International Yoga Day</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>22-04-2022</b> : Stress
                                                                        Management and Interpersonal Skills</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>08-03-2022</b> : The
                                                                        International Women’s Day</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>04-03-2022</b> : Field
                                                                        Visit</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>23-02-2022</b> : Gajanan
                                                                        Maharaj
                                                                        Prakat Divas</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>20-02-2022</b> : Shiv
                                                                        Jayanti
                                                                        program</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-01-2022</b> : Republic
                                                                        Day
                                                                        Celebration</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>13-01-2022</b> : Birth
                                                                        anniversary of Swami Vivekananda</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>28-12-2021</b> : Orientation
                                                                        Program </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>16-10-2021</b> : Reverence
                                                                        the
                                                                        Instruments on the occasion of Khande Navami
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>25-09-2021</b> : World
                                                                        Pharmacist’s Day </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>13-09-2021</b> : Ganesh
                                                                        Festival
                                                                    </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>17-08-2021</b> : 75th
                                                                        Independence Day Celebration 15th Aug. 2021</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>11-08-2021</b> : Live
                                                                        demonstration on Tablet Compression Machine</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>28-07-2021</b> : World
                                                                        Nature
                                                                        Conservation Day</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>24-07-2021</b> : Guru
                                                                        Paurnima
                                                                        Celebration</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>17-06-2021</b> : Webinar on
                                                                        “Drug Repurposing’’</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="tab-pane show" role="tabpanel" id="Section4">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td><a class="text-dark"><b>08-04-2021</b> : Webinar on
                                                                        “Drug Design and Discovery- Current trends’’</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>15-10-2020</b> : Dr. A. P. J
                                                                        Abdul Kalam’s birth anniversary Celebrated as
                                                                        “Reader Inspiration day”</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>30-09-2020</b> : “Pharma
                                                                        Trails:
                                                                        Disease Awareness &amp; Drug Interactions”</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>23-08-2020</b> : Webinar on
                                                                        “Manufacturing process flow in Pharmaceutical
                                                                        Industry” by Dr. Saurabh Deshmukh, Research
                                                                        Scientist, Sandoz Novartis Division, Mumbai</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>09-07-2020</b> : Webinar on
                                                                        “Interventions with Herbal Medicines”</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>06-07-2020</b> : Webinar on
                                                                        “Carrier Opportunities after D. Pharm”</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>03-07-2020</b> : Webinar on
                                                                        “Pharmaceutical approach in vaccine
                                                                        formulation”</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>02-07-2020</b> : Webinar on
                                                                        “Regulatory Affairs and its working”</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="tab-pane show" role="tabpanel" id="Section1">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td><a class="text-dark"><b>29-06-2020</b> : Webinar on
                                                                        “Pharma Research &amp; Development” by Dr. Minal
                                                                        Bonde</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>27-06-2020</b> : Webinar on”
                                                                        Career Opportunities in Pharmacy” by Dr. Satish
                                                                        Polshettiwar</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>21-06-2020</b> :
                                                                        International
                                                                        Yoga Day</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>02-06-2020</b> : Webinar on
                                                                        “Targeted Drug Delivery system” by Dr. Mukund G.
                                                                        Tawar</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>25-05-2020</b> : National
                                                                        Level
                                                                        E-poster competition on pandemic awareness.</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>28-04-2020</b> : COVID
                                                                        awareness
                                                                        online quiz competition</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>19-02-2020</b> : Chhatrapati
                                                                        Shivaji Maharaj Jayanti Celebration</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>15-02-2020</b> : Gajanan
                                                                        Maharaj
                                                                        Prakat Divas</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>25-01-2020</b> : One Day
                                                                        Seminar
                                                                        on Pharmacovigilance</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>31-12-2019</b> : Hospital
                                                                        Visit</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>31-12-2019</b> : Pathology
                                                                        Laboratory Visit</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>27-09-2019</b> : Vidarbha
                                                                        level
                                                                        Human Anatomy and Physiology Model
                                                                        Competition</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>24-08-2019</b> : Guest
                                                                        Lecture
                                                                        on Personality Development</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="tab-pane show" role="tabpanel" id="Section2">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td><a class="text-dark"><b>19-02-2019</b> : Shivjayanti
                                                                        Vanchitansobat</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>19-09-2018</b> : Guest
                                                                        Lecture
                                                                        on Communication Skill</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>29-09-2018</b> : Inter
                                                                        College
                                                                        HAP Model Competition &amp; Poster
                                                                        Presentation</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>01-12-2018</b> :
                                                                        Entrepreneurship in Pharmacy</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>07-12-2018</b> : Guest
                                                                        Lecture
                                                                        on Tuberculosis</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>11-12-2018 &amp;
                                                                            12/12/2018</b>
                                                                        : Pathology Laboratory Visit.</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="tab-pane show" role="tabpanel" id="Section3">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td><a class="text-dark"><b>02-09-2017</b> : Personality
                                                                        Development Guest Lecture</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>23-09-2017</b> : First Aid
                                                                        Awareness by OASIS, Amravati</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>25-09-2017</b> : HAP Model
                                                                        Competition</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>26-09-2017</b> : Current
                                                                        Scenario in Pharmacovigilance</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>28-11-2017</b> : Hospital
                                                                        Visit</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>19-12-2017</b> : Industrial
                                                                        Visit</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td><a class="text-dark"><b>22-12-/2017</b> :
                                                                        Motivational
                                                                        and Inspirational Skill Guest Lecture</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </div>
    </div>
</div>