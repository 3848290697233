import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-college-development-committee',
  templateUrl: './college-development-committee.component.html',
  styleUrls: ['./college-development-committee.component.css']
})
export class CollegeDevelopmentCommitteeComponent implements OnInit {

  banner: any = {
    pagetitle: "college development committee",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "College Development Committee",
  }

  constructor() { }

  ngOnInit(): void {
  }

}
