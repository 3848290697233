<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
       
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container text-center">
                    <header class="section-header">
                        <h3 style="font-weight: 600;">Alumni Information B.Pharm</h3>
                    </header>
                    <div class="row">
                        <div class="col-md-3 mt-5">
                            <a target="_blank" href="../../../../assets/pdf/alumni/2022-23.pdf" style="color: #f2622d;">
                                <img src="../../../../assets/images/pdf_logo.png" style="height: 100px;"><br>
                                <br>
                                <h6>B.Pharm Batch 2022-23</h6>
                            </a>
                        </div>
                        <div class="col-md-3 mt-5">
                            <a target="_blank" href="../../../../assets/pdf/alumni/2021-22.pdf" style="color: #f2622d;">
                                <img src="../../../../assets/images/pdf_logo.png" style="height: 100px;"><br>
                                <br>
                                <h6>B.Pharm Batch 2021-22</h6>
                            </a>
                        </div>
                        <div class="col-md-3 mt-5">
                            <a target="_blank" href="../../../../assets/pdf/alumni/2020-21.pdf" style="color: #f2622d;">
                                <img src="../../../../assets/images/pdf_logo.png" style="height: 100px;"><br>
                                <br>
                                <h6>B.Pharm Batch 2020-21</h6>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        
    </div>
</div>