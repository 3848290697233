import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outstanding-alumni',
  templateUrl: './outstanding-alumni.component.html',
  styleUrls: ['./outstanding-alumni.component.css']
})
export class OutstandingAlumniComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Outstanding Alumni",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Outstanding Alumni",
  }
}
