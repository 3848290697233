import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-screen-reader',
  templateUrl: './screen-reader.component.html',
  styleUrls: ['./screen-reader.component.css']
})
export class ScreenReaderComponent implements OnInit {

  
  banner: any = {
    pagetitle: "SCREEN READER",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "SCREEN READER ",
  }
  
  constructor() { }

  ngOnInit(): void {
  }
}