<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>

    <div class="section-full content-inner">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5 col-sm-12">
                    <div class="section-head m-b30">
                        <h5 class="title-small">Contact Us</h5>
                        <div class="dlab-separator bg-primary"></div>
                        <h2 class="title">Do You Have Any Question?</h2>
                    </div>
                    <ul class="contact-question">
                        <li>
                            <i class="fa fa-map-marker"></i>
                            <h4 class="title">Address</h4>
                            <p>P. R. Pote Patil Group of Educational Institutions, Amravati.
                                Pote Estate, Pote Patil Road, Amravati.</p>
                        </li>
                        <li>
                            <i class="fa fa-envelope-o"></i>
                            <h4 class="title">Email</h4>
                            <p>prppharmacycollege@gmail.com</p>
                        </li>
                        <li>
                            <i class="fa fa-phone"></i>
                            <h4 class="title"> Contact</h4>
                            <p>  
                                0721-2970465, +91 9422264498
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-8 col-md-7 col-sm-12 m-b30">
                    <form class="contact-box dzForm" action="script/contact.php">
                        <h3 class="title-box">If you require any further information, please feel free to reach out to
                            me. If I can be of any help !</h3>
                        <div class="dzFormMsg m-b20"></div>
                        <input type="hidden" value="Contact" name="dzToDo">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input name="dzFirstName" type="text" required="" class="form-control"
                                            placeholder="First Name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input name="dzLastName" type="text" required="" class="form-control"
                                            placeholder="Last Name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input name="dzOther[Phone]" type="text" required="" class="form-control"
                                            placeholder="Phone">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input name="dzEmail" type="email" class="form-control" required=""
                                            placeholder="Your Email Id">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <textarea name="dzMessage" rows="4" class="form-control" required=""
                                            placeholder="Tell us about your project or idea"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group form-recaptcha">
                                    <div class="input-group">
                                        <input class="form-control d-none" style="display:none;" data-recaptcha="true"
                                            required data-error="Please complete the Captcha">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button name="submit" type="submit" value="Submit" class="site-button">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>