<mat-dialog-content class="mat-typography">
    <div *ngFor="let obj of data_mgt">
        <div class="row" style="display: flex;justify-content: center;margin-bottom: 10px;">
            <img [src]="obj.photo" style="width: 15rem;border: 1px solid #b0b0b02e; padding: 10px;">
        </div>
        <div class="row" style="display: flex;justify-content: center;">
            <p style="margin-bottom: 5px; font-size: 25px;font-weight: 600;color: brown;">{{obj.name}}</p>
        </div>
        <div class="row" style="display: flex;justify-content: center;">
            <p style="margin-bottom: 5px; font-size: 25px;font-weight: 600;color: black;">{{obj.designation}}</p>
        </div>
        <div class="row" style="display: flex;justify-content: center;">
            <div style="margin-bottom: 5px; word-wrap: break-word;width: 90%;border-left: 5px solid brown;padding-left: 10px;font-size: 16px;text-align: justify;" [innerHTML]="obj.message">
                <!-- "{{obj.message}}" -->
            </div>
        </div>
    </div>
</mat-dialog-content>