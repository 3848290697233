import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-naac-ssr-new',
  templateUrl: './naac-ssr-new.component.html',
  styleUrls: ['./naac-ssr-new.component.css']
})
export class NaacSsrNewComponent implements OnInit {
  openType = 2

  banner: any = {
    pagetitle: "NAAC",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "NAAC - DATA VERIFICATION AND VALIDATION",
  }

  constructor() { }

  ngOnInit(): void {
  }

  
  open(type: any) {
    this.openType = type
  }
}
