<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="../../../assets/images/other/parents-meet/1.jpg"
                                style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                        </div>
                        <div class="col-md-3">
                            <img src="../../../assets/images/other/parents-meet/2.jpg"
                                style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                        </div>
                        <div class="col-md-3">
                            <img src="../../../assets/images/other/parents-meet/4.jpg"
                                style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                        </div>
                        <div class="col-md-3">
                            <img src="../../../assets/images/other/parents-meet/5.jpg"
                                style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                        </div>
                        <div class="col-md-3">
                            <img src="../../../assets/images/other/parents-meet/3.jpg"
                                style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</div>