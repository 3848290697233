<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <h3>Anti-discrimination Cell</h3>
                    </header>
                    <p style="text-align: justify;">
                        The Anti-Discrimination Cell is constituted to safeguard the interest without any prejudice to
                        their Cast, Creed, Religion, Language, Ethnicity, Gender, and Disability to eliminate
                        discrimination against any students in the institute by providing measures to facilitate
                        punishments for those who indulge in any form of discrimination to promote equality among the
                        students.
                    </p>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Dr. Dipti B. Ruikar (Deshmukh)</td>
                                <td>Chairman</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Mr. Pankaj H. Chaudhary</td>
                                <td>Convener</td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>Mr. Prasad R. Deshmukh</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">4</th>
                                <td>Mr. Prashant J. Burange</td>
                                <td>Member</td>
                            </tr>

                            <tr>
                                <th scope="row">5</th>
                                <td>
                                    Ms. Ritu A. Bairagi
                                </td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">6</th>
                                <td>
                                    Ms. Dipti A. Bonde
                                </td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">7</th>
                                <td>
                                    Mr. Sandeep M. Narad
                                </td>
                                <td>Office Superintendent</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section><br><br><br>

        </main>
    </div>
</div>