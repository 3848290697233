import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gender-sens',
  templateUrl: './gender-sens.component.html',
  styleUrls: ['./gender-sens.component.css']
})
export class GenderSensComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "GENDER SENSITIZATION CELL",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "GENDER SENSITIZATION CELL",
  }
}
