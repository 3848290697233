import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-swayam-portal',
  templateUrl: './swayam-portal.component.html',
  styleUrls: ['./swayam-portal.component.css']
})
export class SwayamPortalComponent implements OnInit {

  
  banner: any = {
    pagetitle: "SWAYAM PORTAL",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "SWAYAM PORTAL",
  }
  
  constructor() { }

  ngOnInit(): void {
  }
}