<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <h3>Anti-Ragging / Anti-Harassment Committee</h3>
                    </header>
                    <p style="text-align:justify">
                        As per the provision of UGC sponsored SAKSHAM guideline for prevention & prohibition of
                        ragging/harrashment in technical institute, the institute has constitute following anti-ragging/
                        ragging/ anti-harrashment committee.
                    </p><br>

                    <p>
                        Anti ragging toll free number:- <span style="color: black;font-weight: 600;">
                            1800-180-5522</span>
                    </p>
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.</td>
                                <td>Dr. Dipti B. Ruikar</td>
                                <td>Chairman </td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td>Pankaj H. Chaudhary</td>
                                <td>Coordinator </td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td>Prashant J. Burnage</td>
                                <td>Coordinator </td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td>Vilas Marathe (Media)
                                </td>
                                <td>Member </td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td>Adv. Pravin Mohod (NGO)
                                </td>
                                <td>Member </td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td>Police Inspector, Nandgaon Peth Police Station</td>
                                <td>Member </td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td>Mr. Sunil Ahuja  (Parent)</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>8.</td>
                                <td>Mr. Sangam Nimkar (Senior Student-M.Pharm)</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>9.</td>
                                <td>Mr. Kuldeep D. Belurkar (Senior Student-B.Pharm)</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>10.</td>
                                <td>Mr. Gaurav Tonde (Fresher Student-B.Pharm)</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>11.</td>
                                <td>Mr. Dhiraj V. Atalkar (Senior Student-D.Pharm)</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>12.</td>
                                <td>Mr.Sarthak Thakare (Fresher Student- D.Pharm)</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>13.</td>
                                <td>Sandip M. Narad (Office Superintendent )</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>14.</td>
                                <td>Krunal G. Tiwari (Representative of Non- teaching)</td>
                                <td>Member</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </section><br><br><br>

        </main>
    </div>
</div>