<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 2rem">
        <div class="news_content news_buletin_pra">
            <h1><a href="#">PHOTO GALLERY (SPORTS)</a></h1>
            <hr>

            <div class="row">
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/1.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/2.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/3.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/4.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/5.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/6.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/7.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/8.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/9.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/10.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/11.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/12.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/13.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/14.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/15.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/16.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/17.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/18.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/19.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/20.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/21.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/22.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/23.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/24.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/25.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/26.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/27.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/28.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/29.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/30.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/31.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/32.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/33.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
                <div class="col-md-3">
                    <img src="../../../assets/images/sports/34.jpg" style="padding: 5px;border: 1px solid rgba(128, 128, 128, 0.212);">
                </div>
            </div>
        </div>
    </div>
</div>