<div class="section-full bg-gray content-inner about-carousel-ser">
	<div class="container">

		<div class="section-head m-b20">
			<h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Virtual Campus
				Tour</h2>
			<div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
		</div>

		<div class="row" style="display: flex;justify-content: center;margin-top: 2rem;">
			<div class="col-md-6">
				<iframe height="350" style="width: 100%;" src="https://www.youtube.com/embed/cq-ZSyxJi-s" border="0">
				</iframe>
			</div>
		</div>

		<div class="section-head m-b20" style="margin-top: 2rem;">
			<h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Courses Offered
			</h2>
			<div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
		</div>
		<div class="row">
			<div class="col-md-4 col-lg-4 col-sm-4">
				<div class="dlab-box courses-bx" style="background-color: #ffffff00;">
					<div class="dlab-media" style="display: flex;justify-content: center;">
						<img src="assets/images/pote_pharmacy_logo.png" style="width: 40%;margin: 2rem;">
					</div>
					<div class="dlab-info" style="background-color: #15c100;">
						<h4 style="font-size: 22px;" class="dlab-title"><a [routerLink]="['/dpharm']"
							style="color: white;">D. Pharm</a></h4>
						<!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p> -->
					</div>
				</div>
			</div>
			<div class="col-md-4 col-lg-4 col-sm-4">
				<div class="dlab-box courses-bx" style="background-color: #ffffff00;">
					<div class="dlab-media" style="display: flex;justify-content: center;">
						<img src="assets/images/pote_pharmacy_logo.png" style="width: 40%;margin: 2rem;">
					</div>
					<div class="dlab-info" style="background-color: #15c100;">
						<h4 style="font-size: 22px;" class="dlab-title"><a [routerLink]="['/bpharm']"
							style="color: white;">B. Pharm</a></h4>
						<!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p> -->
					</div>
				</div>
			</div>
			<div class="col-md-4 col-lg-4 col-sm-4">
				<div class="dlab-box courses-bx" style="background-color: #ffffff00;">
					<div class="dlab-media" style="display: flex;justify-content: center;">
						<img src="assets/images/pote_pharmacy_logo.png" style="width: 40%;margin: 2rem;">
					</div>
					<div class="dlab-info" style="background-color: #15c100;">
						<h4 style="font-size: 22px;" class="dlab-title"><a [routerLink]="['/mpharm']"
							style="color: white;">M. Pharm</a></h4>
						<!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>