<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingFive22">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseFive2" aria-expanded="true"
                                                aria-controls="collapseFive2">Students Enrolled in 2023-24</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseFive2" aria-labelledby="headingFive22"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="../../../assets/pdf/dpharm/M. Pharm 1st Year P'ceutics.xlsx"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            M. Pharm 1st Year P'ceutics
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="../../../assets/pdf/dpharm/M. Pharm 1st Year QA 2023-24.xlsx"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            M. Pharm 1st Year QA 2023-24
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="../../../assets/pdf/dpharm/B. Pharm 1st Year 2023-24.xlsx"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            B. Pharm 1st Year 2023-24
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="../../../assets/pdf/dpharm/D. Pharm Ist Year 2023-24.xlsx"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            D. Pharm Ist Year 2023-24
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFive11">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseFive1" aria-expanded="true"
                                                aria-controls="collapseFive1">Students Enrolled in 2022-23</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseFive1" aria-labelledby="headingFive11"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="../../../assets/pdf/dpharm/admission extract 22-23.pdf"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            Statement of Admitted Students: 2022-23
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="headingFive">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseFive" aria-expanded="true"
                                                aria-controls="collapseFive">Students Enrolled in 2021-22</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseFive" aria-labelledby="headingFive"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/Enroll 2021-22/B. Pharm Merit List 2021-22.pdf"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            B. Pharm Merit List 2021-22
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/Enroll 2021-22/B. Pharm Merit List 2021-22 T.pdf"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            B. Pharm Merit List 2021-22 (CAP)
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/Enroll 2021-22/B. Pharm Merit List 2021-22 DSP.pdf"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>Direct Second Year B. Pharm.
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/Enroll 2021-22/D. Pharm Merit List 2021-22.pdf"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            D. Pharm Merit List 2021-22
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4 mt-4">
                                                        <a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/Enroll 2021-22/D. Pharm Merit List 2021-22  (TFWS).pdf"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>D. Pharm Merit List 2021-22
                                                            (TFWS)
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">Students Enrolled in 2020-21</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseOne" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/B. Pharm. I.pdf"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            B. Pharm.
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/B. Pharm. DSP 06-02-2021.pdf"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>Direct Second Year B. Pharm.
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/D. Pharm. I.pdf"
                                                            style="color:#f2622d">
                                                            <img src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>
                                                            D. Pharm.
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseFour" aria-expanded="true"
                                                aria-controls="collapseFour">Students Enrolled in 2019-20</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseFour" aria-labelledby="headingFour"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">
                                                    <div class="col-md-4"><a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/B. Pharm. 2019-20.pdf"
                                                            style="color:#f2622d"><img
                                                                src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>B. Pharm.</a></div>
                                                    <div class="col-md-4"><a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/D. Pharm. 2019-20.pdf"
                                                            style="color:#f2622d"><img
                                                                src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>D. Pharm.</a></div>
                                                    <div class="col-md-4"><a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/Direct second year B.Pharm 19-20.pdf"
                                                            style="color:#f2622d"><img
                                                                src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>Direct Second Year B. Pharm.</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link collapsed"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo">Students Enrolled in 2018-19</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseTwo" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">
                                                    <div class="col-md-4"><a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/B.Pharmacy 18-19.pdf"
                                                            style="color:#f2622d"><img
                                                                src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>B.Pharm.</a></div>
                                                    <div class="col-md-4"><a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/D.Pharm 18-19.pdf"
                                                            style="color:#f2622d"><img
                                                                src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>D.Pharm.</a></div>
                                                    <div class="col-md-4"><a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/Direct second year B.Pharm 2018-19.pdf"
                                                            style="color:#f2622d"><img
                                                                src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>Direct Second Year B.Pharm.</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link collapsed"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">Students Enrolled in 2017-18</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseThree" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">
                                                    <div class="col-md-4"><a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/B.Pharm 2017-18.pdf"
                                                            style="color:#f2622d"><img
                                                                src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>B.Pharm.</a></div>
                                                    <div class="col-md-4"><a target="_blank"
                                                            href="http://www.prpotepatilpharma.ac.in/enroll/D. Pharm  2017-18.pdf"
                                                            style="color:#f2622d"><img
                                                                src="../../../assets/images/pdf_logo.png"
                                                                style="height:50px"><br>D. Pharm.</a></div>
                                                    <div class="col-md-4"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section><br><br><br>

        </main>
    </div>
</div>