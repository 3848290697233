import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-naac-dvv',
  templateUrl: './naac-dvv.component.html',
  styleUrls: ['./naac-dvv.component.css']
})
export class NaacDvvComponent implements OnInit {

  
  banner: any = {
    pagetitle: "NAAC DVV",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "NAAC DVV",
  }

  constructor() { }

  ngOnInit(): void {
  }

}
