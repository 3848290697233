<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 2rem">
        <div class="news_content news_buletin_pra">
            <h1><a href="#">Screen Reader Access</a></h1>
            <p class="date single_athur style3"></p>
            <table width="100%" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr>
                        <td>
                            <div style="text-align: justify;">
                                <p>This will enable people with visual impairments access the website using
                                    technologies,
                                    such as screen readers. The information of the website is accessible with different
                                    screen readers, such as JAWS, NVDA, Supernova and Window-Eyes.</p>
                                <p><strong>Following table lists the information about different screen
                                        readers:</strong><br>
                                    Information related to the various screen readers</p>
                                <table width="100%" class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <td width="31%" style="background-color: #003366;color: white;"><span
                                                    class="style10">Screen Reader</span>
                                            </td>
                                            <td width="50%" style="background-color: #003366;color: white;"><span
                                                    class="style10">Website</span></td>
                                            <td width="19%" style="background-color: #003366;color: white;"><span
                                                    class="style10">Free / Commercial</span>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span class="style14">Non Visual Desktop Access (NVDA)</span></td>
                                            <td><span class="style17"><a href="http://www.nvda-project.org/"
                                                        target="_blank"
                                                        title="External website that opens in a new window :- Non Visual Desktop Access (NVDA)"
                                                        rel="noopener"> http://www.nvda-project.org/</a><br>
                                                    (External website that opens in a new window)</span></td>
                                            <td><span class="style16">Free</span></td>
                                        </tr>
                                        <tr>
                                            <td><span class="style14">System Access To Go</span></td>
                                            <td><span class="style17"><a href="http://www.satogo.com/" target="_blank"
                                                        title="External website that opens in a new window :- System Access To Go"
                                                        rel="noopener"> http://www.satogo.com/</a><br>
                                                    (External website that opens in a new window)</span></td>
                                            <td><span class="style16">Free</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>&nbsp;</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

