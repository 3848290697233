import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-womens-grievance',
  templateUrl: './womens-grievance.component.html',
  styleUrls: ['./womens-grievance.component.css']
})
export class WomensGrievanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Women’s grievances Committee",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Women’s grievances Committee (Vishakha Committee) ",
  }
}
