<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div>
            <div class="container" style="margin-top: 2rem">
               
                <div class="news_content news_buletin_pra">
                    <h1><a href="#">
                            Details of Activities (IPR)_Patents
                        </a></h1>
                    <hr>

                    <div class="row">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <td>S N</td>
                                    <td>Title</td>
                                    <td>Application No/ Design No</td>
                                    <td>Name of Inventor</td>
                                    <td>Publication Date/ Grant Date </td>
                                    <td>Year</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>A Pharmaceutical composition of Venlafaxine Hydrochloride for nasal
                                        administration
                                    </td>
                                    <td>202421000168</td>
                                    <td>Dr. Pankaj H. Chaudhary, P. R. Pote Patil college of Pharmacy, Amravati</td>
                                    <td>16-02-2024</td>
                                    <td>2024</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Tablet Dissolution Test Apparatus with an Active Timer</td>
                                    <td>401245-001</td>
                                    <td>Dr. Vikrant Salode Dr. Koshish Gabhane Mrs. Gouri Salode. Mr. Sagar Khambayat
                                    </td>
                                    <td>10-05-2024</td>
                                    <td>2024</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>High Performance Liquid Chromatography Apparatus</td>
                                    <td>6287634</td>
                                    <td>Dr. Dipti Ruikar , P.R.Pote Patil college of Pharmacy,Amravati</td>
                                    <td>13-06-2023</td>
                                    <td>2023</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Single Nozzle Paste Filling Machine</td>
                                    <td>392617-001</td>
                                    <td>Dr. N. G. Raghavendra Rao Dr. Meruga Arogya Sheela , Dr.Vema Kiran, Ms. akwana
                                        Rajeshreebahen Pravinkumar, Ms. Adnya D. Bahurupi, Dr. Naresh Kumar Ahuja</td>
                                    <td>12-08-2023</td>
                                    <td>2023</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Portable Device for UV-C Disinfection</td>
                                    <td>384767-001</td>
                                    <td>Ms. Nikita Jitendra. Bhute, Ms. Ankita Sanjay Burande , Ms. Deepti Omprakash
                                        hagchandani, Mr. Zahan Akbar Panjwani, Mrs. Tilottama Moreshwar Gatkine , Mrs.
                                        Dipti
                                        Atul Bonde , Mr. Anup Rameshrao Thakre , Mr. Agrasen Govindrao Moon , Ms. Minal
                                        S.
                                        Sonule , Shruti Thakre, Dr. Vaibhav P. Uplanchiar</td>
                                    <td>06-07-2023</td>
                                    <td>2023</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Medical devices facilitating remote medical examination</td>
                                    <td>6319522</td>
                                    <td>Dr. Amit Jaiswal, P. R. Pote Patil college of Pharmacy, Amravati</td>
                                    <td>24-10-2023</td>
                                    <td>2023</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Method of evaluating the investigational composition for promoting hair growth
                                    </td>
                                    <td>202221048327</td>
                                    <td>Mr. Rahul Jodh, Chetan Jain, P. R. Pote Patil College of Pharmacy, Amravati</td>
                                    <td>09-09-2022</td>
                                    <td>2022</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>An assistive Medicine Dispensing Box</td>
                                    <td>202021042691A</td>
                                    <td>Mr. Prashant Burange,P. R. Pote Patil College of Pharmacy, Amravati</td>
                                    <td>30-10-2020</td>
                                    <td>2020</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- <div class="news_content news_buletin_pra">
                    <h1><a href="#">
                            Details of Activities (IPR)_ (Webinar/ Workshop)
                        </a></h1>
                    <hr>

                    <div class="row">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <td>S N</td>
                                    <td>Title</td>
                                    <td>Date</td>
                                    <td>Year</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Copy Right and Paper Writing</td>
                                    <td>22-12-2022</td>
                                    <td>2022-23</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Role of IPR in a Global Economy: Challenges &amp; Issues</td>
                                    <td>30-09-2022</td>
                                    <td>2022-23</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Awareness on IPR</td>
                                    <td>27-11-2021</td>
                                    <td>2021-22</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Intellectual Property Rights and IP management for startups</td>
                                    <td>28-01-2022</td>
                                    <td>2021-22</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Copyrights &amp; patent filling: writing procedures and precautions</td>
                                    <td>18-02-2021</td>
                                    <td>2021-22</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>National webinar on patent and peteomatic</td>
                                    <td>19-10-2019</td>
                                    <td>2019-20</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Intellectual Property Trade and Development</td>
                                    <td>06-01-2020</td>
                                    <td>2019-20</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Securing Ideas, Empowering Innovation: A Comprehensive Seminar on Intellectual
                                        Property Rights (IPR)</td>
                                    <td>24-11-2018</td>
                                    <td>2018-19</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>