import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rules-of-conduct',
  templateUrl: './rules-of-conduct.component.html',
  styleUrls: ['./rules-of-conduct.component.css']
})
export class RulesOfConductComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "rules-of-conduct",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Rules of Conduct",
  }
}
