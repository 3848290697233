import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-banner',
  templateUrl: './header-banner.component.html',
  styleUrls: ['./header-banner.component.css']
})
export class HeaderBannerComponent implements OnInit {

	@Input() data : any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
