import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-eminient',
  templateUrl: './home-eminient.component.html',
  styleUrls: ['./home-eminient.component.css']
})
export class HomeEminientComponent implements OnInit {

  constructor() { }

  // newsObject: Array<object> = [
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/1.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/1.jpg',
  //     alt: '1',
  //     title: 'News Articles',
  //   },
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/2.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/2.jpg',
  //     alt: '2',
  //     title: 'News Articles',
  //   },
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/3.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/3.jpg',
  //     alt: '3',
  //     title: 'News Articles',
  //   },
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/4.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/4.jpg',
  //     alt: '4',
  //     title: 'News Articles',
  //   },
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/5.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/5.jpg',
  //     alt: '5',
  //     title: 'News Articles',
  //   },
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/6.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/6.jpg',
  //     alt: '6',
  //     title: 'News Articles',
  //   },
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/7.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/7.jpg',
  //     alt: '7',
  //     title: 'News Articles',
  //   },
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/8.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/8.jpg',
  //     alt: '8',
  //     title: 'News Articles',
  //   },
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/9.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/9.jpg',
  //     alt: '9',
  //     title: 'News Articles',
  //   },
  //   {
  //     image: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/10.jpg',
  //     thumbImage: 'http://www.pravinpotepatil.in/assets/images/prp_news/new/10.jpg',
  //     alt: '10',
  //     title: 'News Articles',
  //   },
  // ];

  ngOnInit(): void {
  }

}
