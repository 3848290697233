import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryComponent } from 'ngx-gallery-9';
import { Lightbox } from 'ngx-lightbox';
import data from './data.json';

@Component({
  selector: 'app-about-infra',
  templateUrl: './about-infra.component.html',
  styleUrls: ['./about-infra.component.css']
})
export class AboutInfraComponent implements OnInit {

  
  heading1: string = ''
  heading2: string = ''
  heading3: string = ''
  heading4: string = ''
  heading5: string = ''
  heading6: string = ''
  galleryOptions: NgxGalleryOptions[];
  galleryImages1: NgxGalleryImage[];
  galleryImages2: NgxGalleryImage[];
  galleryImages3: NgxGalleryImage[];
  galleryImages4: NgxGalleryImage[];
  galleryImages5: NgxGalleryImage[];

  banner: any = {
    pagetitle: "Photo Gallery",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Photo Gallery",
  }

  _albums: any = [];
  _albums_2: any = [];
  _albums_3: any = [];
  _albums_4: any = [];
  _albums_5: any = [];
  // data_all: any = []

  constructor(private _lightbox: Lightbox) {
  }

  ngOnInit(): void {

    this.galleryOptions = [
      {
        width: '80%',
        height: '450px',
        thumbnailsColumns: 4,
        // thumbnailsRows: 2,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        preview: false
      },
    ];

    this.getData_1(1, 'STATE OF ART INFRASTRUCTURE')
  }

  getData_1(type, type_name) {
    this.galleryImages1 = data.filter(f => f.type == type)
    this.heading1 = type_name

    this._albums = []
    data.filter(f => f.type == type).forEach(element => {
      const src = element.small;
      const caption = 'Test Image caption';
      const thumb = element.big;
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };

      this._albums.push(album);
    });
  }

  // getData_2(type, type_name) {
  //   this.galleryImages2 = data.filter(f => f.type == type)
  //   this.heading2 = type_name
  // }

  getData_2(type, type_name) {
    this.galleryImages2 = data.filter(f => f.type == type)
    this.heading2 = type_name

    this._albums_2 = []
    data.filter(f => f.type == type).forEach(element => {
      const src = element.small;
      const caption = 'Test Image caption';
      const thumb = element.big;
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };

      this._albums_2.push(album);
    });
  }

  // getData_3(type, type_name) {
  //   this.galleryImages3 = data.filter(f => f.type == type)
  //   this.heading3 = type_name
  // }

  getData_3(type, type_name) {
    this.galleryImages3 = data.filter(f => f.type == type)
    this.heading3 = type_name

    this._albums_3 = []
    data.filter(f => f.type == type).forEach(element => {
      const src = element.small;
      const caption = 'Test Image caption';
      const thumb = element.big;
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };

      this._albums_3.push(album);
    });
  }

  // getData_4(type, type_name) {
  //   this.galleryImages4 = data.filter(f => f.type == type)
  //   this.heading4 = type_name
  // }

  getData_4(type, type_name) {
    this.galleryImages4 = data.filter(f => f.type == type)
    this.heading4 = type_name

    this._albums_4 = []
    data.filter(f => f.type == type).forEach(element => {
      const src = element.small;
      const caption = 'Test Image caption';
      const thumb = element.big;
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };

      this._albums_4.push(album);
    });
  }

  // getData_5(type, type_name) {
  //   this.galleryImages5 = data.filter(f => f.type == type)
  //   this.heading5 = type_name
  // }

  getData_5(type, type_name) {
    this.galleryImages5 = data.filter(f => f.type == type)
    this.heading5 = type_name

    this._albums_5 = []
    data.filter(f => f.type == type).forEach(element => {
      const src = element.small;
      const caption = 'Test Image caption';
      const thumb = element.big;
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };

      this._albums_5.push(album);
    });
  }



  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  open_2(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums_2, index);
  }

  open_3(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums_3, index);
  }

  open_4(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums_4, index);
  }

  open_5(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums_5, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
