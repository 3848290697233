<div class="dlab-bnr-inr overlay-black-middle text-center bg-pt" style="background-image:url({{data.bg_image}});">
	<div class="container">
		<div class="dlab-bnr-inr-entry align-m text-center">
			<h1 class="text-white">{{data.title}}</h1>
			<!-- Breadcrumb row -->
			<div class="breadcrumb-row">
				<ul class="list-inline">
					<li><a [routerLink]="['/home-university']">Home</a></li>
					<li>{{data.pagetitle}}</li>
				</ul>
			</div>
			<!-- Breadcrumb row END -->
		</div>
	</div>
</div>