<div class="section-full bg-gray content-inner">
    <div class="container">
        <!-- <div class="section-head m-b20">
            <h2 class="title" style="color: #fff;font-family: Rockwell !important;font-weight: 500;">UPCOMING EVENTS
            </h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-4 col-sm-6"></div>
            <div class="col-md-6 col-lg-4 col-sm-6">
                <div class="dlab-box courses-bx">
                    <div class="dlab-media">
                        <img style="height: 25rem;" src="../../../assets/images/home-banner/World pharmacist day 2023.jpeg" alt="">
                    </div>
                    <div class="dlab-info">
                        <h2 style="font-size: 22px;text-align: center;font-weight: 600;" class="dlab-title">
                            World Pharmacist Day 2023    
                        </h2>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="section-head m-b20">
            <h2 class="title" style="color: #fff;font-family: Rockwell !important;font-weight: 500;">EVENTS CONDUCTED
            </h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>

        <div class="blog-carousel owl-none owl-carousel">

			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/1.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/3.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/4.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/5.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/6.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/7.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/8.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/9.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/10.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/11.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/other/events/12.jpg" alt="">
					</div>
				</div>
			</div>




			<div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/11.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                Induction Program
                            </h6>
						</div> -->
					</div>
				</div>
			</div>
            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/12.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                Techelons 2023 Competition
                            </h6>
						</div> -->
					</div>
				</div>
			</div>
            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/13.jpg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                72nd Indian Pharmaceutical Congress
                            </h6>
						</div> -->
					</div>
				</div>
			</div>
            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/14.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                Current Scenario in Pharmacovigilance
                            </h6>
						</div> -->
					</div>
				</div>
			</div>
            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/15.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Medicine Garden"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>

            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/16.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Medicine Garden"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>

            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/17.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Gajanan Maharaj Jayanti"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>

            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/18.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "National Youth Day"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>

            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/19.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Tablet Compression Machine"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>

            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/20.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Orientation Program"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>

            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/21.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Drug Repurposing"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>       
            
            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/22.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Drug Design & Discovery"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>       

            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/23.jpg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Manufacturing Process Flow in Pharmaceutical Industry"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>       

            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/24.jpg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Interventions with Verbal Medicinces"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>   
            
            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/25.jpeg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Career Opportunities after DPharm"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>  
            
            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/26.jpg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Video on Awareness about Drug Interaction"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>  

            <div class="item">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img style="height: 25rem;" src="../../../assets/images/home-banner/27.jpg" alt="">
						<!-- <div class="user-info">
							<h6 class="title"> 
                                "Video on Awareness about Drug Interaction"
                            </h6>
						</div> -->
					</div>
				</div>
			</div>  
        </div>
    </div>
</div>