<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="section-full content-inner">
        <div class="container" style="margin-top: 10px">
            <div class="row">
                <div class="col-md-3" >
                    <div style="background-color: rgb(236, 236, 236);padding: 1rem 0;margin-top: 10px;">
                        <p style="text-align: center;"><strong>Index</strong><br> <a style="color: brown;"
                            href="https://prpotepatilengg.ac.in/IQAC/All_Departments/Common_Data/BP-List.pdf">(View/Download)</a>
                    </p>
                    </div>
                </div>
                <div class="col-md-3" >
                    <div style="background-color: rgb(236, 236, 236);padding: 1rem 0;margin-top: 10px;">
                        <p style="text-align: center;"><strong>2021-2022</strong><br> <a style="color: brown;"
                            href="https://prpotepatilengg.ac.in/IQAC/All_Departments/Common_Data/BP2021-22.pdf">(View/Download)</a>
                    </p>
                    </div>
                </div>
                <div class="col-md-3" >
                    <div style="background-color: rgb(236, 236, 236);padding: 1rem 0;margin-top: 10px;">
                        <p style="text-align: center;"><strong>2020-2021</strong><br> <a style="color: brown;"
                            href="https://prpotepatilengg.ac.in/IQAC/All_Departments/Common_Data/BP2020-21.pdf">(View/Download)</a>
                    </p>
                    </div>
                </div>
                <div class="col-md-3" >
                    <div style="background-color: rgb(236, 236, 236);padding: 1rem 0;margin-top: 10px;">
                        <p style="text-align: center;"><strong>2019-2020</strong><br> <a style="color: brown;"
                            href="https://prpotepatilengg.ac.in/IQAC/All_Departments/Common_Data/BP2019-20.pdf">(View/Download)</a>
                    </p>
                    </div>
                </div>
                <div class="col-md-3" >
                    <div style="background-color: rgb(236, 236, 236);padding: 1rem 0;margin-top: 10px;">
                        <p style="text-align: center;"><strong>2018-2019</strong><br> <a style="color: brown;"
                            href="https://prpotepatilengg.ac.in/IQAC/All_Departments/Common_Data/BP2018-19.pdf">(View/Download)</a>
                    </p>
                    </div>
                </div>
                <div class="col-md-3" >
                    <div style="background-color: rgb(236, 236, 236);padding: 1rem 0;margin-top: 10px;">
                        <p style="text-align: center;"><strong>2017-2018</strong><br> <a style="color: brown;"
                            href="https://prpotepatilengg.ac.in/IQAC/All_Departments/Common_Data/BP2017-18.pdf">(View/Download)</a>
                    </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>