<div class="section-full bg-gray content-inner about-carousel-ser">
    <div class="container">

        <div class="section-head m-b20">
            <h2 class="title" style="color: #fff;font-family: Rockwell !important;font-weight: 500;">Infrastructure
            </h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div class="row" style="background-color: white; box-shadow: 0 0 10px #fe9d3b26; padding: 1.5rem;">
            <div class="col-md-12">
                <div class="row">
                    <!-- left part start -->
                    <div class="col-xl-9 col-lg-8 col-md-12 m-b30">
                        <div class="container">
                            <!-- blog grid -->
                            <div class="top-info"
                                style="display: flex; justify-content: center; align-items: center;margin-top: 4px;">
                                <span class="price text-primary" style="font-weight: 500"> ({{heading1}}) </span>
                            </div>
                            <br>
                            <div class="col-lg-12" style="display: flex;justify-content: center">
                                <!-- <ngx-gallery [options]="galleryOptions" [images]="galleryImages1" class="ngx-gallery">
                                </ngx-gallery> -->
                                <div class="imgcontainer">
                                    <div class="gallery" *ngFor="let image of _albums; let i=index">
                                        <img src="{{image.thumb}}" style="cursor: pointer" (click)="open(i)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- left part start -->
                    <!-- Side bar start -->
                    <div class="col-xl-3 col-lg-4 col-md-12 m-b30">
                        <aside class="side-bar sticky-top">
                            <div class="widget widget_archive">
                                <h6 class="widget-title style-1">Infrastructure</h6>
                                <ul>
                                    <li>
                                        <a style="cursor: pointer"
                                            (click)="getData_1(1, 'STATE OF ART INFRASTRUCTURE')">
                                            STATE OF ART INFRASTRUCTURE
                                        </a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer" (click)="getData_1(2, 'LABORATORIES')">
                                            LABORATORIES
                                        </a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer" (click)="getData_1(3, 'CLASSROOMS')">
                                            CLASSROOMS
                                        </a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer" (click)="getData_1(4, 'COMPUTER CENTER')">
                                            COMPUTER CENTER
                                        </a>
                                    </li>
                                    <li>
                                        <a style="cursor: pointer" (click)="getData_1(5, 'LIBRARY')">
                                            LIBRARY
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </div>
                    <!-- left part start -->
                </div>
            </div>
        </div>
    </div>
</div>