import { Component, Input, OnInit } from '@angular/core';
import { SPharmaWebsiteService } from 'src/app/s-pharma-website.service';

@Component({
  selector: 'app-home-about',
  templateUrl: './home-about.component.html',
  styleUrls: ['./home-about.component.css']
})
export class HomeAboutComponent implements OnInit {

  @Input() data : any;
  
  constructor(public objSPharmaWebsiteService: SPharmaWebsiteService) { }

  ngOnInit(): void {
    this.objSPharmaWebsiteService.getNotices()
  }
}
