<div class="section-full content-inner bg-white">
    <div class="container">
        <div class="section-head m-b20">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Our Vision /
                Mission </h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div class="vision-mission" style="margin-bottom: 2rem;">
            <div
                style="border: 1px solid rgba(128, 128, 128, 0.24);padding: 0 !important;background: #fff;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .05) !important;border-radius: 0 70px 0 70px;margin-right: 5px;">
                <div
                    style="color: #fff;padding: 10px;background-color: #0e2954;font-size: 17px;font-weight: 600;text-transform: uppercase;text-align: center;    border-radius: 0 70px 0 0;">
                    Our Vision
                </div>
                <div style="color: #000;padding: 10px 20px;font-size: 14px;font-weight: 600;line-height: 1.7rem;text-align: justify;">
                    To provide quality education and training to enable students to face emerging challenges of the
                    globalized pharmaceutical technology, competitiveness and entrepreneurship.
                </div>
            </div>

            <div
                style="border: 1px solid rgba(128, 128, 128, 0.24);padding: 0 !important;background: #fff;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .05) !important;border-radius: 0 70px 0 70px;margin-right: 5px;">
                <div
                    style="color: #fff;padding: 10px;background-color: #0e2954;font-size: 17px;font-weight: 600;text-transform: uppercase;text-align: center;    border-radius: 0 70px 0 0;">
                    Our Mission
                </div>
                <div style="color: #000;padding: 10px 20px;font-size: 14px;font-weight: 600;">
                    <ul style="margin-left: 1rem;line-height: 1.7rem;">
                        <li>To obtain in depth knowledge and expertise in the field of Pharmaceutical Sciences for
                            providing the need of pharmacy profession and society at large.</li>
                        <li>To provide research oriented educational environment that encourages individuals to make
                            positive lifelong contributions to global health</li>
                    </ul>
                </div>
            </div>

            <div style="border: 1px solid rgba(128, 128, 128, 0.24);padding: 0 !important;background: #fff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .05) !important;border-radius: 0 70px 0 70px;margin-right: 5px;">
                <div
                    style="color: #fff;padding: 10px;background-color: #0e2954;font-size: 17px;font-weight: 600;text-transform: uppercase;text-align: center;    border-radius: 0 70px 0 0;">
                    PROGRAMM EDUCATIONAL OBJECTIVES (PEO'S)
                </div>
                <div style="color: #000;padding: 10px 20px;font-size: 14px;font-weight: 600;">
                   <marquee behavior="" direction="up" style="height: 18rem;">
                    <ul style="margin-left: 1rem;line-height: 1.7rem;">
                        <li>1. Pharmacy Knowledge: Possess knowledge and comprehension of the core and basic knowledge associated with the profession of pharmacy, including biomedical sciences; pharmaceutical sciences; behavioral, social, and administrative pharmacy sciences; and manufacturing practices.</li>
                        <li>2. Planning Abilities: Demonstrate effective planning abilities including time management, resource management, delegation skills and organizational skills. Develop and implement plans and organize work to meet deadlines.</li>
                        <li>3. Problem analysis: Utilize the principles of scientific enquiry, thinking analytically, clearly and critically, while solving problems and making decisions during daily practice. Find, analyze, evaluate and apply information systematically and shall make defensible decisions.</li>
                        <li>4. Modern tool usage: Learn, select, and apply appropriate methods and procedures, resources, and modern pharmacy-related computing tools with an understanding of the limitations.</li>
                        <li>5. Leadership skills: Understand and consider the human reaction to change, motivation issues, leadership and team-building when planning changes required for fulfillment of practice, professional and societal responsibilities. Assume participatory roles as responsible citizens or leadership roles when appropriate to facilitate improvement in health and well- being.</li>
                        <li>6. Professional Identity: Understand, analyze, and communicate the value of their professional roles in society (e.g. health care professionals, promoters of health, educators, managers, employers, employees).</li>
                        <li>7. Pharmaceutical Ethics: Honour personal values and apply ethical principles in professional and social contexts. Demonstrate behavior that recognizes cultural and personal variability in values, communication and lifestyles. Use ethical frameworks; apply ethical principles while making decisions and take responsibility for the outcomes associated with the decisions.</li>
                        <li>8. Communication: Communicate effectively with the pharmacy community and with society at large, such as, being able to comprehend and write effective reports, make effective presentations and documentation, and give and receive clear instructions.</li>
                        <li>9. The Pharmacist and society: Apply reasoning informed by the contextual knowledge to assess societal, health, safety and legal issues and the consequent responsibilities relevant to the professional pharmacy practice.</li>
                        <li>10. Environment and sustainability: Understand the impact of the professional pharmacy solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.</li>
                        <li>11. Life-long learning: Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change. Self-assess and use feedback effectively from others to identify learning needs and to satisfy these needs on an ongoing basis.</li>
         
                    </ul>
                   </marquee>
                </div>
            </div>
        </div>
    </div>
</div>