import { Component, OnInit } from '@angular/core';
import { SPharmaWebsiteService } from 'src/app/s-pharma-website.service';

@Component({
  selector: 'app-publication-faculty',
  templateUrl: './publication-faculty.component.html',
  styleUrls: ['./publication-faculty.component.css']
})
export class PublicationFacultyComponent implements OnInit {

  constructor(public objSPharmaWebsiteService: SPharmaWebsiteService) { }

  ngOnInit(): void {
    this.objSPharmaWebsiteService.getPublication()
  }

  banner: any = {
    pagetitle: "Publication (Faculty)",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Publication (Faculty)",
  }
}
