import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grc',
  templateUrl: './grc.component.html',
  styleUrls: ['./grc.component.css']
})
export class GrcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "GRIEVANCE REDRESSAL CELL",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "GRIEVANCE REDRESSAL CELL",
  }
}
