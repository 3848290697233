<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div class="item owl-new col-md-12">
            <div class="testimonial-8">
                <div class="testimonial-text testimonial-text-new">
                    <div>
                        <h5>List of Files </h5>

                        <div>
                            <ul>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/naac_guidelines-min.pdf">NAAC
                                        Guidelines for Institutions</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_guidelines-min.pdf">IQAC
                                        Guidelines 2017 (Revised)</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_aqar_guidelines-min.pdf">IQAC
                                        AQAR Guideline Affiliated College</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_files-min.pdf">IQAC
                                        List of Files</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_committee_new-min.pdf">IQAC
                                        Committee New</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_policy-min.pdf">IQAC
                                        Policy</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_meeting_notices-min.pdf">IQAC
                                        Meeting Notices</a></li>
                                <li><a target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_mom-min.pdf">IQAC
                                        Minutes of meeting</a></li>
                                <li><a
                                        target="_blank" href="http://www.prpcem.org/wp-content/uploads/2020/07/steering_committee-min.pdf">Steering
                                        Committee</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_functions-min.pdf">Functions
                                        of IQAC</a></li>
                                <li><a target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac-min.pdf">Internal
                                        Quality Assurance Cell</a></li>
                                <li><a target="_blank" href="http://www.prpcem.org/wp-content/uploads/2020/07/naac_ssr_final-min.pdf">NAAC
                                        SSR
                                        FINAL REPORT</a></li>
                                <li><a
                                        target="_blank" href="http://www.prpcem.org/wp-content/uploads/2020/07/naac_ssr_corrigendum-min.pdf">NAAC
                                        SSR Corrigendum (14.09.2017)</a></li>
                                <li><a target="_blank" href="http://www.prpcem.org/wp-content/uploads/2020/07/naac_ppt-min.pdf">PRINCIPAL
                                        Presentation NAAC PEER TEAM</a></li>
                                <li><a target="_blank" href="http://www.prpcem.org/wp-content/uploads/2020/07/naac_certificate-min.pdf">NAAC
                                        GRADE CERTIFICATE</a></li>
                                <li><a target="_blank" href="#">Feedback Report of NAAC PEER TEAM</a></li>
                                <li><a target="_blank" href="http://www.prpcem.org/wp-content/uploads/2020/07/sss_format-min.pdf">Student
                                        Exit
                                        Survey (Format)</a></li>
                                <li><a target="_blank" href="http://www.prpcem.org/wp-content/uploads/2020/07/sss_report.xlsx">Student
                                        Exit
                                        Survey Report</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/audit_proforma-min-1.pdf">ACAD
                                        AUDIT PROFORMA</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/amr_format-min.pdf">Academic
                                        Monitoring Report (Format)</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/class_obs_format-min.pdf">Classroom
                                        Observation (Format)</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/ext_audit_format-min.pdf">External
                                        Academic Audit Reporting (Format)</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/fdp_2015-min-1.pdf">Final
                                        Program Report for FDP-2015</a></li>
                                <li><a target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/fdp_2016-min.pdf">Final
                                        Program Report for FDP-2016</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_higher-min.pdf">Quality
                                        Assurance in Higher Education</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/iqac_activities-min.pdf">Best
                                        Practises Internal Quality Assurance Cell Activities</a></li>
                                <li><a target="_blank" href="http://www.prpcem.org/wp-content/uploads/2020/07/useful_links-min.pdf">Some
                                        useful
                                        links</a></li>
                                <li><a
                                        target="_blank" href="http://www.prpcem.org/wp-content/uploads/2020/07/naac_visit_photos-min.pdf">Photographs
                                        of NAAC PEER TEAM VISIT</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/aqar_2017_18-min.pdf">NAAC
                                        AQAR 2018</a></li>
                                <li><a
                                        target="_blank" href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/07/aqar_2017-18_old-min.pdf">NAAC
                                        AQAR 2018 (OLD FORMAT)</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>