import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-counter',
  templateUrl: './home-counter.component.html',
  styleUrls: ['./home-counter.component.css'],
})
export class HomeCounterComponent implements OnInit {

  count = 200;
  duration = 5000;
  
  constructor() { }

  ngOnInit(): void {
  }

}
