<div class="section-full bg-gray content-inner">
    <div class="container">
        <div class="section-head m-b20">
            <h2 class="title" style="color: #ffffff;font-family: Rockwell !important;font-weight: 500;">Principal Desk
            </h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div class="row" style="margin-top: 1.5rem;display: flex;justify-content: center;">
            <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div class="dlab-box dlab-team1"
                    style="display: grid;grid-template-columns: 30% 70%;background-color: #fff;border-top: 5px solid brown;">
                    <div style="padding: 1rem;">
                        <img alt="" class="lazy" src="assets/images/principal.jpg">
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div>
                            <h2><b>Dr. Dipti B. Ruikar (Deshmukh)</b></h2>
                            <b>Principal</b><br>
                            P. R. Pote Patil College of Pharmacy, Amravati
                            <p>
                                <a (click)="open(3)" style="cursor: pointer;color: rgb(0, 51, 161);">Read more...</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="section-head m-b20">
            <h2 class="title" style="color: #ffffff;font-family: Rockwell !important;font-weight: 500;">Faculty
            </h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div id="instructor" class="tab-pane" style="margin-top: 1rem;">
            <div id="comments-1" class="dlab-instructor">
                <ol class="commentlist">
                    <li class="comment photo_staff">

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/14.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Mr. Prasad R. Deshmukh
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/14.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/1.jpeg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">Renu M. Awachar</h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>Assistant Professor</p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/1.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/2.jpeg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0"> Pragati Ramesh Katkide</h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>Assistant Professor</p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/2.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/3.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Mr. Prashant J. Burange
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>Assistant Professor</p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/3.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/4.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Mr. Vaibhao Anant Bhadange
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/4.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/5.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Ms. Ritu Ambadas Bairagi
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/5.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/6.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Mr. Sandip Digambar Dhawale
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/6.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/7.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Ms. Monika Shriram Kherade
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/7.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/8.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Mr. Rahul Jodh
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/8.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/9.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Mr. P. H. Choudhary
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/9.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/10.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Ms. Gouri Suryakantrao Baraskar
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/10.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/11.jpeg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Ms. Dipti Bonde
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Lecturer
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/11.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/12.jpeg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Ms. Adnya Durwasrao Bahurupi
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/12.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="comment_container"> <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                src="../../../assets/images/pharma-staff/photo/13.jpg" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    Mr Yashdeepsingh S. Thakur
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        Assistant Professor
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href="../../../assets/images/pharma-staff/cv/13.pdf"
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_container" *ngFor="let obj of objSPharmaWebsiteService.arrFaculty">  
                            <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;"
                                [src]="obj.photo_url" alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    {{obj.faculty_name}}
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        {{obj.faculty_desg}}
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" href=""
                                        class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                    </li>
                </ol>

            </div>
        </div> -->
    </div>