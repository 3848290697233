<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <h3>Women’s grievances Committee (Vishakha Committee) / Internal complaint committee</h3>
                    </header>
                    As per the AICTE guideline the college Women's Grievance Redressal Cell (Vishaka Committee) is form
                    to deal with grievances regarding girl's students and female staff members of the college. The
                    functions of the cell are purely safeguarding the rights of female students, faculty and staff
                    members and also to provide a platform for listening to complaints.
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Name</th>
                                <th>Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Dr. Dipti B. Ruikar (Deshmukh), Principal PRPP Pharmacy College</td>
                                <td>Chairman</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Mr. Shreyas P.  Patil , Vice Chairman</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Adv. Pravin M. Mohod, NGO Representative</td>
                                <td>Lawyer</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Mr. Pankaj H. Chaudhary, Male faculty Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Ms. Gauri S. Baraskar, Women’s faculty Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Ms. Kiran S. Kaloshiya, Women’s faculty Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Mr. Sandip M. Narad, Gents Non Teaching Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Ms. Minal P. Deshmukh, Women’s Non Teaching Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>B. Pharm Girls Students Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>D. Pharm Girls Students Representative</td>
                                <td>Member</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section><br><br><br>

        </main>
    </div>
</div>