<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>

    <div class="container" style="margin-top: 10px">
        <div class="section-head m-b20">
            <h2 class="title" style="color: #ffffff;font-family: Rockwell !important;font-weight: 500;">Faculty
            </h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div id="instructor" class="tab-pane" style="margin-top: 1rem;">
            <div id="comments-1" class="dlab-instructor">
                <ol class="commentlist">
                    <li class="comment photo_staff">
                        <div class="comment_container" *ngFor="let obj of arrmStaff">
                            <img class="avatar avatar-60 photo" style="margin-bottom: 1rem;" [src]="obj.photo"
                                alt="">
                            <div class="comment-text">
                                <h6 style="font-size: 15px;" class="author m-t0">
                                    {{obj.name}}
                                </h6>
                                <div class="w3-separator bg-primary"></div>
                                <div class="description m-b15">
                                    <p>
                                        {{obj.designation}}
                                    </p>
                                </div>
                                <div class="clearfix m-b20">
                                    <a target="_blank" [href]="obj.pdf" class="site-button button-sm">Profile <i
                                            class="fa fa-download m-l5"></i></a>
                                </div>
                            </div>
                        </div>

                    </li>
                </ol>

            </div>
        </div>
    </div>
</div>