import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-r-and-d',
  templateUrl: './r-and-d.component.html',
  styleUrls: ['./r-and-d.component.css']
})
export class RAndDComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Research & Development",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Research & Development",
  }
}
