import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PosterComponent } from './poster/poster.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  skills: any = {
    title: "P. R. Pote Patil",
    sub_title: "College of Pharmacy, Amravati",
    content: " <p class='text-justify'>P. R. Pote Patil college of Pharmacy was established in the year 2017 under the Visionary Leadership of the Ex. State Minister of Maharashtra, Guardian Minister Amravati Hon. Shri. Pravin R. Pote Patil (Chairman), Dynamism of Hon. Shri. Shreyas Pravin Pote Patil (Vice Chairman) &amp; Idealistic Dr. Dipti B. Ruikar (Principal) has steered its iconic status;</p> <p class='text-justify'>The college is offering Diploma in Pharmacy with an intake capacity of 60, Bachelor of Pharmacy with an intake capacity of 100 and Master of Pharmacy with an intake capacity of 15.   </p> <p class='text-justify'>The College has started with theme <strong class='text-success'>'Quality Education is our Zeal'</strong> keeping the aim to enhance the quality education in the field of Pharmacy and empower the quality pharmacist so that they serve the society to develop lifesaving drugs.<br><br> Right from its establishment the institute has maintained the quality in Education to prove the theme; the College has all the Facility, well equipped... </p> ",
    content_link: "javascript:void(0)",
    exp_content: "<h2 class='no-title'>7+</h2><h4 class='title'>Years of Achievements</h4>",
    images_data: {
      "img1": "assets/images/about/about2/college-gate.jpg",
      "img2": "assets/images/about/about2/himani-photo.jpg"
    },
  };



  constructor(public dialog: MatDialog) {    
    const dialogRef = this.dialog.open(PosterComponent, { panelClass: 'warning-dialog'});
  }

  ngOnInit(): void {
  }

}
