import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rd-cell',
  templateUrl: './rd-cell.component.html',
  styleUrls: ['./rd-cell.component.css']
})
export class RdCellComponent implements OnInit {

  banner: any = {
    pagetitle: "Research & Development Cell",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Research & Development Cell",
  }

  constructor() { }

  ngOnInit(): void {
  }

}
