import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-college-orgnanisation-chart',
  templateUrl: './college-orgnanisation-chart.component.html',
  styleUrls: ['./college-orgnanisation-chart.component.css']
})
export class CollegeOrgnanisationChartComponent implements OnInit {

  banner: any = {
    pagetitle: "Orgnaisational Chart",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Orgnaisational Chart",
  }

  constructor() { }

  ngOnInit(): void {
  }

}
