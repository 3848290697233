import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patents',
  templateUrl: './patents.component.html',
  styleUrls: ['./patents.component.css']
})
export class PatentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Patents",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Patents",
  }
}
