<div class="section-full  dlab-we-find bg-img-fix p-t20 p-b20 bg-white wow fadeIn" data-wow-duration="2s"
	data-wow-delay="0.6s">
	<div class="container">
		<div class="section-head m-b20">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Our Other Institutes</h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
		<div class="section-content">
			<div class="client-logo-carousel mfp-gallery gallery owl-btn-center-lr owl-carousel owl-btn-3">
				<div class="item">
					<div class="ow-client-logo">
						<div class="client-logo"><a target="_blank" href="https://prpotepatilengg.ac.in/"><img src="../../../assets/images/colleges/engg.jpeg"
									alt=""></a></div>
					</div>
				</div>
				<div class="item">
					<div class="ow-client-logo">
						<div class="client-logo"> <a target="_blank" href="http://www.prpotepatilpharma.ac.in/"><img src="../../../assets/images/colleges/pharm.png"
									alt=""></a> </div>
					</div>
				</div>
				<div class="item">
					<div class="ow-client-logo">
						<div class="client-logo"> <a target="_blank" href="http://www.prpotepatilarch.ac.in/"><img src="../../../assets/images/colleges/3.png"
									alt=""></a> </div>
					</div>
				</div>
				<div class="item">
					<div class="ow-client-logo">
						<div class="client-logo"> <a target="_blank" href="http://www.prpotepatilagri.ac.in/"><img src="../../../assets/images/colleges/4.png"
									alt=""></a> </div>
					</div>
				</div>
				<div class="item">
					<div class="ow-client-logo">
						<div class="client-logo"> <a target="_blank" href="http://www.prpotepatilschool.ac.in/"><img src="../../../assets/images/colleges/5.png"
									alt=""></a> </div>
					</div>
				</div>
				<div class="item">
					<div class="ow-client-logo">
						<div class="client-logo"> <a target="_blank" href="http://www.prpotepatilayurved.ac.in/"><img src="../../../assets/images/colleges/6.png"
									alt=""></a> </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>