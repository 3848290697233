import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-awards-accolades',
  templateUrl: './awards-accolades.component.html',
  styleUrls: ['./awards-accolades.component.css']
})
export class AwardsAccoladesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Awards & Accolades",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Awards & Accolades",
  }
}
