<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
       
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container text-center">
                    <header class="section-header">
                        <h3>Alumni Information D.Pharm</h3>
                    </header>
                    <div class="row">
                        <div class="col-md-4 mt-5">
                            <a target="_blank" href="http://www.prpotepatilpharma.ac.in/alumni_information/D.Pharm/D Pharm Batch 2020-21.pdf" style="color: #f2622d;">
                                <img src="../../../../assets/images/pdf_logo.png" style="height: 150px;"><br>
                                <br>
                                <h5>D.Pharm Batch 2020-21</h5>
                            </a>
                        </div>

                        <div class="col-md-4 mt-5">
                            <a target="_blank" href="http://www.prpotepatilpharma.ac.in/alumni_information/D.Pharm/D Pharm Batch 2019-20.pdf" style="color: #f2622d;">
                                <img src="../../../../assets/images/pdf_logo.png" style="height: 150px;"><br>
                                <br>
                                <h5>D.Pharm Batch 2019-20</h5>
                            </a>
                        </div>

                        <div class="col-md-4 mt-5">
                            <a target="_blank" href="http://www.prpotepatilpharma.ac.in/alumni_information/D.Pharm/D Pharm Batch 2018-19.pdf" style="color: #f2622d;">
                                <img src="../../../../assets/images/pdf_logo.png" style="height: 150px;"><br>
                                <br>
                                <h5>D.Pharm Batch 2018-19</h5>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <!-- <section class="fadeInUp section-bg wow">
                <div class="container text-center">
                    <header class="section-header">
                        <h3>Notice</h3>
                    </header>
                    <div class="row">
                        <div class="col-md-6 mt-5">
                            <a href="admission_notice/B Pham Schedule 2021-221.pdf" style="color: #f2622d;">
                                <img src="../../../../assets/images/pdf_logo.png" style="height: 150px;"><br>
                                <br>
                                <h5>Notice</h5>
                            </a>
                        </div>

                        <div class="col-md-6 mt-5">
                            <a href="admission_notice/Merit List 1283 (2021-22)1.pdf" style="color: #f2622d;">
                                <img src="../../../../assets/images/pdf_logo.png" style="height: 150px;">
                                <br>
                                <br>
                                <h5>Merit List</h5>
                            </a>
                        </div>

                    </div>
                </div>
            </section> -->

        </main>
        
    </div>
</div>