<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div class="row">
            <div class="col-md-3">
                <div style="    background-color: rgb(14 41 84);
    padding: 1rem;
    margin: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    color: white;
    height: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;">
                    <div>
                        <p style="text-align: center;"><strong>Self-Study Report</strong><br>
                            <a href="../../assets/pdf/NAAC/SSR07022024.pdf" target="_blank" style="font-size: 12px;color: white;">(View/Download)</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="    background-color: rgb(14 41 84);
    padding: 1rem;
    margin: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    color: white;
    height: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;">
                    <div>
                        <p style="text-align: center;"><strong>I. Extended Profile</strong><br>
                            <a style="font-size: 12px;color: white;">(View/Download)</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="    background-color: rgb(14 41 84);
                padding: 1rem;
                margin: 10px;
                border: 1px solid grey;
                border-radius: 10px;
                color: white;
                height: 11rem;
                display: flex;
                align-items: center;
                justify-content: center;">
                    <div>
                        <p style="text-align: center;"><strong>Undertaking & Self Declaration submitted by Head of the
                                Institute with
                                IIQA</strong><br>
                            <a style="color: rgb(237, 237, 255);font-size: 12px;" href="../../assets/pdf/NAAC/NAAC Undertaking.pdf">(Undertaking)</a>
                            <br>
                            <a style="color: rgb(237, 237, 255);font-size: 12px;" href="../../assets/pdf/NAAC/NAAC 2 Self declartion.pdf">(Self
                                Declaration)</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="    background-color: rgb(14 41 84);
    padding: 1rem;
    margin: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    color: white;
    height: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;">
                    <div>
                        <p style="text-align: center;"><strong>II. Quantitative (QnM) and Qualitative (QlM)
                                Parameters</strong><br>
                                <a style="font-size: 12px;">(View/Download)</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div>
                <h5 style="text-transform: uppercase; color: brown;text-align: center;font-weight: 600;">Criterion 1
                    Curricular Aspects</h5>
                <hr>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator – 1.1 Curricular Planning and
                                            Implementation</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>1.1.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>
                                        The Institution ensures effective curriculum planning and
                                        delivery through a well-planned and documented process including
                                        Academic calendar and conduct of continuous internal Assessment
                                    </b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%201/1.1.1/1.1.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 1.2 Academic Flexibility</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>1.2.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Number of Certificate/Value added courses offered and online
                                        courses of MOOCs, SWAYAM, NPTEL etc. where the students of the
                                        institution have enrolled and successfully completed during the
                                        last five years)</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%201/1.2.1/1.2.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>1.2.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of students enrolled in Certificate/ Value added
                                        courses and also completed online courses of MOOCs, SWAYAM,
                                        NPTEL etc. as against the total number of students during the
                                        last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%201/1.2.2/1.2.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 1.3 Curriculum Enrichment</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>1.3.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Institution integrates crosscutting issues relevant to
                                        Professional Ethics, Gender, Human Values, Environment and
                                        Sustainability in transacting the Curriculum</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%201/1.3.1/1.3.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td>
                                <p><b>1.3.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of students undertaking project work/field work/
                                        internships (Data for the latest completed academic year)</b>
                                </p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%201/1.3.2/1.3.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 1.4 Feedback System</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>1.4.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Institution obtains feedback on the academic performance and
                                        ambience of the institution from various stakeholders, such as
                                        Students, Teachers, Employers, Alumni etc. and action taken
                                        report on the feedback is made available on institutional
                                        website</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%201/1.4.1/1.4.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h5 style="text-transform: uppercase; color: brown;text-align: center;font-weight: 600;">Criterion 2
                    Teaching Learning and Evaluation</h5>
                <hr>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 2.1. Student Enrolment and Profile</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.1.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Enrolment percentage</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.1.1/2.1.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.1.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of seats filled against reserved categories (SC, ST,
                                        OBC etc.) as per applicable reservation policy for the first
                                        year admission during the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.1.2/2.1.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 2.2 </b><b>Student Teacher Ratio</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.2.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Student – Full time Teacher Ratio
                                        (Data for the latest completed academic year)
                                    </b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.2.1/2.2.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 2.3 Teaching- Learning Process</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.3.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Student centric methods, such as experiential learning,
                                        participative learning and problem solving methodologies are
                                        used for enhancing learning experiences and teachers use ICT-
                                        enabled tools including online resources for effective teaching
                                        and learning process</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.3.1/2.3.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 2.4 Teacher Profile and Quality</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.4.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of full-time teachers against sanctioned posts during
                                        the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.4.1/2.4.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.4.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of full time teachers with NET/SET/SLET/ Ph. D./D.Sc. /
                                        D.Litt./L.L.D. during the last five years (consider only highest
                                        degree for count)</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.4.2/2.4.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 2.5. Evaluation Process and Reforms</b></span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.5.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Mechanism of internal/ external assessment is transparent and the
                                        grievance redressal system is time- bound and efficient</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.5.1/2.5.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 2.6 Student Performance and Learning
                                            Outcome</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.6.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Programme Outcomes (POs) and Course Outcomes (COs) for all
                                        Programmes offered by the institution are stated and displayed
                                        on website</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.6.1/2.6.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.6.2</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Attainment of POs and COs are evaluated.</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.6.2/2.6.2.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>                                               
                            </td> -->
                        </tr>

                        <tr>
                            <td>
                                <p><b>2.6.3</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Pass percentage of Students during last five years (excluding
                                        backlog students)</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.6.3/2.6.3.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 2.7 Student Satisfaction Survey</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>2.7.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Online student satisfaction survey regarding to teaching learning
                                        process.</b></p>
                            </td>
                            <td>
                                <!-- <p><a href="" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%202/2.7.1/2.7.1.pdf" target="_blank">
                                        <b>Excel-
                                            Click here</b></a></p> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h5 style="text-transform: uppercase; color: brown;text-align: center;font-weight: 600;">Criterion 3
                    Research, Innovations, and Extension</h5>
                <hr>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator 3.1- Resource Mobilization for Research</b></span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>3.1.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Grants received from Government and non-governmental agencies for
                                        research projects / endowments in the institution during the
                                        last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%203/3.1.1/3.1.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 3.2 </b><b>Innovation Ecosystem</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>3.2.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Institution has created an ecosystem for innovations, Indian
                                        Knowledge System (IKS),including awareness about IPR,
                                        establishment of IPR cell, Incubation centre and other
                                        initiatives for the creation and transfer of
                                        knowledge/technology and the outcomes of the same are
                                        evident</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%203/3.2.1/3.2.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td>
                                <p><b>3.2.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Number of workshops/seminars/conferences including programs
                                        conducted on Research Methodology, Intellectual Property Rights
                                        (IPR) and entrepreneurship during the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%203/3.2.2/3.2.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 3.3 Research Publication and Awards</b></span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>3.3.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Number of research papers published per teacher in the Journals as
                                        notified on UGC CARE list during the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%203/3.3.1/3.3.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>3.3.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Number of books and chapters in edited volumes/books published and
                                        papers published in national/ international conference
                                        proceedings per teacher during last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%203/3.3.2/3.3.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 3.4 Extension Activities</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>3.4.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Outcomes of Extension activities in the neighborhood community in
                                        terms of impact and sensitizing the students to social issues
                                        for their holistic development during the last five years.</b>
                                </p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%203/3.4.1/3.4.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td>
                                <p><b>3.4.2</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Awards and recognitions received for extension activities from
                                        government / government recognised bodies</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%203/3.4.2/3.4.2.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td>
                                <p><b>3.4.3</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Number of extension and outreach programs conducted by the
                                        institution through organized forums including NSS/NCC with
                                        involvement of community during the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%203/3.4.3/3.4.3.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 3.5. Collaboration</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>3.5.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Number of functional MoUs/linkages with institutions/ industries
                                        in India and abroad for internship, on-the-job training, project
                                        work, student / faculty exchange and collaborative research
                                        during the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%203/3.5.1/3.5.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h5 style="text-transform: uppercase; color: brown;text-align: center;font-weight: 600;">Criterion 4
                    Infrastructure and Learning Resources</h5>
                <hr>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator 4.1- Physical Facilities</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>4.1.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <b>
                                    The Institution has adequate infrastructure and other facilities
                                    for
                                </b>
                                <ul style="margin-left: 1rem;">
                                    <li>a. teaching – learning, viz., classrooms, laboratories,
                                        computing equipment etc</li>
                                    <li>b. ICT – enabled facilities such as smart class, LMS
                                        etc. Facilities for Cultural and sports activities, yoga
                                        centre, games (indoor and outdoor), Gymnasium,
                                        auditorium etc</li>
                                </ul>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%204/4.1.1/4.1.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td>
                                <p><b>4.1.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of expenditure for infrastructure development and
                                        augmentation excluding salary during the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%204/4.1.2/4.1.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- </b><b>4.2 Library as a learning
                                            Resource</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>4.2.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Library is automated with digital facilities using Integrated
                                        Library Management System (ILMS), adequate subscriptions to
                                        e-resources and journals are made. The library is optimally used
                                        by the faculty and students</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%204/4.2.1/4.2.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 4.3 IT Infrastructure</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>4.3.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Institution frequently updates its IT facilities and provides
                                        sufficient bandwidth for internet connection</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%204/4.3.1/4.3.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td>
                                <p><b>4.3.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Student – Computer ratio (Data for the latest completed academic
                                        year)</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%204/4.3.2/4.3.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 4.4 Maintenance of Campus
                                            Infrastructure</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>4.4.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage expenditure incurred on maintenance of physical
                                        facilities and academic support facilities excluding salary
                                        component, during the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%204/4.4.1/4.4.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h5 style="text-transform: uppercase; color: brown;text-align: center;font-weight: 600;">Criterion 5
                    Student Support and Progression</h5>
                <hr>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator 5.1- Student Support</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>5.1.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of students benefited by scholarships and freeships
                                        provided by the institution, government and non-government
                                        bodies, industries, individuals, philanthropists during the last
                                        five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%205/5.1.1/5.1.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>5.1.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Following capacity development and skills enhancement activities
                                        are organised for improving students’ capability</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%205/5.1.2/5.1.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>5.1.3</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of students benefitted by guidance for competitive
                                        examinations and career counseling offered by the Institution
                                        during the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%205/5.1.3/5.1.3.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>5.1.4</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>The institution adopts the following for redressal of student
                                        grievances including sexual harassment and ragging cases</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%205/5.1.4/5.1.4.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 5.2 </b><b>Student Progression</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>5.2.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of placement of outgoing students and students
                                        progressing to higher education during the last five years</b>
                                </p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%205/5.2.1/5.2.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>5.2.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Percentage of students qualifying in state/national/ international
                                        level examinations during the last five years&nbsp;</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%205/5.2.2/5.2.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 5.3 Student Participation and
                                            Activities&nbsp;</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>5.3.1</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Number of awards/medals for outstanding performance in sports/
                                        cultural activities at University / state/ national /
                                        international level during the last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%205/5.3.1/5.3.1.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>5.3.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>Average number of sports and cultural programs in which students
                                        of the Institution participated during last five years</b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%205/5.3.2/5.3.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 5.4 Alumni Engagement&nbsp;</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>5.4.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>There is a registered Alumni Association that contributes
                                        significantly to the development of the institution through
                                        financial and/or other support services</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%205/5.4.1/5.4.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h5 style="text-transform: uppercase; color: brown;text-align: center;font-weight: 600;">Criterion 6
                    Governance, Leadership, and Management</h5>
                <hr>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 6.1 Institutional Vision and
                                            Leadership</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td><b>6.1.1</b></td>
                            <td><b>QlM</b></td>
                            <td><b>The institutional governance and leadership
                                    are in accordance with the vision and mission of the Institution and
                                    it is visible in various institutional practices such as NEP
                                    implementation, sustained institutional growth, decentralization,
                                    participation in the institutional governance and in their short
                                    term and long term Institutional Perspective Plan.</b></td>
                            <!-- <td ><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%206/6.1.1/6.1.1.pdf" target="_blank">
                                    <b>Proof- Clickhere</b></a></td> -->
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 6.2 </b><b>Strategy Development and
                                            Deployment&nbsp;</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td><b>6.2.1</b></td>
                            <td><b>QlM</b></td>
                            <td><b>The institutional perspective plan is
                                    effectively deployed and functioning of the institutional bodies is
                                    effective and efficient as visible from policies, administrative
                                    setup, appointment, service rules, and procedures, etc.</b></td>
                            <!-- <td ><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%206/6.2.1/6.2.1.pdf" target="_blank">
                                    <b>Proof- Clickhere</b></a></td> -->
                        </tr>
                        <tr>
                            <td><b>6.2.2</b></td>
                            <td><b>QnM</b></td>
                            <td><b>
                                    Implementation of e-governance in areas of
                                    operation
                                    1. Administration <br>
                                    2. Finance and Accounts <br>
                                    3. Student Admission and Support <br>
                                    4. Examination
                                </b></td>
                            <td><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%206/6.2.2/6.2.2.pdf"
                                    target="_blank">
                                    <b>Proof- Clickhere</b></a></td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 6.3 Faculty Empowerment
                                            Strategies&nbsp;</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td><b>6.3.1</b></td>
                            <td><b>QlM</b></td>
                            <td><b>
                                    The institution has performance appraisal system, effective welfare
                                    measures for teaching and non-teaching staff and avenues for career
                                    development/progression</b></td>
                            <!-- <td ><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%206/6.3.1/6.3.1.pdf" target="_blank">
                                    <b>Proof- Clickhere</b></a></td> -->
                        </tr>
                        <tr>
                            <td><b>6.3.2</b></td>
                            <td><b>QnM</b></td>
                            <td><b>
                                    Percentage of teachers provided with financial support to attend
                                    conferences/workshops and towards membership fee of professional
                                    bodies during the last five years</b>
                            </td>
                            <td><a
                                    href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%206/6.3.2/6.3.2.pdf">
                                    <b>Proof- Clickhere</b></a>
                                <p></p>

                            </td>
                        </tr>
                        <tr>
                            <td><b>6.3.3</b></td>
                            <td><b>QnM</b></td>
                            <td><b>
                                    Percentage of teaching and non-teaching staff participating in
                                    Faculty development Programmes (FDP), Management Development
                                    Programmes (MDPs) professional development
                                    /administrative training programs during the last five years
                                </b></td>
                            <td><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%206/6.3.3/6.3.3.pdf"
                                    target="_blank">
                                    <b>Proof- Clickhere</b></a>
                                <p></p>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 6.4 Financial Management and Resource
                                            Mobilization</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td><b>6.4.1</b></td>
                            <td><b>QlM</b></td>
                            <td><b>Institution has strategies for mobilization
                                    and optimal utilization of resources and funds from various sources
                                    (government/ non- government organizations) and it conducts
                                    financial audits regularly (internal and external)</b></td>
                            <!-- <td ><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%206/6.4.1/6.4.1.pdf" target="_blank">
                                    <b>Proof- Clickhere</b></a></td> -->
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 6.5 Internal Quality Assurance System</b></span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td><b>6.5.1</b></td>
                            <td><b>QlM</b></td>
                            <td><b>Internal Quality Assurance Cell (IQAC) has
                                    contributed significantly for institutionalizing the quality
                                    assurance strategies and processes. It reviews teaching learning
                                    process, structures & methodologies of operations and learning
                                    outcomes at periodic intervals and records the incremental
                                    improvement in various activities</b></td>
                            <!-- <td ><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%206/6.5.1/6.5.1.pdf" target="_blank">
                                    <b>Proof- Clickhere</b></a></td> -->
                        </tr>
                        <tr>
                            <td><b>6.5.2</b></td>
                            <td><b>QnM</b></td>
                            <td><b>
                                    Quality assurance initiatives of the institution include:

                                    1. Regular meeting of Internal Quality Assurance Cell (IQAC);
                                    quality improvement initiatives identified and implemented <br>
                                    2. Academic and Administrative Audit (AAA) and follow-up action
                                    taken <br>
                                    3. Collaborative quality initiatives with other institution(s) <br>
                                    4. Participation in NIRF and other recognized rankings <br>
                                    5. Any other quality audit/accreditation recognized by state,
                                    national or international agencies such as NAAC, NBA etc.

                                </b></td>
                            <td><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%206/6.5.2/6.5.2.pdf"
                                    target="_blank">
                                    <b>Proof- Clickhere</b></a>
                                <p></p>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h5 style="text-transform: uppercase; color: brown;text-align: center;font-weight: 600;">Criterion 7
                    Institutional Values and Best Practices</h5>
                <hr>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator 7.1- Institutional Values and Social
                                            Responsibilities</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #3366ff;"><b>Gender
                                            Equity and celebration of days of National/International
                                            commemoration</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>7.1.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Institution has initiated the Gender Audit and measures for the
                                        promotion of gender equity during the last five years.</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%207/7.1.1/7.1.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #3366ff;"><b>Environmental
                                            Consciousness and
                                            Sustainability and Divyangjan friendly
                                            initiatives</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>7.1.2</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>
                                        The Institution has facilities and initiatives for
                                        1. Alternate sources of energy and energy conservation measures
                                        <br>
                                        2. Management of the various types of degradable and non-
                                        degradable waste <br>
                                        3. Water conservation <br>
                                        4. Green campus initiatives <br>
                                        5. Disabled-friendly, barrier free environment

                                    </b>
                                </p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%207/7.1.2/7.1.2.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>7.1.3</b></p>
                            </td>
                            <td>
                                <p><b>QnM</b></p>
                            </td>
                            <td>
                                <p><b>
                                        Quality audits on environment and energy regularly undertaken by
                                        the Institution.
                                        The institutional environment and energy initiatives are
                                        confirmed through the following
                                        1. Green audit / Environment audit <br>
                                        2. Energy audit <br>
                                        3. Clean and green campus initiatives <br>
                                        4. Beyond the campus environmental promotion and sustainability
                                        activities

                                    </b></p>
                            </td>
                            <td>
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%207/7.1.3/7.1.3.pdf"
                                        target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #3366ff;"><b>Inclusion, Situatedness,
                                            Human values
                                            &amp; professional ethics</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>7.1.4</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>
                                        Describe the Institutional efforts/initiatives in providing an
                                        inclusive environment i.e., tolerance and harmony towards
                                        cultural, regional, linguistic, communal socioeconomic diversity
                                        and Sensitization of students and employees to the
                                        constitutional obligations: values, rights, duties and
                                        responsibilities of citizens
                                    </b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%207/7.1.4/7.1.4.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 7.2 Best Practices</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>7.2.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Describe two best practices successfully implemented by the
                                        Institution as per NAAC format provided in the Manual </b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%207/7.2.1/7.2.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                        <tr>
                            <td colspan="4">
                                <p style="text-align: center;"><span style="color: #002592;"><b>Key
                                            Indicator- 7.3 </b><b>Institutional
                                            Distinctiveness</b></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><b>7.3.1</b></p>
                            </td>
                            <td>
                                <p><b>QlM</b></p>
                            </td>
                            <td>
                                <p><b>Portray the performance of the Institution in one area distinctive
                                        to its priority and thrust within 1000 words</b></p>
                            </td>
                            <!-- <td >
                                <p><a href="http://naac.prpotepatilgroupedu.in/pharmacy/naac/Review/Criteria%207/7.3.1/7.3.1.pdf" target="_blank">
                                        <b>Proof-Click here</b></a></p>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <a routerLink="/naac-dvv">
                    <h5>DVV - Click here</h5>
                </a>
                <hr>
            </div>
        </div>
    </div>
</div>