import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alumni-meet',
  templateUrl: './alumni-meet.component.html',
  styleUrls: ['./alumni-meet.component.css']
})
export class AlumniMeetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Alumni Meet",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Alumni Meet",
  }
}
