<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>

    <div class="section-full wow fadeIn" data-wow-delay="0.4s">
        <div class="container">
            <div class="top-info" style="display: flex; justify-content: center; align-items: center;margin-top: 1rem;">
                <h4>Introduction</h4>
            </div>
            <hr>
            <div style="text-align: center;font-weight: 500">
                <p>Promoting high quality research in academics is necessary requirement for growth of individual and
                    any organization. The objective of Research & development (R&D) initiatives undertaken by PRPCEM is
                    to build research careers, support to strengthen the knowledge foundation and promote the creation
                    of new applications, as well as societal impact of education, research and innovation. All
                    education, research and innovation of this Institute should aim to be of a good quality. The goal of
                    creating technological and social innovations has emerged alongside R&D-based activities. </p>
                <p>The Research Promotion Scheme of Institute is aims to nurture research culture in the institute by
                    promoting research in newly emerging and challenging areas of science and engineering. It encourages
                    the Undergraduate, Postgraduate and Ph.D. Research Scholar candidates to undertake the research in
                    newly emerging frontier areas of science and Engineering including multidisciplinary fields. This
                    enhances the general research capability of budding technocrats by way of participating in
                    conferences, seminars, workshops, project competition, etc.</p>
            </div>

            <div class="top-info" style="display: flex; justify-content: center; align-items: center;margin-top: 1rem;">
                <h6>Aims & Objectives</h6>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <ul>
                        <li>To create research awareness among the faculty and students</li>
                        <li>To enhance teamwork between researchers for interdisciplinary research</li>
                        <li>To establish links with different Industries, R&D organizations, provide funding for
                            research
                            activities.</li>
                        <li>To involve every faculty member in R&D activities, lead to Ph.D. degree and research
                            proposal
                            writing.</li>
                        <li>To enhance the research awareness by conducting various national level and international
                            level
                            workshops and conferences and guest lectures.</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <ul>
                        <li>To establish the fruitful collaboration and interaction with researchers working on
                            interdisciplinary research</li>
                        <li>Establish mutually beneficial linkages with industry, business, and the society at large.
                        </li>
                        <li>Promote entrepreneurship among students and faculty.</li>
                        <li>Contribute to national endeavor to nurture talent for indigenous development of technology
                            and
                            technology products.</li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="top-info"
                        style="display: flex; justify-content: center; align-items: center;margin-top: 1rem;">
                        <h6>Constitution of R&D Cell (2020-21)</h6>
                    </div>
                    <div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <td>
                                        SN
                                    </td>
                                    <td>
                                        Name of Faculty
                                    </td>
                                    <td>
                                        Department
                                    </td>
                                    <td>
                                        Designation
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        1
                                    </td>
                                    <td>
                                        Dr. A. V. Kale
                                    </td>
                                    <td>
                                        –
                                    </td>
                                    <td>
                                        Principal &amp; Executive Head
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2
                                    </td>
                                    <td>
                                        Dr. S. B. Warkad
                                    </td>
                                    <td>
                                        Electrical Engg
                                    </td>
                                    <td>
                                        Dean (R&amp;D)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2
                                    </td>
                                    <td>
                                        Dr. S. M. Tondre
                                    </td>
                                    <td>
                                        Mechanical Engg
                                    </td>
                                    <td>
                                        Deptt. Coordinator
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3
                                    </td>
                                    <td>
                                        Dr. A. S. Telang
                                    </td>
                                    <td>
                                        Electrical Engg
                                    </td>
                                    <td>
                                        Deptt. Coordinator
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4
                                    </td>
                                    <td>
                                        Dr. V. B. Padole
                                    </td>
                                    <td>
                                        EXTC Engg
                                    </td>
                                    <td>
                                        Deptt. Coordinator
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        5
                                    </td>
                                    <td>
                                        Dr. C. A. Dhawale
                                    </td>
                                    <td>
                                        Computer Science Engg
                                    </td>
                                    <td>
                                        Deptt. Coordinator
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        6
                                    </td>
                                    <td>
                                        Dr. N. P. Bhople
                                    </td>
                                    <td>
                                        Civil Engg
                                    </td>
                                    <td>
                                        Deptt. Coordinator
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        7
                                    </td>
                                    <td>
                                        Dr. S. A. Shahare
                                    </td>
                                    <td>
                                        Applied Science &amp; Humanity
                                    </td>
                                    <td>
                                        Deptt. Coordinator
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        8
                                    </td>
                                    <td>
                                        Prof. N. S. Kariya
                                    </td>
                                    <td>
                                        MBA
                                    </td>
                                    <td>
                                        Deptt. Coordinator
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        9
                                    </td>
                                    <td>
                                        Mr. R. G. Khope, IoCare
                                    </td>
                                    <td>
                                        R&amp;D Organisation
                                    </td>
                                    <td>
                                        Opted Member
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        10
                                    </td>
                                    <td>
                                        Mr. Ravindra Sharma, Autosys Indore
                                    </td>
                                    <td>
                                        Industry Expert
                                    </td>
                                    <td>
                                        Opted member
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="top-info"
                        style="display: flex; justify-content: center; align-items: center;margin-top: 1rem;">
                        <h6>PhD Programme</h6>
                    </div>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td>Subject</td>
                                <td>Department</td>
                                <td>Intake</td>
                                <td>Supervisors</td>
                                <td>Contact Person</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Electrical Engineering</td>
                                <td>Electrical Engineering</td>
                                <td>04</td>
                                <td>
                                    <p>Dr. S. B. Warkad<br>Dr. A. S. Telang</p>
                                </td>
                                <td rowspan="5">Dr. S. B. Warkad, Dean (R&amp;D)</td>
                            </tr>
                            <tr>
                                <td>Electronics Engineering</td>
                                <td>Electronics Engineering</td>
                                <td>04</td>
                                <td>
                                    <p>Dr. D. G. Wakde<br>Dr. R. D. Ghongade<br>Dr. G. D. Dalvi<br>Dr.Padole</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Physics</td>
                                <td>Science &amp; Humanity</td>
                                <td>06</td>
                                <td>Dr. N. R. Thakare</td>
                            </tr>
                            <tr>
                                <td>Mechanical Engg</td>
                                <td>Mechanical Engineering</td>
                                <td>(Applied)</td>
                                <td>Dr. P. R. Wadnerkar</td>
                            </tr>
                            <tr>
                                <td>Computer Science</td>
                                <td>Computer Science &amp; Engineering</td>
                                <td>(Applied)</td>
                                <td>
                                    <p>Dr. A. D. Raut<br>Dr. Ajay Gadicha</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/RD-Policy.pdf" target="_blank">
                        <div class="tab_div">
                            R&D Policy
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/PhD-Cell-Policy.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Ph.D Cell Policy
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/3.-Faculty-for-Higher-Studies-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Faculty for Higher Studies
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/4.-Faculty-Participation-in-Conferences-STTP-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Faculty Participation in Conference/STTP
                        </div>
                    </a>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/5.-Conference-STTP-FDP-Organised-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Conference/STTP/FDP Organized
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/6.-Faculty-Student-Publications-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Faculty/Student Publications
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/7.-Patent-IPR-Publications-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Patent/IPR Publications
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/8.-Book-Chapter-Publications-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Book/Chapter Publication
                        </div>
                    </a>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/9.-Research-Guidance-Ph.D.-ME-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Research Guidance – M.E / PhD
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/10.-Sponsored-Research-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Sponsored Research
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/11.-Consultancy-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Consultancy
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/12.-Reserch-Lab-Product-Development-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Research Lab/Product Development
                        </div>
                    </a>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/13.-Technical-Magazine-Newsletter-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Technical Magazine & Newsletter
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/14.-Faculty-Interaction-with-Outside-world-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Faculty Interaction with Outside World
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/15.-Faculty-Achievement-Awards-Houours-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Faculty Achievement – Awards/Honours
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/16.-Peer-Reviewing-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Peer Reviewing
                        </div>
                    </a>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/17.-Technical-Wrokshop-Organised-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Technical Workshops
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/18.-Innovative-Live-Projects-1.pdf"
                        target="_blank">
                        <div class="tab_div">
                            Innovative/Live Projects    
                        </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/19.-MOU-with-Universities-and-Industries-.pdf"
                        target="_blank">
                        <div class="tab_div">
                            MOU with Universities and Industries
                        </div>
                    </a>
                </div>
            </div>
            <br>
        </div>
    </div>
</div>