import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anti-disc',
  templateUrl: './anti-disc.component.html',
  styleUrls: ['./anti-disc.component.css']
})
export class AntiDiscComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  
  banner: any = {
    pagetitle: "ANTI-DISCRIMINATION CELL",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "ANTI-DISCRIMINATION CELL",
  }
}
