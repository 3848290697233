import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-governing-body',
  templateUrl: './about-governing-body.component.html',
  styleUrls: ['./about-governing-body.component.css']
})
export class AboutGoverningBodyComponent implements OnInit {
  
  banner: any = {
    pagetitle: " governing body",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: " Governing Body",
  }

  constructor() { }

  ngOnInit(): void {
  }

}
