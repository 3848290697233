import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HomeAboutMgtComponent } from '../home-about-mgt/home-about-mgt.component';
import data from '../home-team/about_mgt.json'
import { SPharmaWebsiteService } from 'src/app/s-pharma-website.service';

@Component({
  selector: 'app-home-principal',
  templateUrl: './home-principal.component.html',
  styleUrls: ['./home-principal.component.css']
})
export class HomePrincipalComponent implements OnInit {

  constructor(public dialog: MatDialog, public objSPharmaWebsiteService: SPharmaWebsiteService) { }

  ngOnInit(): void {
    this.objSPharmaWebsiteService.getFaculty()
  }

  open(id) {
    this.dialog.open(HomeAboutMgtComponent, {
      // disableClose: true,
      data: data.filter(s=>s.id == id)
    });
  }
}
