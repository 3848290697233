import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anti-ragging',
  templateUrl: './anti-ragging.component.html',
  styleUrls: ['./anti-ragging.component.css']
})
export class AntiRaggingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Anti-Ragging / Anti-Harassment Committee",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "ANTI RAGGING/ HARASSMENT COMMITTEE",
  }
}
