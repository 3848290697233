<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div class="container">
            <header class="section-header">
                <h3>Student Publications</h3>
            </header>
            <div class="row">
                <div class="col-md-12">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h2 class="mb-0"><button type="button" class="btn btn-link"
                                        style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                        data-target="#collapseThree" aria-expanded="true"
                                        aria-controls="collapseThree">Publication Year 2022</button></h2>
                            </div>
                            <div class="collapse" id="collapseThree" aria-labelledby="headingThree"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <div class="container">
                                        <div class="row">
                                            <div class="table-responsive">
                                                <table class="table table-hover mytable table-bordered">
                                                    <thead style="color:white;background-color: #f2622d">
                                                        <tr>
                                                            <th scope="col">Sr. No.</th>
                                                            <th scope="col" style="width: 20%;">Name of the Author</th>
                                                            <!--  <th scope="col">Publication Year </th> -->
                                                            <th scope="col">Title of the Research Paper</th>
                                                            <th scope="col">Details of the Publication </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0"><button type="button" class="btn btn-link"
                                        style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                        data-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne">Publication Year 2021</button></h2>
                            </div>
                            <div class="collapse" id="collapseOne" aria-labelledby="headingOne"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <div class="container">
                                        <div class="row">
                                            <div class="table-responsive">
                                                <table class="table table-hover mytable table-bordered">
                                                    <thead style="color:white;background-color: #f2622d">
                                                        <tr>
                                                            <th scope="col">Sr. No.</th>
                                                            <th scope="col" style="width: 20%;">Name of the Author</th>
                                                            <!--  <th scope="col">Publication Year </th> -->
                                                            <th scope="col">Title of the Research Paper</th>
                                                            <th scope="col">Details of the Publication </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h2 class="mb-0"><button type="button" class="btn btn-link"
                                        style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                        data-target="#collapseTwo" aria-expanded="true"
                                        aria-controls="collapseTwo">Publication Year 2020</button></h2>
                            </div>
                            <div class="collapse" id="collapseTwo" aria-labelledby="headingTwo"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <div class="container">
                                        <div class="row">
                                            <div class="table-responsive">
                                                <table class="table table-hover mytable table-bordered">
                                                    <thead style="color:white;background-color: #f2622d">
                                                        <tr>
                                                            <th scope="col">Sr. No.</th>
                                                            <th scope="col" style="width: 20%;">Name of the Author</th>
                                                            <!-- <th scope="col">Publication Year </th> -->
                                                            <th scope="col">Title of the Research Paper</th>
                                                            <th scope="col">Details of the Publication </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td scope="row">1</td>
                                                            <td>Shivani kotamkar, Samiksha Burange, Gauri Rathod, Diksha
                                                                Ghorpade </td>
                                                            <!-- <td>2020</td> -->
                                                            <td>Preparation and Evaluation of Novel Candy Lozenges
                                                                Containing Fluoxetine Hydrochloride </td>
                                                            <td>International Journal of Scientific Development and
                                                                Research (IJSDR) July-2020 Vol.5, Issue 9, page no.559 -
                                                                565</td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">2</td>
                                                            <td>Diksha Ghorpade, Shivani kotamkar, samiksha Burange,
                                                                Gauri Rathod </td>
                                                            <!-- <td>2020</td> -->
                                                            <td>Preparation and Evaluation of Gastroretentive drug
                                                                delivery system of Gliclazide</td>
                                                            <td> International Journal of Scientific Development and
                                                                Research (IJSDR) July-2020 Vol.5, Issue 10, page no.29 -
                                                                35</td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">3</td>
                                                            <td>Samiksha Burange, Gauri Rathod, Diksha Ghorpade, Shivani
                                                                kotamkar </td>
                                                            <!-- <td>2020</td> -->
                                                            <td>Formulation and Evaluation of Effervescent Floating
                                                                Tablet of Cefuroxime Axetil </td>
                                                            <td>International Journal of Scientific Development and
                                                                Research (IJSDR), July-2020 Vol.5, Issue 7, page no.660
                                                                - 667</td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">4</td>
                                                            <td>Vaishnavi Ladhake, Samiksha Kadu, Shweta Gawande,
                                                                Vaishnavi Tayade </td>
                                                            <!-- <td>2020</td> -->
                                                            <td>Preparation and Evaluation of Oral Fast Dissolving Films
                                                                Of Bilastine Using Pullulan</td>
                                                            <td>International Journal of Scientific Development and
                                                                Research (IJSDR) June-2020, Vol.5, Issue 6, page no.282
                                                                - 287,</td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">5</td>
                                                            <td>Vanshika Sahu*, Apurva Deshmukh, Aachal Kolhe, Nikita
                                                                Wakchaware.<br>
                                                            </td>
                                                            <!-- <td>2020</td> -->
                                                            <td>Formulation and Evaluation of Mucoadhesive Buccal Tablet
                                                                of Miconazole Nitrate for Oral Candidiasis. <br>
                                                            </td>
                                                            <td>International Journal of Scientific Development and
                                                                Research, July-2020,Vol.5, Issue 7, page no.590 - 597.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">6</td>
                                                            <td>Gauri Dhule, Mohini Murkute, Vedanti Wadokar </td>
                                                            <!-- <td>2020</td> -->
                                                            <td>Formulation and Evaluation of medicated chewing gum of
                                                                Jasminum leaves for the treatment of mouth ulcer </td>
                                                            <td>World journal of pharmacy and pharmaceutical
                                                                sciences2020, Volume 9, Issue 11, 1386-1394</td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">7</td>
                                                            <td>Pallavi Wadaskar , Mayuri Urkude, Ashwini Munjewar,
                                                                Hemangi Vaishnav </td>
                                                            <!-- <td>2020</td> -->
                                                            <td>Preparation and evaluation of Transdermal patches of
                                                                Bifonazole </td>
                                                            <td>International Journal of Scientific Development and
                                                                Research, July-2020, Vol.5, Issue 7, page no.467 - 470.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</div>