<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div>
            <main id="main"><br>
                <header class="section-header">
                    <h3>Faculty awards and accolades</h3>
                    <h5 class="text-center"><b>Academic Year 2019-20</b></h5><br><br>
                </header>
                <div class="container">
                    <div class="table-responsive">
                        <table class="table table-hover mytable">
                            <thead>
                                <tr>
                                    <th scope="col">Sr. No.</th>
                                    <th scope="col" style="width:20%">Name of Faculty</th>
                                    <th scope="col" class="text-center">Awards Recieved</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Mr. Prasad R. Deshmukh</td>
                                    <td>Won 1st Prize at 14th Intra University Student Research Convection Avishkar 2019 organized by SGBAU, in Medicine and Pharmacy Category at Ph.D level and<br>Representing SGBAU for State level Avishkar 2019-20 competitions
                                        held on 28th Jan 2020 at Mumbai University.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div><br><br><br>
                <header class="section-header">
                    <h3>Students awards and accolades</h3>
                    <h5 class="text-center"><b>Academic Year 2019-20</b></h5><br><br>
                </header>
                <div class="container">
                    <div class="table-responsive">
                        <table class="table table-hover mytable">
                            <thead>
                                <tr>
                                    <th scope="col">Sr. No.</th>
                                    <th scope="col" style="width:20%">Name of Student</th>
                                    <th scope="col" class="text-center">Awards Recieved</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>