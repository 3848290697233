import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buletin',
  templateUrl: './buletin.component.html',
  styleUrls: ['./buletin.component.css']
})
export class BuletinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Bulletin",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Bulletin",
  }
}
