import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SPharmaWebsiteService {

  constructor(private http: HttpClient) { }

  arrFaculty: any = []
  arrPublication: any = []
  arrNotices: any = []

  getFaculty(){
    this.http.get(environment.apiKey+ 'pharma_faculty').subscribe(
      (res:any)=>{
        this.arrFaculty = res
      }
    )
  }

  getPublication(){
    this.http.get(environment.apiKey+ 'pharma_publication').subscribe(
      (res:any)=>{
        this.arrPublication = res
      }
    )
  }

  getNotices(){
    this.http.get(environment.apiKey+ 'pharma_notices').subscribe(
      (res:any)=>{
        this.arrNotices = res
      }
    )
  }
}
