<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">

        <table class="table table-bordered table-hover">
            <thead>
                <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Organization Position</th>
                    <th scope="col">Designation</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Hon’ble Shri. Pravin R. Pote</td>
                    <td>Chairman</td>
                    <td>Chairman</td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Hon’ble Shri. Shreyas P. Pote</td>
                    <td>Vice-Chairman</td>
                    <td>Vice-Chairman</td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Dr. Mahendra P. Dhore</td>
                    <td>Trust Member</td>
                    <td>Member
                        [Nominated by Trust]
                    </td>
                </tr>
                <tr>
                    <th scope="row">4</th>
                    <td>Adv. Pravin M. Mohod</td>
                    <td>Trust Member</td>
                    <td>Member
                        [Nominated by Trust]
                    </td>
                </tr>
                <tr>
                    <th scope="row">5</th>
                    <td>Dr. D. T. Ingole</td>
                    <td>Director</td>
                    <td>Member
                        [Nominated by Trust]
                    </td>
                </tr>

                <tr>
                    <th scope="row">6</th>
                    <td>Dr. Mohd. Zuhair</td>
                    <td>Expert from Other Institute</td>
                    <td>Member
                        [Nominated by Trust]
                    </td>
                </tr>

                <tr>
                    <th scope="row">7</th>
                    <td>Shri. Prakash Pund </td>
                    <td>Field of Social Service</td>
                    <td>Member
                        [Nominated by Trust]
                    </td>
                </tr>
                <tr>
                    <th scope="row">8</th>
                    <td>Mr. Kishor B. Deshmukh</td>
                    <td>Administrative Officer</td>
                    <td>Member (Nominated by Trust)</td>
                </tr>

                <tr>
                    <th scope="row">9</th>
                    <td>Nominee from PCI</td>
                    <td>-</td>
                    <td>Yet to receive Nomination</td>
                </tr>

                <tr>
                    <th scope="row">10</th>
                    <td>Dr. Vijay R. Mankar </td>
                    <td>Joint Director,
                        RO DTE, Amravati
                    </td>
                    <td>Member</td>
                </tr>

                <tr>
                    <th scope="row">11</th>
                    <td>Nominee from SGBAU Amravati</td>
                    <td>-</td>
                    <td>Yet to receive Nomination</td>
                </tr>

                <tr>
                    <th scope="row">12</th>
                    <td>Mr. Mahesh D. Pawar</td>
                    <td>Field of Industry</td>
                    <td>Member</td>
                </tr>

                <tr>
                    <th scope="row">13</th>
                    <td>Mr. Prasad R. Deshmukh </td>
                    <td>Head of Department</td>
                    <td>Invited Member
                        (Teaching Staff)
                    </td>
                </tr>
                <tr>
                    <th scope="row">14</th>
                    <td>Mr. Pankaj H. Chaudhary</td>
                    <td>Teaching Member</td>
                    <td>Invited Member
                        (Teaching Staff)
                    </td>
                </tr>
                <tr>
                    <th scope="row">15</th>
                    <td>Dr. Dipti Bipinchandra Ruikar (Deshmukh)</td>
                    <td>Principal</td>
                    <td>Member Secretary</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>