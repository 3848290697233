import { Component, OnInit } from '@angular/core';
import data from './event.json'
import img from './event_img.json'
import { MatDialog } from '@angular/material/dialog';
import { ActivityDetailsComponent } from '../department/activity-details/activity-details.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  arrmEvents: mEvents[]= data
  arrmEventImages: mEventImages[] = img

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  
  banner: any = {
    pagetitle: "Events",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Events",
  }

  openActivity(obj) {
    this.dialog.open(ActivityDetailsComponent, {
      panelClass: 'custom-dialog-container',
      // disableClose: true
      data: {
        dataActivity: obj
      }
    });
    console.log(obj)
  }
}


export class mEvents {
  id: string
  title: string
  sub_title_date: string
  short_content: string
  content: string
  banner: string
  pdf: string
  sub_title_posted_by: string
  sub_title_year: string
}

export class mEventImages {
  id: string
  image: string
}