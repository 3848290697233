<div class="section-full bg-gray content-inner">
    <div class="container">
        <div class="section-head m-b20">
            <h2 class="title" style="color: #ffffff;font-family: Rockwell !important;font-weight: 500;">Our Pillars</h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div class="dlab-box dlab-team1"
                    style="display: grid;grid-template-columns: 30% 70%;background-color: #fff;border-top: 5px solid brown;">
                    <div style="padding: 1rem;">
                        <img alt="" class="lazy" src="assets/images/chairman.png">
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div>
                            <h2><b>Shri. Pravin R. Pote Patil</b></h2>
                            <b>Chairman</b><br> P. R. Pote Patil Education &amp; Welfare Trust's
                            Group of Institutions, Amravati. &amp; MLC, Amravati, Ex State Minister, Maharashtra <br>
                            <p>
                                <a (click)="open(1)" style="cursor: pointer;color: rgb(0, 51, 161);">Read more...</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div class="dlab-box dlab-team1"
                    style="display: grid;grid-template-columns: 30% 70%;background-color: #fff;border-top: 5px solid brown;">
                    <div style="padding: 1rem;">
                        <img alt="" class="lazy" src="assets/images/vice-chairman.png">
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div>
                            <h2><b>Shri. Shreyas Pote Patil</b></h2>
                            <b>Vice Chairman</b><br> P. R. Pote Patil Education &amp; Welfare Trust's
                            Group of Institutions, Amravati.
                            <p>
                                <a (click)="open(2)" style="cursor: pointer;color: rgb(0, 51, 161);">Read more...</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row" style="margin-top: 1.5rem;">
            <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div class="dlab-box dlab-team1"
                    style="display: grid;grid-template-columns: 30% 70%;background-color: #fff;border-top: 5px solid brown;">
                    <div style="padding: 1rem;">
                        <img alt="" class="lazy" src="assets/images/principal.png">
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div>
                            <h2><b>Dr. D. T. Ingole</b></h2>
                            <b>Principal</b><br>
                            P. R. Pote Patil College of Engineering and Management, Amravati
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div class="dlab-box dlab-team1"
                    style="display: grid;grid-template-columns: 30% 70%;background-color: #fff;border-top: 5px solid brown;">
                    <div style="padding: 1rem;">
                        <img alt="" class="lazy" src="assets/images/vice-principal.jpg">
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div>
                            <h2><b>Dr. Mohammad Zuhair</b></h2>
                            <b>Vice Principal</b><br> P. R. Pote Patil College of Engineering and Management, Amravati
                        </div>
                    </div>
                </div>
            </div>
           -->
    </div>

    
   
</div>