import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqac-old',
  templateUrl: './iqac-old.component.html',
  styleUrls: ['./iqac-old.component.css']
})
export class IqacOldComponent implements OnInit {

  banner: any = {
    pagetitle: "IQAC OLD",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "IQAC OLD",
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
