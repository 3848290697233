import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copyrights',
  templateUrl: './copyrights.component.html',
  styleUrls: ['./copyrights.component.css']
})
export class CopyrightsComponent implements OnInit {

  banner: any = {
    pagetitle: "COPYRIGHTS",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Copyrights",
  }

  constructor() { }

  ngOnInit(): void {
  }

}
