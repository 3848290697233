import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sc-st',
  templateUrl: './sc-st.component.html',
  styleUrls: ['./sc-st.component.css']
})
export class ScStComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "SC/ST CELL",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "SC/ST CELL",
  }
}
