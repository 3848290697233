import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oral-presentation',
  templateUrl: './oral-presentation.component.html',
  styleUrls: ['./oral-presentation.component.css']
})
export class OralPresentationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "Oral Presentation",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Oral Presentation",
  }
}
