<div class="section-full content-inner">
    <div class="container">
        <div class="row">
            <!-- Side bar start -->
            <div class="col-xl-3 col-lg-4 col-md-12 m-b30">
                <aside class="side-bar sticky-top">
                    <div class="widget">
                        <div class="teacher-info text-center" style="padding: 6px 15px;">
                            <div class="top-info" style="display: flex;justify-content: center;align-items: center;">
                                <span class="price text-primary">CONTACT INFORMATION</span>
                            </div>
                            <div class="teacher-pic">
                                <a href="#"><img src="assets/images/staff/ai/1.png" alt=""></a>
                            </div>
                            <h5 class="name" style="font-weight: 600;font-size: 17px;color: #ffe9df;">Dr. Ajay B.
                                Gadicha</h5>
                            <span class="position" style="font-weight: 600;font-size: 14px">Head, <br>
                                Department of Artificial Intelligence & Data Science

                            </span>
                            <span class="position"
                                style="font-weight: 600;font-size: 13px;text-transform: lowercase;"><i
                                    class="fa fa-phone"></i> 9850303844 <br>
                                <i class="fa fa-envelope"></i> atuldraut@gmail.com
                            </span>
                            <div class="clearfix m-b20">
                                <a target="_blank" href="../../../../assets/images/staff-cv/ai/hod-ai.pdf"
                                    class="site-button button-sm">Profile <i class="fa fa-download m-l5"></i></a>
                            </div>
                            <ul class="social-list">
                                <li><a href="javascript:void(0);"
                                        class="site-button circle fa fa-facebook facebook"></a></li>
                                <li><a href="javascript:void(0);" class="site-button circle fa fa-twitter twitter"></a>
                                </li>
                                <li><a href="javascript:void(0);"
                                        class="site-button circle fa fa-linkedin linkedin"></a></li>
                                <li><a href="javascript:void(0);"
                                        class="site-button circle fa fa-whatsapp whatsapp"></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="widget widget_archive">
                        <h5 class="widget-title style-1">Department Facilities</h5>
                        <ul>
                            <li><a routerLink="/ai-ds">AI Home</a></li>
                            <li><a routerLink="/ai-ds/ai-peo">PEO’s, PO’s, PSO’s</a></li>
                        </ul>
                    </div>
                </aside>
            </div>
            <!-- Side bar END -->
            <!-- left part start -->
            <div class="col-xl-9 col-lg-8 col-md-12 m-b30">
                <div class="row">
                    <div class="col-lg-12">

                        <!-- Tabs -->
                        <div class="course-details dlab-tabs border-top bg-tabs">
                            <ul class="nav nav-tabs ">
                                <li>
                                    <a data-toggle="tab" target="_blank" href="#description" class="active">
                                        <i class="fa fa-bookmark m-r10"></i>
                                        <span class="title-head">Description</span>
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="tab" target="_blank" href="#curriculum">
                                        <i class="fa fa-cube"></i>
                                        <span class="title-head">Laboratories </span>
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="tab" target="_blank" href="#instructor">
                                        <i class="fa fa-user"></i>
                                        <span class="title-head">Faculties</span>
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="tab" target="_blank" href="#review">
                                        <i class="fa fa-comments"></i>
                                        <span class="title-head">Downloads </span>
                                        <!-- <span class="text-primary">(3)</span> -->
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div id="description" class="tab-pane active">
                                    <h3 class="m-t0 m-b10">About This Course</h3>
                                    <p>Artificial Intelligence and Data Science is an interdisciplinary field that
                                        uses scientific methods,
                                        processes, algorithms and systems to extract knowledge and insights from
                                        many structural and
                                        unstructured data. Data science is all about using data to solve problems.
                                        Data science is bringing
                                        together all aspects of technology required for gathering, storing,
                                        analysing, and understanding data.
                                        This includes storage technology, distributed computing, data-driven
                                        modelling, data analytics and
                                        mining, visualization, and security, among others.</p>
                                    <!-- <h3 class="m-b10">Course’s Objectives</h3> -->
                                    <p>Data-driven businesses are worth $1.2 trillion collectively in 2020. India
                                        has welcomed technology with
                                        open arms and with initiatives like ‘Digital India’, it will only encourage
                                        the integration of
                                        technology in all spheres of life. India had over 564.5 million Internet
                                        users in 2020 and the number is
                                        consistently increasing. According to a study of the data science job
                                        market, 40% of global companies
                                        struggle to hire and retain data scientists. 1/3 of the top 400 Indian
                                        companies lack state-of-the-art
                                        data analysis tools and personnel. Further, the study estimate that 364,000
                                        new jobs will be created in
                                        data by 2020 in India. According to a report from NASSCOM, by 2021, the
                                        total Data science & AI job
                                        openings in India is estimated to go by 2,30,000. But the fresh employable
                                        talent or university talent
                                        available will be just 90,000, leaving a huge gap of 1,40,000.
                                    </p>
                                    <P>
                                        The student will be studying basic science and standard engineering subjects
                                        along with programming
                                        tools required for data science in the first year. In the second and third
                                        year, they will be
                                        specializing in data science-related topics, along with the required
                                        computational mathematics and
                                        statistical skills. The core subjects taught include fundamental subjects
                                        from computer science along
                                        with data analytics, cloud computing & management, machine learning, big
                                        data, and deep learning. In the
                                        last two semesters, students will specialize in the particular domain area
                                        of their interest by studying
                                        domain-specific subjects and doing a full semester project in the industry
                                        on the domain-related
                                        problem. Students taking the course will also intern at companies and take
                                        up projects in data science.
                                        This program provides ample opportunity for students to specialize in a
                                        particular aspect of data
                                        science through the electives and the project.
                                    </P>

                                    <h5 style="background: #ffd8a9;color: #b52e04; padding: 4px; text-transform: uppercase; font-weight: 600; text-align: center; font-size: 12px;"
                                        class="m-t0 m-b10">Vision</h5>
                                    <p
                                        style="text-align: justify;font-size: 15px; font-weight: 600; text-align: center; background: #b1b1b126; padding: 15px;">
                                        <li>To impart quality education and conduct research in data science
                                            relevant to needs of the industry,
                                            national and international community that will help to improve the
                                            quality of human life related to
                                            Data Science.</li>
                                    </p>
                                    <h5 style="background: #ffd8a9;color: #b52e04; padding: 4px; text-transform: uppercase; font-weight: 600; text-align: center; font-size: 12px;"
                                        class="m-t0 m-b10">Mission</h5>
                                    <p
                                        style="text-align: justify;font-size: 14px; font-weight: 600;  background: #b1b1b126; padding: 15px;">
                                        <li>To prepare human resources with technical and management skills to meet
                                            the contemporary and
                                            futuristic Data Science demands of the industry and society at large by
                                            delivering relevant
                                            curriculum, using the state-of-the-art pedagogical innovations, and
                                            undertaking relevant research
                                            and especially practical on field applications of technology.</li>
                                    </p>

                                    <div id="comments-1" class="dlab-instructor">
                                        <ol class="commentlist">
                                            <li class="comment photo_staff">
                                                <div class="comment_container"> <img class="avatar avatar-60 photo"
                                                        src="assets/images/staff/ai/1.png" alt="">
                                                    <div class="comment-text">
                                                        <h6 style="font-size: 15px;" class="author m-t0">Dr. Ajay B.
                                                            Gadicha</h6>
                                                        <div class="w3-separator bg-primary"></div>
                                                        <div class="description m-b15">
                                                            <p>Assistant Professor</p>
                                                        </div>
                                                        <div class="clearfix m-b20">
                                                            <a target="_blank"
                                                                href="../../../../assets/images/staff-cv/ai/hod-ai.pdf"
                                                                class="site-button button-sm">Profile <i
                                                                    class="fa fa-download m-l5"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="comment_container"> <img class="avatar avatar-60 photo"
                                                        src="assets/images/staff/ai/11.png" alt="">
                                                    <div class="comment-text">
                                                        <h6 style="font-size: 15px;" class="author m-t0">Prof.
                                                            Kalyani K. Sukhadan</h6>
                                                        <div class="w3-separator bg-primary"></div>
                                                        <div class="description m-b15">
                                                            <p>Assistant Professor</p>
                                                        </div>
                                                        <div class="clearfix m-b20">
                                                            <a target="_blank"
                                                                href="../../../../assets/images/staff-cv/ai/4.pdf"
                                                                class="site-button button-sm">Profile <i
                                                                    class="fa fa-download m-l5"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="comment_container"> <img class="avatar avatar-60 photo"
                                                        src="assets/images/staff/ai/12.jpg" alt="">
                                                    <div class="comment-text">
                                                        <h6 style="font-size: 15px;" class="author m-t0">Prof. Jyoti
                                                            C. Bambal</h6>
                                                        <div class="w3-separator bg-primary"></div>
                                                        <div class="description m-b15">
                                                            <p>Assistant Professor</p>
                                                        </div>
                                                        <div class="clearfix m-b20">
                                                            <a target="_blank"
                                                                href="../../../../assets/images/staff-cv/ai/5.pdf"
                                                                class="site-button button-sm">Profile <i
                                                                    class="fa fa-download m-l5"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="comment_container"> <img class="avatar avatar-60 photo"
                                                        src="assets/images/staff/ai/13.jpeg" alt="">
                                                    <div class="comment-text">
                                                        <h6 style="font-size: 15px;" class="author m-t0"> Prof.
                                                            Laxmikant S. Bhattad </h6>
                                                        <div class="w3-separator bg-primary"></div>
                                                        <div class="description m-b15">
                                                            <p>Assistant Professor</p>
                                                        </div>
                                                        <div class="clearfix m-b20">
                                                            <a target="_blank"
                                                                href="../../../../assets/images/staff-cv/ai/6.pdf"
                                                                class="site-button button-sm">Profile <i
                                                                    class="fa fa-download m-l5"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div id="curriculum" class="tab-pane">
                                    <div class="row">
                                        <div class="col-md-6 col-lg-4 col-sm-6">
                                            <div class="dlab-box courses-bx">
                                                <div class="dlab-media">
                                                    <img src="assets/images/labs/ai/1.jpg" alt="">
                                                    <div class="user-info">
                                                        <h6 class="title">AI Department</h6>
                                                    </div>
                                                </div>
                                                <div class="dlab-info" style="text-align: center">
                                                    <h6 class="dlab-title"><a> Computer Center</a></h6>
                                                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-4 col-sm-6">
                                            <div class="dlab-box courses-bx">
                                                <div class="dlab-media">
                                                    <img src="assets/images/labs/ai/2.jpg" alt="">
                                                    <div class="user-info">
                                                        <h6 class="title">AI Department</h6>
                                                    </div>
                                                </div>
                                                <div class="dlab-info" style="text-align: center">
                                                    <h6 class="dlab-title"><a> Emerging Technology Lab</a></h6>
                                                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-4 col-sm-6">
                                            <div class="dlab-box courses-bx">
                                                <div class="dlab-media">
                                                    <img src="assets/images/labs/ai/3.jpg" alt="">
                                                    <div class="user-info">
                                                        <h6 class="title">AI Department</h6>
                                                    </div>
                                                </div>
                                                <div class="dlab-info" style="text-align: center">
                                                    <h6 class="dlab-title"><a> Database Laboratory</a></h6>
                                                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="instructor" class="tab-pane">

                                    <div id="comments-1" class="dlab-instructor">
                                        <ol class="commentlist">
                                            <li class="comment photo_staff">
                                                <div class="comment_container"> <img class="avatar avatar-60 photo"
                                                        src="assets/images/staff/ai/1.png" alt="">
                                                    <div class="comment-text">
                                                        <h6 style="font-size: 15px;" class="author m-t0">Dr. Ajay B.
                                                            Gadicha</h6>
                                                        <div class="w3-separator bg-primary"></div>
                                                        <div class="description m-b15">
                                                            <p>Assistant Professor</p>
                                                        </div>
                                                        <div class="clearfix m-b20">
                                                            <a target="_blank"
                                                                href="../../../../assets/images/staff-cv/ai/hod-ai.pdf"
                                                                class="site-button button-sm">Profile <i
                                                                    class="fa fa-download m-l5"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="comment_container"> <img class="avatar avatar-60 photo"
                                                        src="assets/images/staff/ai/11.png" alt="">
                                                    <div class="comment-text">
                                                        <h6 style="font-size: 15px;" class="author m-t0">Prof.
                                                            Kalyani K. Sukhadan</h6>
                                                        <div class="w3-separator bg-primary"></div>
                                                        <div class="description m-b15">
                                                            <p>Assistant Professor</p>
                                                        </div>
                                                        <div class="clearfix m-b20">
                                                            <a target="_blank"
                                                                href="../../../../assets/images/staff-cv/ai/4.pdf"
                                                                class="site-button button-sm">Profile <i
                                                                    class="fa fa-download m-l5"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="comment_container"> <img class="avatar avatar-60 photo"
                                                        src="assets/images/staff/ai/12.jpg" alt="">
                                                    <div class="comment-text">
                                                        <h6 style="font-size: 15px;" class="author m-t0">Prof. Jyoti
                                                            C. Bambal</h6>
                                                        <div class="w3-separator bg-primary"></div>
                                                        <div class="description m-b15">
                                                            <p>Assistant Professor</p>
                                                        </div>
                                                        <div class="clearfix m-b20">
                                                            <a target="_blank"
                                                                href="../../../../assets/images/staff-cv/ai/5.pdf"
                                                                class="site-button button-sm">Profile <i
                                                                    class="fa fa-download m-l5"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="comment_container"> <img class="avatar avatar-60 photo"
                                                        src="assets/images/staff/ai/13.jpeg" alt="">
                                                    <div class="comment-text">
                                                        <h6 style="font-size: 15px;" class="author m-t0"> Prof.
                                                            Laxmikant S. Bhattad </h6>
                                                        <div class="w3-separator bg-primary"></div>
                                                        <div class="description m-b15">
                                                            <p>Assistant Professor</p>
                                                        </div>
                                                        <div class="clearfix m-b20">
                                                            <a target="_blank"
                                                                href="../../../../assets/images/staff-cv/ai/6.pdf"
                                                                class="site-button button-sm">Profile <i
                                                                    class="fa fa-download m-l5"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div id="review" class="tab-pane comments-area">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- left part start -->
        </div>
    </div>
</div>