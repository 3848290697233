<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div>
            <main id="main">
                <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                    <div class="container">
                        <header class="section-header">
                            <h3 style="font-size:20px">Oral Presentation</h3>
                        </header>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="di"><b>Date:</b> 14th February 2020<br>
                                        <hr><b>Venue:</b> Conference Hall of the Hotel Central Regenta, Nagpur.<br>
                                        <hr><b>Details:</b> Society for Research Development in Health Science (SRDHS)
                                        Sponsored 2nd International Conference in Collaboration with Dadasaheb Balpande
                                        College of Pharmacy (DBCP) on Theme: “Invigorating Research
                                        in Pharmaceutical's: Reasonable Industrial approach” Prof. Sohani Solanke
                                        Presented project entitled “ INVESTIGATING PLANT DERIVED SURGICAL SUTURES” in
                                        which she investigated two plants i.e the strongest plant fiber
                                        Agave americana L. var. americana (agavaceae) and ) a smoothest plant fibers
                                        Musa paradesica L.(musaceae for compatibility as surgical sutures with in-vivo
                                        and In-vitro study.<br><br>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-4"><a
                                                        href="../../../assets/images/presentation/p1.jpg"
                                                        class="link-preview" data-lightbox="nime"
                                                        data-title="ORAL PRESENTATION By Prof. Sohani Solanke"
                                                        title="Preview"><img
                                                            src="../../../assets/images/presentation/p1.jpg"
                                                            class="img-fluid link-preview"></a></div>
                                                <div class="col-md-4"><a
                                                        href="../../../assets/images/presentation/p2.jpg"
                                                        class="link-preview" data-lightbox="nime"
                                                        data-title="ORAL PRESENTATION By Prof. Sohani Solanke"
                                                        title="Preview"><img
                                                            src="../../../assets/images/presentation/p2.jpg"
                                                            class="img-fluid link-preview"></a></div>
                                            </div>
                                        </div><br>
                                    </div>
                                </div>
                            </div>
                        </div><br><br>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="di"><b>Date:</b> 25th January 2020<br>
                                        <hr><b>Venue:</b> Shri. Sudhakarrao Naik Teacher Trainiang Department,
                                        University of Mumbai, Kalina<br>
                                        <hr><b>Details:</b> Prof. Prasad Deshmukh was selected for the final round (Oral
                                        Presentation) of the “Avishkar” competition held at University of Mumbai,
                                        Kalina. He presented the project entitled “Chemically Engineered Chitosan
                                        for Brain Targeted Delivery” in which he synthesised and characterized Palmitoyl
                                        chitosan further he explained about the use of synthesized Palmitoyl chitosan in
                                        preparing the Ropinirole loaded nanoparticle and their evaluatuion
                                        for brain targeting .<br><br>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-4"><a
                                                        href="../../../assets/images/presentation/pp1.jpg"
                                                        class="link-preview" data-lightbox="nime"
                                                        data-title="ORAL PRESENTATION By Prof. Prasad Deshmukh"
                                                        title="Preview"><img
                                                            src="../../../assets/images/presentation/pp1.jpg"
                                                            class="img-fluid link-preview"></a></div>
                                                <div class="col-md-4"><a
                                                        href="../../../assets/images/presentation/pp2.jpg"
                                                        class="link-preview" data-lightbox="nime"
                                                        data-title="ORAL PRESENTATION By Prof. Prasad Deshmukh"
                                                        title="Preview"><img
                                                            src="../../../assets/images/presentation/pp2.jpg"
                                                            class="img-fluid link-preview"></a></div>
                                                <div class="col-md-4"><a
                                                        href="../../../assets/images/presentation/pp3.jpg"
                                                        class="link-preview" data-lightbox="nime"
                                                        data-title="ORAL PRESENTATION By Prof. Prasad Deshmukh"
                                                        title="Preview"><img
                                                            src="../../../assets/images/presentation/pp3.jpg"
                                                            class="img-fluid link-preview"></a></div>
                                            </div>
                                        </div><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    </div>
</div>