<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <div class="row" style="margin-top: 1rem;">
                        <div class="col-md-3">
                            <a href="https://forms.gle/tQoGKFQeHTXkBXzh6" target="_blank"
                                class="btn btn-danger">Student Feedback</a>
                        </div>
                        <div class="col-md-3"><a href="https://forms.gle/b1ZZMpbNFiYN35ar8" target="_blank"
                                class="btn btn-danger">Faculty Feedback</a> </div>
                        <div class="col-md-3"><a href="https://forms.gle/tM8De8VbUuENqtpm9" target="_blank"
                                class="btn btn-danger">Alumni Feedback</a> </div>
                        <div class="col-md-3"><a href="https://forms.gle/5YhXnW6gdJCN6RVN7" target="_blank"
                                class="btn btn-danger">Employer Feedback</a> </div>
                    </div>
                    <div class="row" style="margin-top: 1rem;">
                        <div class="col-md-2"></div>
                        <div class="col-md-4"><a href="../../../assets/pdf/feedback/4 Feedback Action taken Report.pdf"
                                target="_blank" class="btn btn-danger">Action Taken Report </a> </div>
                        <div class="col-md-4"><a href="../../../assets/pdf/feedback/3 Feedback Analysis.pdf"
                                target="_blank" class="btn btn-danger">Analysis of the Feedback Collected</a> </div>
                    </div>
                </div>
            </section><br><br><br>

        </main>
    </div>
</div>