import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HomeAboutMgtComponent } from '../home-about-mgt/home-about-mgt.component';
import data from './about_mgt.json'

@Component({
  selector: 'app-home-team',
  templateUrl: './home-team.component.html',
  styleUrls: ['./home-team.component.css']
})
export class HomeTeamComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  open(id) {
    this.dialog.open(HomeAboutMgtComponent, {
      // disableClose: true,
      data: data.filter(s=>s.id == id)
    });
  }
}
