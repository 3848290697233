<div class="icon-bar" style="top: 30%;">
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfRmjHyW-ydotczDfBD4OOn5CDI_GuYablher7CEBeia-9_8Q/viewform?pli=1" target="_blank" class="youtube"
        style="background-color: #fed53c; color: #081c41; font-size: 13px; padding: 8px; border-radius: 0 15px 15px 0; font-weight: 600;">
        Admission Open <br>
        (2024-2025)
    </a>
</div>
<div class="icon-bar">
    <a target="_blank" href="https://www.facebook.com/potegroupcampus/" class="facebook">
        <img src="../assets/images/icon-sm/fb.png" style="width: 20px;">
    </a>
    <a target="_blank" href="https://www.instagram.com/prpotegroup/?hl=en " class="linkedin"
        style="background-color: #8b00b5;">
        <img src="../assets/images/icon-sm/Instagram_icon.png" style="width: 20px;">
    </a>
    <a target="_blank" href="https://twitter.com/prpgei?lang=en" class="twitter">
        <img src="../assets/images/icon-sm/twitter.png" style="width: 20px;">
    </a>
    <a target="_blank" href="https://www.youtube.com/channel/UCZ3_sR2StcxL1N9GzPnVX1g" class="youtube">
        <img src="../assets/images/icon-sm/youtube.png" style="width: 20px;">
    </a>
</div>
<div class="page-wraper">
    <app-header></app-header>

    <div class="page-content bg-white">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
    <button class="scroltop icon-up" type="button"><i class="fa fa-arrow-up"></i></button>
</div>


<!-- Existing #F29727 (2) #F29727 (3) #F29727-->
<!-- https://www.freepik.com/search?color=orange&format=search&page=5&query=website+background+texture -->