<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div class="row">

            <div class="col-md-3">
                <div style="display: grid;grid-template-rows: auto;border: 1px solid rgba(128, 128, 128, 0.199);">
                    <div>
                        <img src="../../../../assets/images/outstanding/1.jpg" alt="">
                    </div>
                    <div
                        style="font-weight: 600; text-align: center; font-size: 13px; line-height: 1.3rem; padding: .6rem 5px; color: black;height: 6rem;">
                        Name- Saurabh Thakare <br>
                        Company -BDR pharmaceutical International pvt Ltd, Vadodara Gujarat
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="display: grid;grid-template-rows: auto;border: 1px solid rgba(128, 128, 128, 0.199);">
                    <div>
                        <img src="../../../../assets/images/outstanding/2.jpg" alt="">
                    </div>
                    <div
                        style="font-weight: 600; text-align: center; font-size: 13px; line-height: 1.3rem; padding: .6rem 5px; color: black;height: 6rem;">
                        Name - Tejas Pande <br>
                        Company - Clarus RCM
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="display: grid;grid-template-rows: auto;border: 1px solid rgba(128, 128, 128, 0.199);">
                    <div>
                        <img src="../../../../assets/images/outstanding/3.jpg" alt="">
                    </div>
                    <div
                        style="font-weight: 600; text-align: center; font-size: 13px; line-height: 1.3rem; padding: .6rem 5px; color: black;height: 6rem;">
                        Name - Navneet Sahu <br>
                        Company - Reddy's laboratories, Hyderabad.
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="display: grid;grid-template-rows: auto;border: 1px solid rgba(128, 128, 128, 0.199);">
                    <div>
                        <img src="../../../../assets/images/outstanding/6.jpg" alt="">
                    </div>
                    <div
                        style="font-weight: 600; text-align: center; font-size: 13px; line-height: 1.3rem; padding: .6rem 5px; color: black;height: 6rem;">
                        Mrunal Jane <br>
                        Meditab, Ahemdabad
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="display: grid;grid-template-rows: auto;border: 1px solid rgba(128, 128, 128, 0.199);">
                    <div>
                        <img src="../../../../assets/images/outstanding/4.jpg" alt="">
                    </div>
                    <div
                        style="font-weight: 600; text-align: center; font-size: 13px; line-height: 1.3rem; padding: .6rem 5px; color: black;height: 6rem;">
                        Mayuri Magar (Trainee Medical Coder) <br>
                        Advantmed, Gujrat
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="display: grid;grid-template-rows: auto;border: 1px solid rgba(128, 128, 128, 0.199);">
                    <div>
                        <img src="../../../../assets/images/outstanding/5.jpg" alt="">
                    </div>
                    <div
                        style="font-weight: 600; text-align: center; font-size: 13px; line-height: 1.3rem; padding: .6rem 5px; color: black;height: 6rem;">
                        Vaibhav Tijare <br>
                        Optum Pune
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>