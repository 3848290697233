<!-- <script>
	function animateValue(obj, start, end, duration) {
		let startTimestamp = null;
		const step = (timestamp) => {
			if (!startTimestamp) startTimestamp = timestamp;
			const progress = Math.min((timestamp - startTimestamp) / duration, 1);
			obj.innerHTML = Math.floor(progress * (end - start) + start);
			if (progress < 1) {
				window.requestAnimationFrame(step);
			}
		};
		window.requestAnimationFrame(step);
	}

	const obj = document.getElementById("value");
	animateValue(obj, 100, 0, 5000);
</script> -->

<div class="section-full text-white bg-img-fix content-inner overlay-black-dark counter-staus-box">
	<!-- style="background-image:url(assets/images/background/bg4.jpg);" -->
	<div class="container">
		<div class="row">
			<div class="col-md-3 col-lg-3 col-sm-6 col-6 m-b30 wow fadeInUp counter-style-5" data-wow-duration="2s"
				data-wow-delay="0.2s">
				<div class="icon-bx-wraper center">
					<div class="icon-content">
						<h2 class="dlab-tilte counter" >5000</h2>
						<!-- <h1 [countUp]="count" [duration]="duration"></h1> -->
						<!-- <div id="value">100</div> -->
						<p>Placements</p>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-lg-3 col-sm-6 col-6 m-b30 wow fadeInUp counter-style-5" data-wow-duration="2s"
				data-wow-delay="0.4s">
				<div class="icon-bx-wraper center">
					<div class="icon-content">
						<h2 class="dlab-tilte counter">350</h2>
						<p>Well Qualified Staff</p>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-lg-3 col-sm-6 col-6 m-b30 wow fadeInUp counter-style-5" data-wow-duration="2s"
				data-wow-delay="0.6s">
				<div class="icon-bx-wraper center">
					<div class="icon-content">
						<h2 class="dlab-tilte counter">10000</h2>
						<p>Alumni</p>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-lg-3 col-sm-6 col-6 m-b30 wow fadeInUp counter-style-5" data-wow-duration="2s"
				data-wow-delay="0.6s">
				<div class="icon-bx-wraper center">
					<div class="icon-content">
						<h2 class="dlab-tilte counter">13</h2>
						<p>Years</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>