import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sports-awards',
  templateUrl: './sports-awards.component.html',
  styleUrls: ['./sports-awards.component.css']
})
export class SportsAwardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  
  banner: any = {
    pagetitle: "AWARDS & ACCOLADES",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "AWARDS & ACCOLADES",
  }
}
