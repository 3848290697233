<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <h3>GENDER SENSITIZATION CELL</h3>
                    </header>
                    <p style="text-align:justify">
                        As per the provision of UGC sponsored SAKSHAM Guidelines, a “Gender Sensitization cell is formed
                        with the primary objective to provide an environment for work and study free of sexual
                        harassment which aligns with the requirement of an institution of higher education.
                        The functions of the cell are purely safeguarding the rights of female students, faculty and
                        staff members and also to provide a platform for listening to complaints.
                    </p><br>

                    <p>
                        Anti ragging toll free number:- <span style="color: black;font-weight: 600;">
                            1800-180-5522</span>
                    </p>
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Dr. Dipti B. Ruikar (Deshmukh), Principal PRPPCOP, Amravati</td>
                                <td>Chairman</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Adv. Pravin M. Mohod, NGO Representative</td>
                                <td>Lawyer</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Mr. Prasad R. Deshmukh, Male faculty Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Ms. Gauri S. Baraskar, Women’s faculty Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Mr. Sandip M. Narad, Gents Non Teaching Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Ms. Minal P. Deshmukh, Women’s Non Teaching Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Ms. Anisha Gulhane ,M. Pharm Girls Students Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Ms. Sanika Bangar, B. Pharm Girls Students Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Ms. Monali Khobragade, D. Pharm Girls Students Representative</td>
                                <td>Member</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section><br><br><br>

        </main>
    </div>
</div>