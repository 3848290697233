<div class="content-block">
  <app-header-banner [data]="banner"></app-header-banner>
  <div class="container" style="margin-top: 10px; padding-top: 2rem;">
    <div class="container">
      <header class="section-header">
        <h3>Faculty Publications</h3>
      </header>
      <div class="row">
        <div class="col-md-12">
          <div class="accordion" id="accordionExample">

            <div class="card">
              <div class="card-header" id="headingSix">
                <h2 class="mb-0"><button type="button" class="btn btn-link collapsed"
                    style="color:#000;font-weight:700;float:left" data-toggle="collapse" data-target="#collapseSix"
                    aria-expanded="false" aria-controls="collapseSix">Publication Year 2023-2024</button></h2>
              </div>
              <div class="collapse" id="collapseSix" aria-labelledby="headingSix" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-hover mytable table-bordered">
                          <thead style="color:white;background-color: #f2622d">
                            <tr>
                              <td style="text-align: center;">S N</td>
                              <td>Title of the Paper</td>
                              <td>Details of Publication</td>
                              <td>Name of Authors</td>
                              <td style="width: 10%;">ISSN No.</td>
                            </tr>
                          </thead>
                          <tbody>

                            <tr>
                              <td style="text-align: center;">1</td>
                              <td>A Comprehensive Review on Hemidesmus indicus</td>
                              <td>International Journal of Pharmacy and Pharmaceutical Research February 2024 Vol.:30,
                                Issue:2</td>
                              <td>Shravani R Kakad, Pruthviraj M Rathod, Dhanashri A Dhanbhar, Monika S Kherade, Dr.
                                Dipti B Ruikar</td>
                              <td>2349-7203</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">2</td>
                              <td>Formulation, Development of Carbopol Gel Containing Antifungal Drug Loaded Nanosponges
                              </td>
                              <td>Chinese Journal Of Medical Genetics Vol. 32 Iss. 1 2023</td>
                              <td>Seema B. Kharwade, Sunil S.Talde, Hanuman S.Kolse, Nikita P. Shahane , Lochana
                                L.Malode</td>
                              <td>1003-9406</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">3</td>
                              <td>A Comprehensive Review on Sphagneticola Trilobatal</td>
                              <td>International Journal of Pharmaceutical Research and Applications</td>
                              <td>Vaishnavi P. Lawane. Dr.Pankaj H. Chaudhary and Dr.Dipti Ruikar.</td>
                              <td>2249-7781</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">4</td>
                              <td>A systematic review on Calotropis gigantea Linn.</td>
                              <td>International Journal Of Scientific Development And Research</td>
                              <td>Gauri V. Raut, Dr.Pankaj H. Chaudhary, Dr.Dipti B. Ruikar, Tejaswi S. Kohale</td>
                              <td>2455-2631</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">5</td>
                              <td>A systematic review on Ficus recemosa Linn</td>
                              <td>International Journal Of Scientific Development And Research</td>
                              <td>Tejaswi S. Kohale, Dr. Dipti B. Ruikar, Dr. Pankaj H. Chaudhary, Gauri V. Raut</td>
                              <td>2455-2631</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">6</td>
                              <td>Comprehensive review on Passiflora incarnata Linn</td>
                              <td>International Journal of Pharmacognosy and Pharmaceutical Sciences</td>
                              <td>Sakshi S Madavi and Pankaj H Chaudhary</td>
                              <td>2706-7017</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">7</td>
                              <td>Qualitative and Quantitative Phytochemical Analysis Of Wedelia Trilobata Linn</td>
                              <td>International Journal Of Pharmaceutical Sciences</td>
                              <td>Aditya V. Ubhale , Sakshi S.Madavi ,Shivani D. Wagh, Vaishnavi P. Lawane , Pankaj H.
                                Chaudhary, Prashant J. Burange, Dipti B. Ruikar</td>
                              <td>0975-4725</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">8</td>
                              <td>A comprehensive review on Lepidagathias crisata Wild.</td>
                              <td>Journal of Ayurveda and Integrated Medical Sciences</td>
                              <td>Aditya V. Ubhale , Pankaj H. Chaudhary , Dipti B. Ruikar</td>
                              <td>2456-3110</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">9</td>
                              <td>Improving Aceclofenac Solubility through Advanced Crystallization Methods</td>
                              <td>International Journal of Innovative research and technology</td>
                              <td>Vaishnavi B. Borgaonkar , Amit R. Jaiswal , Shailesh Sharma</td>
                              <td>2349-6002</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">10</td>
                              <td>Formulation And Evaluation Of Multiparticulate System To Enhance Formulation Efficacy
                              </td>
                              <td>World Journal Of Pharmaceutical And Medical Research</td>
                              <td>Shobhit K. Suralke and Bhushan S. Bhoyar</td>
                              <td>2455-3301</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">11</td>
                              <td>A review on solubility enhancement technique for pharmaceutical drugs</td>
                              <td>GSC Biological and Pharmaceutical Sciences</td>
                              <td>Vaishnavi B. Borgaonkar , Chetan M. Jain, Amit R. Jaiswal, Poonam Irache, Achal H.
                                Yelane and Hanuman P. Tattu</td>
                              <td>2581-3250</td>
                            </tr>
                            <tr>
                              <td style="text-align: center;">12</td>
                              <td>Formulation and Evaluation of Nutraceutical using herbal Extract</td>
                              <td>International Journal of Innovative research and technology</td>
                              <td>Prajakta S. More, Bhushan S. Bhoyar</td>
                              <td>2349-6002</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="card">
                <div class="card-header" id="headingSix">
                  <h2 class="mb-0"><button type="button" class="btn btn-link collapsed"
                      style="color:#000;font-weight:700;float:left" data-toggle="collapse" data-target="#collapseSix"
                      aria-expanded="false" aria-controls="collapseSix">Publication Year 2023-2024</button></h2>
                </div>
                <div class="collapse" id="collapseSix" aria-labelledby="headingSix" data-parent="#accordionExample">
                  <div class="card-body">
                    <div class="container">
                      <div class="row">
                        <div class="table-responsive">
                          <table class="table table-hover mytable table-bordered">
                            <thead style="color:white;background-color: #f2622d">
                              <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col" style="width: 20%;">Name of the Author</th>
                                
                                <th scope="col">Title of the Research Paper</th>
                                <th scope="col">Details of the Publication </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let obj of objSPharmaWebsiteService.arrPublication">
                                <td scope="row">1</td>
                                <td>{{obj.author}}</td>
                                <td>{{obj.title}}</td>
                                <td>{{obj.details}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

            <div class="card">
              <div class="card-header" id="headingSix">
                <h2 class="mb-0"><button type="button" class="btn btn-link collapsed"
                    style="color:#000;font-weight:700;float:left" data-toggle="collapse" data-target="#collapseSix"
                    aria-expanded="false" aria-controls="collapseSix">Publication Year 2022-2023</button></h2>
              </div>
              <div class="collapse" id="collapseSix" aria-labelledby="headingSix" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-hover mytable table-bordered">
                          <thead style="color:white;background-color: #f2622d">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col" style="width: 20%;">Name of the Author</th>

                              <th scope="col">Title of the Research Paper</th>
                              <th scope="col">Details of the Publication </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFive">
                <h2 class="mb-0"><button type="button" class="btn btn-link collapsed"
                    style="color:#000;font-weight:700;float:left" data-toggle="collapse" data-target="#collapseFive"
                    aria-expanded="false" aria-controls="collapseFive">Publication Year 2021-2022</button></h2>
              </div>
              <div class="collapse" id="collapseFive" aria-labelledby="headingFive" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-hover mytable table-bordered">
                          <thead style="color:white;background-color: #f2622d">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col" style="width: 20%;">Name of the Author</th>

                              <th scope="col">Title of the Research Paper</th>
                              <th scope="col">Details of the Publication </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">1</td>
                              <td>Siddhant Warkari, Swaraj Deshmukh, Mukund Tawar, Pankaj Chaudhary, Yashdeep Thakur
                              </td>

                              <td>Community Pharmacists and their contribution in handling COVID-19 Pandemic in India.
                              </td>
                              <td>International Journal of Pharmaceutical Research and Applications Volume 7, Issue 2
                                Mar-Apr 2022, pp: 720-723</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0"><button type="button" class="btn btn-link"
                    style="color:#000;font-weight:700;float:left" data-toggle="collapse" data-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">Publication Year 2020-2021</button></h2>
              </div>
              <div class="collapse" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-hover mytable table-bordered">
                          <thead style="color:white;background-color: #f2622d">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col" style="width: 20%;">Name of the Author</th>

                              <th scope="col">Title of the Research Paper</th>
                              <th scope="col">Details of the Publication </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">1</td>
                              <td>Kalyani Farkade, Mukund Tawar </td>

                              <td>Analytical Method Validation and Quantitative Analysis for Active Pharmaceutical
                                Ingredient and Marketed Formulation of Teneligliptin Hydrobromide by UV Spectroscopy
                              </td>
                              <td>Asian Journal of Pharmaceutical Analysis. 2021; 11(3):195-8.</td>
                            </tr>
                            <tr>
                              <td scope="row">2</td>
                              <td>Vedanshu Malviya, Prashant Burange, Yashdeepsingh Thakur, Mukund Tawar </td>

                              <td>Enhancement of Solubility and Dissolution Rate of Atazanavir Sulphate by
                                Nanocrystallization </td>
                              <td>Indian Journal of Pharmaceutical Education and Research; 2021; 55(3):1-9 </td>
                            </tr>
                            <tr>
                              <td scope="row">3</td>
                              <td>Monika Kherade, Sohani Solanke, Mukund Tawar, Sagar Wankhede </td>

                              <td>Fructooligosaccharides: A comprehensive review </td>
                              <td>Journal of Ayurvedic and Herbal Medicine 2021;7(3):193-200</td>
                            </tr>
                            <tr>
                              <td scope="row">4</td>
                              <td>Pankaj H Chaudhary, Mukund G Tawar, Birendra Shrivastava , Manish Gupta </td>

                              <td>Need &amp; Scope of Standardization of herbal medicines-A review<br>
                                <br>
                              </td>
                              <td>Journal of Research in Pharmaceutical Science Volume 7 Issue 8 (2021) pp: 26-31</td>
                            </tr>
                            <tr>
                              <td scope="row">5</td>
                              <td>Prasad Deshmukh, Mukund Tawar, Anushri Awatade, Apurva Deshmukh , Ashwini Munjewar
                              </td>

                              <td>A Review Triphala as a Poly Medicinal Herb</td>
                              <td>European Journal of Biomedical and Pharmaceutical sciences 2021; Volume, 8 Issue 9,
                                276-281</td>
                            </tr>
                            <tr>
                              <td scope="row">6</td>
                              <td>Sohani Solanke, Mukund Tawar, Sneha Rathod, Samiksha Burange</td>

                              <td>Phyto-Therapeutic Review of Tinosporia Cordifolia Linn in Competance to
                                immunomodulation and Antiviral Potential to Deal with Covid International</td>
                              <td>Journal of Pharmacy and Pharmaceutical Research; 2021; Volume 22 (1): 334-360</td>
                            </tr>
                            <tr>
                              <td scope="row">7</td>
                              <td>Sohani Solanke, Nitin Kochar, Mukund Tawar, Krutika Sonar, Samiksha Kadu, Vaishnavi
                                Tayade</td>

                              <td>Recent Studies on Potential Prebiotic Herbs- A Review </td>
                              <td>EAS Journal of Nutritional and Food Science; 2021; 3 (4): 1-8</td>
                            </tr>
                            <tr>
                              <td scope="row">8</td>
                              <td>Monika Kherade, Sohani Solanke, Mukund Tawar, Shweta Warghat, Trupti Bansod</td>

                              <td>A Comprehensive Review on Withania Coagulans (Panner Dodi)</td>
                              <td>International Journal of Pharmacy &amp; Pharmaceutical Research, Volume 22 Issue 2
                                (2021) pp: 257-282</td>
                            </tr>
                            <tr>
                              <td scope="row">9</td>
                              <td>Rahul Rajge, Vishal Mahanur, Mukund Tawar</td>

                              <td>Effect on Drug Release profile of Meclizine Hydrochloride by using various Dissolution
                                Media</td>
                              <td>Asian Journal of Pharmacy and Technology. 2021; 11(3):191-7.</td>
                            </tr>
                            <tr>
                              <td scope="row">10</td>
                              <td>Kalpak Gajbhiye, Nawaz Hakam, Gauri Rathod, Mukund Tawar</td>

                              <td>Formulation and Evaluation of Transdermal Patches of Benidipine Hydrochloride</td>
                              <td>Asian Journal of Pharmacy and Technology 2021; 11(3):207-2</td>
                            </tr>
                            <tr>
                              <td scope="row">11</td>
                              <td>Prashant J. Burange , Mukund G. Tawar , Ritu A. Bairagi , Vedanshu R. Malviya ,
                                Vanshika K. Sahu, Sakshi N. Shewatkar , Roshani A. Sawarkar, Renuka R. Mamurkar</td>

                              <td>Synthesis of silver nanoparticles by using Aloe vera and Thuja orientalis leaves
                                extract and their biological activity: a comprehensive review</td>
                              <td>Bulletin of the National Research Centre (2021) 45:181</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTwo">
                <h2 class="mb-0"><button type="button" class="btn btn-link"
                    style="color:#000;font-weight:700;float:left" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">Publication Year 2019-2020</button></h2>
              </div>
              <div class="collapse" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-hover mytable table-bordered">
                          <thead style="color:white;background-color: #f2622d">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col" style="width: 20%;">Name of the Author</th>

                              <th scope="col">Title of the Research Paper</th>
                              <th scope="col">Details of the Publication </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">1</td>
                              <td>Vedanshu R. Malviya, Mukund Tawar </td>

                              <td>Preparation and Evaluation of Oral Dispersible Strips of Teneligliptin Hydrobromide
                                for Treatment of Diabetes Mellitus</td>
                              <td>International Journal of Pharmaceutical Science and Nanotechnology; 2020; 13(1);
                                4745-4751</td>
                            </tr>
                            <tr>
                              <td scope="row">2</td>
                              <td>Vedanshu R. Malviya, Shraddha Shrikhande, Kalyani Gudadhe, Yashdeep Thakur, Mukund
                                Tawar</td>

                              <td>Formulation and evaluation of natural gum based fast dissolving tablet of Meclizine
                                hydrochloride by using 32 factorial design </td>
                              <td>Asian Journal of Pharmacy and Pharmacology;2020, 6 (2), 94-100</td>
                            </tr>
                            <tr>
                              <td scope="row">3</td>
                              <td>Kalyani Gudadhe, Bhagyashri Borade, Neha Deshmukh ,Mukund Tawar </td>

                              <td>Development and validation of RP-HPLC for the simultaneous estimation of metformin and
                                alogliptin in bulk and combined tablet dosage form </td>
                              <td>World Journal of Pharmacy and Pharmaceutical Sciences; 2020 , 9;(11),1172-1180</td>
                            </tr>
                            <tr>
                              <td scope="row">4</td>
                              <td>Vedanshu R. Malviya, Yashdeep Thakur. Mukund Tawar </td>

                              <td>A Review On Covid-19-Corona Virus Disease </td>
                              <td>World Journal Of Pharmacy And Pharmaceutical Sciences;2020, 9 (8), 1333-1353</td>
                            </tr>
                            <tr>
                              <td scope="row">5</td>
                              <td>Vaibhav A. Bhadange, Dr. Mukund G. Tawar, Prashant J. Burange, Dr. Sagar B. Wankhade
                                and Chetan M. Jain </td>

                              <td>Review On Chromatographic Method Development For Quality Control Of Ayurvedic
                                Formulation By HPTLC. </td>
                              <td>World Journal Of Pharmacy And Pharmaceutical Sciences; 2020, 9, (12), 976-982.</td>
                            </tr>
                            <tr>
                              <td scope="row">6</td>
                              <td>Mukund Tawar, Vedanshu R. Malviya, Vaishanavi Ladakhe, Kalpak Gajbhiye, Juili Satao
                              </td>

                              <td>Design and Characterization of Phase Transition System of Zolmitriptan Hydrochloride
                                for Nasal Drug Delivery System</td>
                              <td>International Journal of Pharmaceutical Sciences and Nanotechnology;2020,
                                13(3);4942-4951</td>
                            </tr>
                            <tr>
                              <td scope="row">7</td>
                              <td>Aachal Kolhe, Apurva Deshmukh, Sohani Solanke, Mukund Tawar</td>

                              <td>Comparative studies on suspending properties of Tribulus Terrestris Mucilage</td>
                              <td>World journal of pharmacy and pharmaceutical sciences 2020, Volume 9 Issue 10,
                                2146-2158</td>
                            </tr>
                            <tr>
                              <td scope="row">8</td>
                              <td>Gauri Dhule, Akshada Chavhan, Sohani Solanke, Mukund Tawar</td>

                              <td>Novel use of Sucrose cocate as a surfactant in formulation and evaluation of Herbal
                                liquid Soap</td>
                              <td>World journal of pharmacy and pharmaceutical sciences 2020, Volume 9 Issue 10;
                                2211-2223</td>
                            </tr>
                            <tr>
                              <td scope="row">9</td>
                              <td>J Satao, P Wadaskar, A Bais, S Bhamburkar, V Malviya, Mukund Tawar</td>

                              <td>Preparation and Characterization of Benidipine Hydrochloride Microballoons</td>
                              <td>International Journal of Scientific Development and Research June-2020, Vol.5, Issue
                                6, 176 - 181</td>
                            </tr>
                            <tr>
                              <td scope="row">10</td>
                              <td>Sohani Solanke, Juili Satao , Hemangi Vaishnav, Shivani Kotamkar, Mukund Tawar</td>

                              <td>Topical Gel Formulation Incorporated with Hydroalcoholic Extract of Stevia rebaudiana
                                Bertoni</td>
                              <td>International Journal of Science &amp; Engineering Development Research, Vol.6, Issue
                                8, page no.42 – 54</td>
                            </tr>
                            <tr>
                              <td scope="row">11</td>
                              <td>Sneha D Kherde, Komal M Parmar, Mukund G Tawar, Satyendra K Prasad &amp; Prakash R
                                Itankar</td>

                              <td>Study on impact of different climatic zones on physicochemical and phytochemical
                                profile of Withaniasomnifera (L.) Dunal</td>
                              <td>Indian Journal of Traditional Knowledge Vol 19(3), July 2020, pp 486-493</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0"><button type="button" class="btn btn-link collapsed"
                    style="color:#000;font-weight:700;float:left" data-toggle="collapse" data-target="#collapseThree"
                    aria-expanded="false" aria-controls="collapseThree">Publication Year 2018-2019</button></h2>
              </div>
              <div class="collapse" id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-hover mytable table-bordered">
                          <thead style="color:white;background-color: #f2622d">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col" style="width: 20%;">Name of the Author</th>

                              <th scope="col">Title of the Research Paper</th>
                              <th scope="col">Details of the Publication </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">1</td>
                              <td>Prashant Burange, Adnya Bahurupi, Mukund Tawar, Shital Ingole<br>
                              </td>

                              <td>Review on Marine Sponge: Derived Natural Products and formulations</td>
                              <td>Am. J. PharmTech Res. 2019; 9(03)</td>
                            </tr>
                            <tr>
                              <td scope="row">2</td>
                              <td>Prashant Burange, Adnya Bahurupi, Mukund Tawar, Shital Ingole</td>

                              <td>Formulation and Characterization of Solid Lipid Microparticles</td>
                              <td>American journal of pharmacy and health Research 2019, Volume 7, Issue 4, 2321-3647
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">3</td>
                              <td>Sohani B. Solanke, Mukund G. Tawar<br>
                              </td>

                              <td>Phytochemical Information and Pharmacological Activities of Eggplant (Solanum
                                melongena L.)</td>
                              <td>EASJ Pharm &amp; Pharmacol; Vol-1, Iss-5 (Sept-Oct, 2019): 103-114</td>
                            </tr>
                            <tr>
                              <td scope="row">4</td>
                              <td>Kalyani Gudadhe, Mukund G. Tawar </td>

                              <td>Development and Validation of Analytical Instrumental Method for Pharmaceutical
                                Products</td>
                              <td>American Journal of Pharmacy &amp; Health Research 2019. Volume 7, Issue 5</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFour">
                <h2 class="mb-0"><button type="button" class="btn btn-link collapsed"
                    style="color:#000;font-weight:700;float:left" data-toggle="collapse" data-target="#collapseFour"
                    aria-expanded="false" aria-controls="collapseFour">Publication Year 2017-2018</button></h2>
              </div>
              <div class="collapse" id="collapseFour" aria-labelledby="headingFour" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-hover mytable table-bordered">
                          <thead style="color:white;background-color: #f2622d">
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col" style="width: 20%;">Name of the Author</th>

                              <th scope="col">Title of the Research Paper</th>
                              <th scope="col">Details of the Publication </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">1</td>
                              <td>Dr. Mukund G. Tawar, Nilesh Chaudhary, Vivek Kahale et.al</td>

                              <td>Effect of Ailanthus Excelsa Extract In Hepatic Disorders </td>
                              <td>World Journal Pharmacy &amp; Pharmaceutical Sciences. Vol. 7, Issue 2, Feb 2018</td>
                            </tr>
                            <tr>
                              <td scope="row">2</td>
                              <td>Dr. Mukund G. Tawar, Samidha Bhonde, et.al</td>

                              <td>Evaluation of antidiabetic activity of polyherbal preparation against alloxan induced
                                diabetes in experimental animal</td>
                              <td>International Journal of Current Advanced Research Volume 7; Issue 7(A); July 2018;
                                Page No. 13934-13937</td>
                            </tr>
                            <tr>
                              <td scope="row">3</td>
                              <td>Pankaj Chaudhary, Mukund Tawar</td>

                              <td>A Pharmacognostic and Phytopharmacological Overview on Bombax ceiba</td>
                              <td>Systematic Reviews in Pharmacy. 2018;10(1).</td>
                            </tr>
                            <tr>
                              <td scope="row">4</td>
                              <td>Sohani B. Solanke, Mukund Tawar, Santosh Bhujbal</td>

                              <td>Development and Evaluation of Surgical Sutures from Agave americana Linn. Var.
                                Americana.</td>
                              <td>American Journal of PharmTech Res. 2018; 8(6)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>