<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div>
            <main id="main" style="margin-top: 1rem;">
                <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                    <div class="container">
                        <header class="section-header">
                            <h3>R &amp; D Cell Composition</h3>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <td>Sr. No.</td>
                                        <td>Name of Faculty</td>
                                        <td>Designation</td>
                                        <td>Role</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1.             </td>
                                        <td>Dr.Dipti B. Ruikar</td>
                                        <td>Principal</td>
                                        <td>Chairperson</td>
                                    </tr>
                                    <tr>
                                        <td>2.             </td>
                                        <td> Dr. Bhushan S. Bhoyar
                                        </td>
                                        <td>Associate Professor</td>
                                        <td>Secretory</td>
                                    </tr>
                                    <tr>
                                        <td>3.             </td>
                                        <td>Mrs. Dipti A. Bonde (Kadam)</td>
                                        <td>Assistant Professor</td>
                                        <td>R&amp;D Coordinator</td>
                                    </tr>
                                    <tr>
                                        <td>4.             </td>
                                        <td>Ms.Adnya D. Bahurupi</td>
                                        <td>Assistant Professor</td>
                                        <td>EDC Coordinator</td>
                                    </tr>
                                    <tr>
                                        <td>5.             </td>
                                        <td>Ms. Gauri S. Baraskar (Salode)</td>
                                        <td>Assistant Professor</td>
                                        <td>IPR Coordinator</td>
                                    </tr>
                                    <tr>
                                        <td>6.             </td>
                                        <td>Ms.Monika S.Kherde</td>
                                        <td>Assistant Professor</td>
                                        <td>IIIC Coordinator</td>
                                    </tr>
                                    <tr>
                                        <td>7.             </td>
                                        <td>Mr.Prashant J.Burange</td>
                                        <td>Associate Professor</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <td>8.             </td>
                                        <td>Ms. Ritu A.Bairagi</td>
                                        <td>Assistant Professor</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <td>9.             </td>
                                        <td>Ms.Kiran S. Kaloshiya</td>
                                        <td>Assistant Professor</td>
                                        <td>Member</td>
                                    </tr>
                                    <tr>
                                        <td>10.         </td>
                                        <td>Ms.Pragati R. Katkide</td>
                                        <td>Assistant Professor</td>
                                        <td>Member</td>
                                    </tr>
                                </tbody>
                            </table>
                        </header>
                    </div>
                </section>
                <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                    <div class="container">
                        <header class="section-header">
                            <h3>The objectives of the R &amp; D cell</h3>To promote and facilitate research activities amongst the faculty and students:<br>
                            <ul>
                                <li>To Collect, Analyze and published the data from faculties.</li>
                                <li>To encourage faculty and students to participate in research projects to improve their technical skills and knowledge.</li>
                                <li>To assist faculty in obtaining research grants from various funding Agencies.</li>
                                <li>To update Google Scholar Citation of each faculty.</li>
                            </ul>
                        </header>
                    </div>
                </section>
                <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                    <div class="container">
                        <header class="section-header">
                            <h3>Department wise R &amp; D Thrust areas</h3><b>Pharmaceutics:</b><br>
                            <ul>
                                <li>Formulation and Development</li>
                                <li>Novel Drug Delivery Systems</li>
                                <li>Multiparticulate Drug Delivery System</li>
                                <li>Colon Specific Drug Delivery System</li>
                            </ul><b>Pharmaceutical Chemistry:</b><br>
                            <ul>
                                <li>Molecular Modeling</li>
                                <li>Computer Aided Drug Designing</li>
                                <li>Combinatorial Chemistry</li>
                                <li>Analytical Method Development</li>
                            </ul><b>Pharmacology:</b><br>
                            <ul>
                                <li>Toxicological Evaluation</li>
                                <li>Preliminary Screening of Drug Effect</li>
                                <li>Effective Bioavailability and Bioequivalence Studies</li>
                                <li>In-Vivo Studies</li>
                                <li>Preclinical Studies</li>
                                <li>Adverse Drug Reaction</li>
                            </ul><b>Pharmacognosy:</b><br>
                            <ul>
                                <li>Pharmacognostic Analysis</li>
                                <li>Preliminary Screening of Drug Effect</li>
                                <li>Qualitative and Quantitative Studies</li>
                                <li>Pharmacological Studies of Herbal Drug and Formulation</li>
                                <li>Formulation and Development of Crude Drugs</li>
                                <li>Fingerprint Analysis of Herbal Drugs and Formulations</li>
                            </ul>
                        </header>
                    </div>
                </section>
    
            </main>
        </div>
    </div>
</div>