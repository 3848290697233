<div class="content-block">

    <div class="section-full content-inner">
        <div class="container" style="margin-top: 10px">
            <div class="row" style="display: flex;justify-content: center;">
                <img src="../../assets/images/other/session.jpeg" style="width: 80%;">
            </div>
            <div class="row" style="display: flex;justify-content: center;margin-top: 1rem;">
                <button (click)="open()" class="btn btn-success">Click for Registration</button>
            </div>
        </div>
    </div>
</div>