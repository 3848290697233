<div class="section-full content-inner bg-white">
    <div class="container">
        <div class="section-head m-b20">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Our Mentor</h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div class="row" style="display: flex;justify-content: center;">
            <div class="col-md-3" style="border: 1px solid rgba(128, 128, 128, 0.274); padding: 10px;border-radius: 10px;">
                <div style="display: flex;justify-content: center;">
                    <img src="../../../assets/images/mentor.png" style="width: 70%;">
                </div>
                <div style="text-align: center;margin-top: 1rem;">
                    <div style="font-weight: 600; font-size: 20px;color: rgb(0, 69, 158);">Shri. Ramchandraji M. Pote Patil</div> <br>
                    <!-- <div style="font-weight: 600; font-size: 18px;color: black;">P. R. Pote (Patil) Education and Welfare Trust, Amravati</div> -->
                </div>
            </div>
        </div>
    </div>
</div>